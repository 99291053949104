// Queries and mutations for the Home module
import { gql } from 'apollo-boost';

import { PARENT_QUERY } from '../../../services/constants';

export const LOGGED_IN = gql`
    {
        loggedIn @client
        error @client
    }
`;

// Has the user been 401 logged out
export const FORCED_LOGOUT = gql`
    {
        forcedLogout @client
    }
`;

// Has the client thrown an error
export const ERROR = gql`
    {
        error @client
    }
`;

export const CURRENT_PARENT_QUERY = gql`
query currentParent {
    currentParent {
        ${PARENT_QUERY}
    }
}
`;
