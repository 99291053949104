import React, { Fragment, useContext, useEffect, useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useEffectOnce } from 'react-use';
import ls from 'local-storage';
import { Redirect, Router } from '@reach/router';

import { Loader } from '@axeedge/go-teacher-components';
import { getAppTokenName, TERMS_VERSION } from '@axeedge/go-shared-utils';
import { ErrorPage, Ssl } from '@axeedge/go-shared-components';

import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGGED_IN } from '../services/graphql';
import { APP_NAME } from '../../../services/constants';
import LoggedInRouter from './LoggedInRouter';
import ParentProfile from '../../ParentProfile';
import AccountSettings from '../../AccountSettings';
import Logout from '../../Logout';
import Dashboard from '../../Dashboard';
import AddChild from '../../AddChild';
import Child from '../../Child';
import ReadingBooks from '../../Child/scenes/ReadingBooks';
import Store from '../../Store';
import RouteCapture from './RouteCapture';
import Login from '../../Login';
import SignUp from '../../SignUp';
import Pending from '../../Pending';
import SetPassword from '../../Login/scenes/SetPassword';
import ConfirmNewEmail from '../../Login/scenes/ConfirmNewEmail';
import ResetSent from '../../Login/scenes/ResetSent';
import RedirectAfterLogin from '../../Login/scenes/RedirectAfterLogin';
import Activation from '../../Activation';
import Impersonate from '../../Impersonate';
import TermsUpdate from '../../TermsUpdate';
import LoggedOutRouter from './LoggedOutRouter';

const RouterAuthQuery = () => {
    const { data, error, loading } = useQuery(LOGGED_IN);
    const [ready, setReady] = useState(false);

    const auth = useContext(AuthContext);

    useEffect(() => {
        if (ls(getAppTokenName(APP_NAME)) && !auth.currentUser && ls('go_parent_user') && ls('go_parent_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_parent_user')));
        }
    }, [auth]);

    useEffectOnce(() => {
        if (ls(getAppTokenName(APP_NAME)) && !auth.currentUser && ls('go_parent_user') && ls('go_parent_user') !== 'undefined') {
            auth.setCurrentUser(JSON.parse(ls('go_parent_user')));
        }
        setReady(true);
    });

    const renderContent = () => {
        if (error) {
            return <p>{error.message}</p>
        }

        if (loading) {
            return <Loader />
        }
        if (data && ready) {
            return (
                <Router>
                    <RouteCapture path='/'>
                        {
                            auth.currentUser ? (
                                <LoggedInRouter path='/' default>
                                    {(!auth.currentUser.lastTermsVersionApproved || parseInt(auth.currentUser.lastTermsVersionApproved) < TERMS_VERSION) ?
                                    <TermsUpdate path="/" default />
                                    :
                                    <>
                                    <Dashboard path='/' default />
                                    <AddChild path='/addChild' />
                                    <Child path='/child/:id' />
                                    <ReadingBooks path='/child/:id/readingBooks' />
                                    <Logout path='/logout/*' />
                                    <ParentProfile path='/profile' />
                                    <AccountSettings path='/account/' />
                                    <Store path='/store/*' />
                                    <ConfirmNewEmail path='/confirm_email/:token/*' />
                                    </>
                                    }
                                </LoggedInRouter>
                            ) : (
                                <LoggedOutRouter path='/' default>
                                    <Login path='/' default/>
                                    <SignUp path='/signup' />
                                    <Activation path='/activation' />
                                    <Pending path='/pending' />
                                    <Redirect from='/logout' to='/' noThrow/>
                                    <RedirectAfterLogin path='/redirectAfterLogin/:app' />
                                    <SetPassword path='/setpassword/:token/*' />
                                    <ResetSent path='/reset_sent' />
                                    <Store path='/store/*' />
                                    <ErrorPage path='/error' />
                                    <ConfirmNewEmail path='/confirm_email/:token/*' />
                                    <Impersonate path='/impersonate/:id/:token' />
                                </LoggedOutRouter>
                            )
                        }
                    </RouteCapture>
                </Router>
            )
        }
    }

    return (
        <Fragment>
            <Ssl/>
            {renderContent()}
        </Fragment>
    );

}

export default RouterAuthQuery;
