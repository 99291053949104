import React, { useEffect, useState, useContext } from 'react';
import { useApolloClient, useQuery } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useLocation } from 'react-use';
import { navigate } from '@reach/router';

import { ErrorPage } from '@axeedge/go-shared-components';
import { getUrl, APPS } from '@axeedge/go-shared-utils';

import { FORCED_LOGOUT, ERROR } from '../services/graphql';

import { APP_NAME } from '../../../services/constants';
import { AuthContext } from '../../../services/auth/AuthProvider';

const RouteCapture = ({ children }) => {

    const [targetRoute, setTargetRoute] = useState('');
    const client = useApolloClient();
    const { data: fl } = useQuery(FORCED_LOGOUT);
    const { data: clientError } = useQuery(ERROR);
    const { currentUser: user, setCurrentUser } = useContext(AuthContext);

    const locationState = useLocation();

    const forceLogout = redirect => {
        ls.clear();
        client.clearStore();
        setCurrentUser(null);
        if (redirect) {
            window.location.href = `${getUrl(APPS.go_parents_desktop)}/redirectAfterLogin/${APP_NAME}?targetUrl=${targetRoute}`;
        } else {
            window.location.href = `${getUrl(APPS.go_parents_desktop)}`;
        }
    }

    // const clearError = () => {
    //     client.writeData({
    //         data: {
    //             error: false,
    //         },
    //     });
    //     navigate('/');
    // }

    // console.log(locationState);

    useEffect(() => {
        const { pathname } = locationState;
        const checkRouteForLogout = () => {
            
            if (!pathname) {
                return false;
            }
            if (pathname.includes('logout')) {
                return false;
            }
            if (pathname.includes('setpassword')) {
                return true;
            }
            if (pathname.includes('impersonate')) {
                const params = locationState.pathname.split('/');
                if (ls('go_impersonating') && params[2] === user.id) {
                    return false;
                }
                return true;
            }
            return false;
        }
        setTargetRoute(pathname);
        if (user && !user.reset && checkRouteForLogout()) {
            navigate(`/logout/${pathname}`);
        }
    }, [setTargetRoute, locationState, user]);

    useEffect(() => {
        if (ls('go_parent_user') && ls('go_parent_user') === 'undefined') {
            forceLogout(false);
        }
    });

    if (fl && fl.forcedLogout) {
        forceLogout(true);
    }

    if (clientError && clientError.error) {
        return <ErrorPage />
    }

    return children || null;
}

export default RouteCapture;