import React, { useRef, useState, useContext } from 'react';
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { navigate } from '@reach/router';
import { yupResolver } from '@hookform/resolvers';
import ReCAPTCHA from 'react-google-recaptcha';
import ls from 'local-storage';
import { useTranslation } from 'react-i18next';
import FacebookLoginWithButton from 'react-facebook-login';
import cx from 'classnames';

import { getAppTokenName } from '@axeedge/go-shared-utils';
import { GOOGLE_SITE_KEY, TERMS_VERSION } from '@axeedge/go-shared-utils';
import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { ADD_PARENT_MUTATION } from '../services/graphql';
import { LOGIN_FB } from '../../Login/services/graphql';
import { APP_NAME, FbAppId } from '../../../services/constants';
import styles from '../SignUp.module.scss';
import loader from '../../../images/loader.svg';
import { AuthContext } from '../../../services/auth/AuthProvider';

const SignUpForm = () => {
    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [rcValue, setRcValue] = useState(null);
    const recaptchaRef = useRef();
    const [fbRes, setFbRes] = useState(null);
    const auth = useContext(AuthContext);
    const client = useApolloClient();
    const schema = Yup.object().shape({
        firstName: Yup.string()
            .required(t('req_enter_firstname')),
        lastName: Yup.string()
            .required(t('req_enter_lastname')),
        email: Yup.string().email()
            .required(t('req_enter_email')),
        tos: Yup.boolean().oneOf([true], t('req_terms'))

    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [signUp, { loading }] = useMutation(ADD_PARENT_MUTATION, {
        onCompleted: ({ addParent }) => {
            if (addParent.errors && addParent.errors.length !== 0) {
                setFormErrors(addParent.errors);
                return;
            }
            if (addParent.parent && addParent.parent.id) {
                navigate('/pending', { state: { confirm_email: addParent.parent.email } });
            } 
        }
    })

    const onSubmit = values => {
        const recaptchaValue = recaptchaRef.current.getValue();
        if (recaptchaValue) {
            const { firstName, lastName, email } = values;
            signUp({
                variables: {
                    firstName,
                    lastName,
                    email,
                    displayName: `${firstName} ${lastName}`,
                    termsVersionApproved: (TERMS_VERSION).toString()
                }
            });
        }
    }

    const onChangeCaptcha = e => {
        setRcValue(e);
    }

    const [createFBAccount, {loading: creatingFb}] = useMutation(ADD_PARENT_MUTATION, {
        onCompleted: data => {
            if (data.addParent.errors && data.addParent.errors.length !== 0) {
                setFormErrors(data.addParent.errors);
                return;     
            }
            if (data.addParent.parent && data.addParent.parent.id) {
                fbLogin({
                    variables: {
                        email: data.addParent.parent.email,
                        facebookId: data.addParent.parent.facebookId,
                    }
                })
            }
        }
    })

    const [fbLogin, { loading: fbLoggingIn }] = useMutation(LOGIN_FB, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.parentLogInWithFb.errors && data.parentLogInWithFb.errors.length !== 0) {
                if(data.parentLogInWithFb.errors.includes("Invalid user")) {
                    createFBAccount({
                        variables: {
                            firstName: fbRes.first_name,
                            lastName: fbRes.last_name,
                            displayName: fbRes.name,
                            email: fbRes.email,
                            facebookId: fbRes.id,
                            termsVersionApproved: (TERMS_VERSION).toString()
                        }
                    })
                } else {
                    setFormErrors(data.parentLogInWithFb.errors);
                }
                return;
            }
            if (data.parentLogInWithFb.parent && data.parentLogInWithFb.parent.id && data.parentLogInWithFb.token) {
                ls(getAppTokenName(APP_NAME), data.parentLogInWithFb.token);
                ls('go_parent_user', JSON.stringify(data.parentLogInWithFb.parent));
                auth.setCurrentUser(data.parentLogInWithFb.parent);
                auth.setEmailLinked(data.parentLogInWithFb.emailLinked);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const responseFacebook = (response) => {
        if (response.id) {
            setFbRes(response);
            fbLogin({
                variables: {
                    email: response.email,
                    facebookId: response.id,
                }
            })
        }
      }

      

    return (
        <div className={styles.loginMethods}>
            {(fbLoggingIn || creatingFb) ?
                <div className='u-display-flex u-justify-center'>
                    <img src={loader} alt={t('loading')}/>
                </div> :
                <>
                    <div className={styles.loginMethodsForm}>
                        <p className={cx('auth-page__subtitle u-m-base-2', styles.signUpSubtitle)}>{t('no_smartphone')}</p>
                        <p className={cx('u-m-base-2', styles.signUpText)}>{t('no_problem')}</p>
                        <form onSubmit={handleSubmit(onSubmit)}>
                            <div className='basic-form__group'>
                                <input
                                    name='firstName'
                                    placeholder={t('ph_first_name')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                />
                            </div>
                            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                            <div className='basic-form__group'>
                                <input
                                    name='lastName'
                                    placeholder={t('ph_last_name')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='text'
                                />
                            </div>
                            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}
                            <div className='basic-form__group'>
                                <input
                                    name='email'
                                    placeholder={t('ph_enter_email')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    type='email'
                                />
                            </div>
                            {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}


                            <div className="basic-form__group basic-form__group--check u-m-base-0">
                                <input
                                    name="tos"
                                    className="basic-form__check-box"
                                    ref={register}
                                    type="checkbox"
                                    id="tos"
                                />
                                <label className="basic-form__check-label" htmlFor="tos">{t('agree_terms')}</label>
                            </div>
                            <ul className='u-m-base-3 u-m-left-3'>
                                <li><a target='blank' className='link-underline' href='https://boomreader.co.uk/terms'>Terms &amp; Conditions</a></li>
                                <li><a target='blank' className='link-underline' href='https://boomreader.co.uk/privacy'>{t('privacy_policy')}</a></li>
                            </ul>
                            {errors.tos && <p className='basic-form__hint'>{errors.tos.message}</p>}
                            

                            <div className={styles.signupCaptcha}>
                                <ReCAPTCHA
                                    onChange={onChangeCaptcha}
                                    ref={recaptchaRef}
                                    style={{ display: "inline-block" }}
                                    sitekey={GOOGLE_SITE_KEY}
                                    size={window.innerWidth < 383 ? 'compact' : 'normal'}
                                />
                            </div>
                            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                            <div className='u-display-flex u-justify-center u-m-top-2'>
                                <Button disabled={!rcValue || loading} type='submit' primary fullWidth>{loading ? t('creating_account') : t('create_account')}</Button>
                            </div>
                        </form>
                    </div>
                    <div className={styles.loginMethodsFb}>
                        <div className={styles.fbLogin}>
                            <span className={styles.fbLoginSpan}>or</span>
                        </div>
                        <FacebookLoginWithButton
                            appId={FbAppId()}
                            autoLoad={false}
                            fields="name, email, picture, first_name, last_name"
                            callback={responseFacebook}
                            cssClass="kep-login-facebook small"
                            icon="fa-facebook" 
                            textButton="Signup with Facebook"
                            isMobile={false}    
                        />
                    </div>
                </>
            }
        </div>
    )
}

export default SignUpForm;
