import React, { Fragment, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import { Button, FormErrors} from '@axeedge/go-teacher-components';

import { SET_PASSWORD_MUTATION } from '../services/graphql';
import { Check } from 'react-feather';
import { AuthContext } from '../../../services/auth/AuthProvider';

const AddPassword = ({ closeModal }) => {
    const { setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const { t } = useTranslation();

    const [changePassword, { loading }] = useMutation(SET_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.parentSetPassword.errors && data.parentSetPassword.errors.length !== 0) {
                setFormErrors(data.parentSetPassword.errors);
                return;
            }

            if (data.parentSetPassword.parent.id) {
                setSuccess(true);
                setCurrentUser(data.parentSetPassword.parent);
                reset({
                    password: '',
                    passwordConfirmation: ''
                })
            }
        }
    });

    const schema = Yup.object().shape({
        password: Yup.string()
            .required('Please enter a new password'),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], 'Passwords must match')
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        setFormErrors([]);
        const { password, passwordConfirmation } = values;
        changePassword({
            variables: {
                password,
                passwordConfirmation
            }
        })
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className='light u-m-base-3'>{t('please_set_new_pw')}</h1>
                {!success &&
                    <>
                    <p className="u-m-base-2">{t('you_dont_have_pw')}</p>
                    <div className='basic-form__group'>
                        <label htmlFor='password' className='basic-form__text-label'>{t('new_password')}</label>
                        <input
                            name='password'
                            id='password'
                            className='basic-form__text-box'
                            type='password'
                            ref={register}
                        />
                    </div>
                    {errors.newPassword && <p className='basic-form__hint'>{errors.password.message}</p>}
                    <div className='basic-form__group'>
                        <label htmlFor='passwordConfirmation' className='basic-form__text-label'>{t('confirm_new_password')}</label>
                        <input
                            name='passwordConfirmation'
                            id='passwordConfirmation'
                            className='basic-form__text-box'
                            type='password'
                            ref={register}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className='basic-form__hint'>{errors.passwordConfirmation.message}</p>}
                    <div className='u-m-top-3'>
                        <Button className='u-m-right-1' type='submit'>{loading ? t('saving') : t('button.save')}</Button>
                        <Button type='button' outline onClick={() => closeModal()}>{t('button.cancel')}</Button>
                    </div>
                    </>
                }
            </form>
            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
            {
                success && (
                    <p className='basic-form__success'><Check className='basic-form__success__icon'/>{t('new_password_saved')}</p>
                )
            }
        </Fragment>
    )
}

export default AddPassword;
