import React, { useContext, useState, useEffect } from 'react';
import { useParams } from '@reach/router';
import ImageFadeIn from "react-image-fade-in";
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { parse } from "query-string";
import { LogOut } from 'react-feather';
import { Trans, useTranslation } from 'react-i18next';

import { Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName, getUrl } from '@axeedge/go-shared-utils';

import ForgotPassword from './ForgotPassword';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { LOGIN, LOGIN_FB } from '../services/graphql';
import { ADD_PARENT_MUTATION } from '../../SignUp/services/graphql';
import { APP_NAME } from '../../../services/constants';
import logo from '../../../images/boomer.png';
import LoginForm from '../components/LoginForm';
import loader from '../../../images/loader.svg';
const RedirectAfterLogin = ({ location }) => {
    const [token, setToken] = useState(ls(getAppTokenName(APP_NAME)));
    const [fbRes, setFbRes] = useState(null);
    const params = useParams();
    const [formErrors, setFormErrors] = useState([]);
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const client = useApolloClient();
    const auth = useContext(AuthContext);
    const { t } = useTranslation();

    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.parentLogIn.errors && data.parentLogIn.errors.length !== 0) {
                setFormErrors(data.parentLogIn.errors);
                return;
            }
            if (data.parentLogIn.parent && data.parentLogIn.parent.id && data.parentLogIn.token) {
                setToken(data.parentLogIn.token);
                ls(getAppTokenName(APP_NAME), data.parentLogIn.token);
                ls('go_parent_user', JSON.stringify(data.parentLogIn.parent));
                auth.setCurrentUser(data.parentLogIn.parent);
                client.writeData({
                    data: {
                        loggedIn: data.parentLogIn.token,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const [createFBAccount, {loading: creatingFb}] = useMutation(ADD_PARENT_MUTATION, {
        onCompleted: data => {
            if (data.addParent.errors && data.addParent.errors.length !== 0) {
                setFormErrors(data.addParent.errors);
                return;     
            }
            if (data.addParent.parent && data.addParent.parent.id) {
                fbLogin({
                    variables: {
                        email: data.addParent.parent.email,
                        facebookId: data.addParent.parent.facebookId,
                    }
                })
            }
        }
    })

    const [fbLogin, { loading: fbLoggingIn }] = useMutation(LOGIN_FB, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.parentLogInWithFb.errors && data.parentLogInWithFb.errors.length !== 0) {
                if(data.parentLogInWithFb.errors.includes("Invalid user")) {
                    createFBAccount({
                        variables: {
                            firstName: fbRes.first_name,
                            lastName: fbRes.last_name,
                            displayName: fbRes.name,
                            email: fbRes.email,
                            facebookId: fbRes.id,
                        }
                    })
                } else {
                    setFormErrors(data.parentLogInWithFb.errors);
                }
                return;
            }
            if (data.parentLogInWithFb.parent && data.parentLogInWithFb.parent.id && data.parentLogInWithFb.token) {
                ls(getAppTokenName(APP_NAME), data.parentLogInWithFb.token);
                ls('go_parent_user', JSON.stringify(data.parentLogInWithFb.parent));
                auth.setCurrentUser(data.parentLogInWithFb.parent);
                auth.setEmailLinked(data.parentLogInWithFb.emailLinked);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });


    const responseFacebook = (response) => {
        if (response && response.id) {
            setFbRes(response);
            fbLogin({
                variables: {
                    email: response.email,
                    facebookId: response.id,
                }
            })
        }
      }

    useEffect(() => {
        if (token) {
            window.location.href = `${getUrl(Number(params.app))}${parse(location.search).targetUrl}?token=${token}`;
        }
    }, [token, params.app, location.search]);

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <ImageFadeIn className='auth-page__logo' src={logo} />
                <h1 className='auth-page__title'>{t('login')}</h1>
                <div className='auth-page__logged-out'>
                    <LogOut className='auth-page__logged-out__icon' />
                    <p className='auth-page__logged-out__text'>
                        <Trans i18nKey='youve_been_logged_out'>You’ve been logged out.<br />Please log back in to continue</Trans>
                    </p>
                </div>
                {(fbLoggingIn || creatingFb) ?
                <img src={loader} alt="Loading..."/> :
                <LoginForm formErrors={formErrors} loggingIn={loggingIn} loginMethod={login} setModalForgotPass={setModalForgotPass} responseFacebook={responseFacebook} />
                }
            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default RedirectAfterLogin;
