import { gql } from 'apollo-boost';

export const GET_GROUP_BOOK_QUERY = gql`
query groupReadingBook($id: ID!) {
    groupReadingBook(id: $id) {
        id
        status
        book {
            id
            reviews {
                id
                author {
                    id
                }
                reviewText
                reviewRate
                date
            }
        }
        mostRecentLog {
            id
            pageEnd
            pageStart
        }
        logs {
            id
            author {
                id
                firstName
                lastName
                displayName
                role
            }
            problemWords {
                id
                word
            }
            isCompletion
            date
            entry
            pageEnd
            pageStart
            readingDate
            studentIds
        }
    }
}`

export const BOOK_ISBN_SEARCH_QUERY = gql`
query bookIsbnSearch($isbn: String!) {
    bookByIsbn(isbn: $isbn) {
        id
        author
        isbn
        isbn13
        coverUrl
        title
        description
        existsInDb
    }
}
`;

export const BOOK_TITLE_SEARCH_QUERY = gql`
query bookTitleSearch($title: String!, $searchApi: Boolean) {
    booksByTitle(title: $title, searchApi: $searchApi) {
        items {
            id
            author
            isbn
            isbn13
            coverUrl
            title
            description
            existsInDb
        }
    }
}
`;

export const ADD_READING_BOOK = gql`
mutation addReadingBookAndAssignToStudent($title: String!,
    $author: String!,
    $isbn: String,
    $isbn13: String,
    $coverUrl: String,
    $description: String,
    $bookType: Int!,
    $hideComments: Boolean!,
    $studentId: ID!) {
        addReadingBookAndAssignToStudent(title: $title, author: $author, isbn: $isbn, isbn13: $isbn13, coverUrl: $coverUrl, description: $description, studentId: $studentId, bookType: $bookType, hideComments: $hideComments) {
        studentReadingBook {
            id
        }
        errors
    }
}
`;



export const ASSIGN_READING_BOOK = gql`
mutation assignReadingBookToStudent(
    $bookId: ID!,
    $bookType: Int!,
    $hideComments: Boolean!,
    $studentId: ID!,
    $isReread: Boolean) {
        assignReadingBookToStudent(bookId: $bookId, studentId: $studentId, bookType: $bookType, hideComments: $hideComments, isReread: $isReread) {
        studentReadingBook {
            id
            addedBy {
                displayName
                id
            }
            book {
                id
                author
                coverUrl
                title
                isbn
                isbn13
                publisher {
                    id
                    name
                }
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            bookType
            completedAt
            hideComments
            isReread
            logs {
                id
                author {
                    id
                    firstName
                    lastName
                    displayName
                    role
                }
                problemWords {
                    id
                    word
                }
                isCompletion
                date
                entry
                pageEnd
                pageStart
                readingDate
            }
            noReview
            status
        }
        errors
    }
}
`;

export const ADD_READING_LOG = gql`
mutation addReadingBookLog(
        $studentReadingBookId: ID!
        $pageStart: Int!
        $pageEnd: Int!
        $entry: String!
        $problemWords: [String!]
        $finished: Boolean!,
        $readingDate: String!,
    ) {
        addReadingBookLog(studentReadingBookId: $studentReadingBookId, pageStart: $pageStart, pageEnd: $pageEnd, entry: $entry, problemWords: $problemWords, finished: $finished, readingDate: $readingDate) {
        studentReadingBook {
            id
        }
        errors
    }
}
`;

export const ADD_GROUP_READING_LOG = gql`
    mutation addGroupReadingBookLog(
        $groupReadingBookId: ID!
        $pageStart: Int!
        $pageEnd: Int!
        $entry: String!
        $problemWords: [String!]
        $finished: Boolean!,
        $readingDate: String!,
        $studentIds: [ID!]
    ) {
        addGroupReadingBookLog (groupReadingBookId: $groupReadingBookId, pageStart: $pageStart, pageEnd: $pageEnd, entry: $entry, problemWords: $problemWords, finished: $finished, readingDate: $readingDate, studentIds: $studentIds) {
            groupReadingBook {
                id
            }
            errors
        }
    }
`

export const EDIT_LOG = gql`
mutation editReadingLog($studentReadingBookLogId: ID!, $entry: String, $problemWords: [String!]) {
    editReadingBookLog(studentReadingBookLogId: $studentReadingBookLogId, entry: $entry, problemWords: $problemWords) {
        studentReadingBookLog {
            id
        }
        errors
    }
}
`;

export const DELETE_LOG = gql`
mutation deleteReadingBookLog($studentReadingBookLogId: ID!, $studentId: ID!) {
    deleteReadingBookLog(studentReadingBookLogId: $studentReadingBookLogId, studentId: $studentId) {
        deleted
        errors
    }
}
`;