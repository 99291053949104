import React, {useState} from 'react';
import cx from 'classnames';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';
import ImageFadeIn from 'react-image-fade-in';

import SignUpForm from './components/SignUpForm';
import appleBadge from '../../images/third-party/app-store-badge.svg';
import googleBadge from '../../images/third-party/google-play-badge.svg';
import AuthLinks from '../Login/components/AuthLinks';
import styles from './SignUp.module.scss';

const SignUp = () => {
    const { t } = useTranslation();
    const [showVid, setShowVid] = useState(false);

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <AuthLinks signup={true} />
                <h1 className={styles.signUpTitle}>{t('create_your_account')}</h1>
                {/* <Button outline onClick={() => {setShowVid(!showVid)}}>{showVid ? t('hide_video_guide') : t('learn_how_it_works')}</Button> */}
                {showVid && 
                    <>
                        <div className={styles.closeBg}  onClick={() => setShowVid(false)}></div>
                        <div className={styles.videoPanel}>
                            <Icon.XCircle className={styles.closeVideo} onClick={() => setShowVid(false)} />
                            <div style={{padding:'56.25% 0 0 0', position: 'relative', clear: 'both'}}>
                                <iframe src={`https://player.vimeo.com/video/452529818?autoplay=1&title=0&byline=0&portrait=0`} style={{position:'absolute',top:'0',left:'0',width:'100%',height:'100%'}} frameBorder='0' allow='fullscreen' allowFullScreen title={t('into_video')}></iframe>
                                </div>
                                <script src='https://player.vimeo.com/api/player.js'></script>
                        </div>
                    </>
                }
                <div className={styles.signUpAppPanel}>
                    <div className={styles.signUpAppText}>
                        <p className={cx('auth-page__subtitle u-m-base-2', styles.signUpSubtitle)}>{t('download_app')}</p>
                        <p className={cx('u-m-base-2', styles.signUpText)}>{t('download_blurb')}</p>
                    </div>
                    
                    <div className={styles.signUpAppLinks}>
                        <a 
                            href="https://apps.apple.com/us/app/goapps-parents/id1552398474?itsct=apps_box&amp;itscg=30200" 
                            className={styles.signUpApple}
                        >
                            <ImageFadeIn src={appleBadge} alt='Download on the App Store' />
                        </a>
                        <a className={styles.signUpAndroid} href='https://play.google.com/store/apps/details?id=app.goapps.parents&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <ImageFadeIn src={googleBadge} alt='Get it on Google Play' />
                        </a>
                    </div>
                </div>
                <div className='u-m-top-20'>
                    <SignUpForm />
                </div>
            </div>
        </div>
    )
}

export default SignUp;