import React, { useContext, useState } from 'react';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';
import ImageFadeIn from 'react-image-fade-in';

import { Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName, TERMS_VERSION } from '@axeedge/go-shared-utils';

import ForgotPassword from './scenes/ForgotPassword';
import { AuthContext } from '../../services/auth/AuthProvider';
import { LOGIN, LOGIN_FB } from './services/graphql';
import { ADD_PARENT_MUTATION } from '../SignUp/services/graphql';
import { APP_NAME } from '../../services/constants';
import loader from '../../images/loader.svg';
import LoginForm from './components/LoginForm';
import styles from './Login.module.scss';
import appleBadge from '../../images/third-party/app-store-badge.svg';
import googleBadge from '../../images/third-party/google-play-badge.svg';
import AuthLinks from './components/AuthLinks';

const Login = () => {
    const [formErrors, setFormErrors] = useState([]);
    const [fbRes, setFbRes] = useState(null);
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const client = useApolloClient();
    const auth = useContext(AuthContext);
    const { t } = useTranslation();

    const [createFBAccount, {loading: creatingFb}] = useMutation(ADD_PARENT_MUTATION, {
        onCompleted: data => {
            if (data.addParent.errors && data.addParent.errors.length !== 0) {
                setFormErrors(data.addParent.errors);
                return;     
            }
            if (data.addParent.parent && data.addParent.parent.id) {
                fbLogin({
                    variables: {
                        email: data.addParent.parent.email,
                        facebookId: data.addParent.parent.facebookId,
                    }
                })
            }
        }
    })

    const [login, { loading: loggingIn }] = useMutation(LOGIN, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (!data || (data.parentLogIn.errors && data.parentLogIn.errors.length) !== 0) {
                setFormErrors(data.parentLogIn.errors || 'This user invalid');
                return;
            }
            if (data.parentLogIn.parent && data.parentLogIn.parent.id && data.parentLogIn.token) {
                ls(getAppTokenName(APP_NAME), data.parentLogIn.token);
                ls('go_parent_user', JSON.stringify(data.parentLogIn.parent));
                auth.setCurrentUser(data.parentLogIn.parent);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const [fbLogin, { loading: fbLoggingIn }] = useMutation(LOGIN_FB, {
        awaitRefetchQueries: true,
        onCompleted: data => {
            if (data.parentLogInWithFb.errors && data.parentLogInWithFb.errors.length !== 0) {
                if(data.parentLogInWithFb.errors.includes('Invalid user')) {
                    createFBAccount({
                        variables: {
                            firstName: fbRes.first_name,
                            lastName: fbRes.last_name,
                            displayName: fbRes.name,
                            email: fbRes.email,
                            facebookId: fbRes.id,
                            termsVersionApproved: (TERMS_VERSION).toString()
                        }
                    })
                } else {
                    setFormErrors(data.parentLogInWithFb.errors);
                }
                return;
            }
            if (data.parentLogInWithFb.parent && data.parentLogInWithFb.parent.id && data.parentLogInWithFb.token) {
                ls(getAppTokenName(APP_NAME), data.parentLogInWithFb.token);
                ls('go_parent_user', JSON.stringify(data.parentLogInWithFb.parent));
                auth.setCurrentUser(data.parentLogInWithFb.parent);
                auth.setEmailLinked(data.parentLogInWithFb.emailLinked);
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
            }
        }
    });

    const responseFacebook = (response) => {
        if (response && response.id) {
            setFbRes(response);
            fbLogin({
                variables: {
                    email: response.email,
                    facebookId: response.id,
                }
            })
        }
      }

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <AuthLinks />
                <h1 className={styles.loginTitle}>{t('parent_login')}</h1>
                <div className={styles.loginAppsContainer}>
                    <p className={cx('auth-page__subtitle', styles.loginSubtitle)}>{t('download_app')}</p>
                    <div className={styles.loginAppLinks}>
                        <a 
                            href='https://apps.apple.com/us/app/goapps-parents/id1552398474?itsct=apps_box&amp;itscg=30200' 
                            className={styles.loginApple}
                        >
                            <ImageFadeIn src={appleBadge} alt='Download on the App Store' />
                        </a>
                        <a className={styles.loginAndroid} href='https://play.google.com/store/apps/details?id=app.goapps.parents&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1'>
                            <ImageFadeIn src={googleBadge} alt='Get it on Google Play' />
                        </a>
                    </div>    
                </div>
                
                {
                    (fbLoggingIn || creatingFb) ?
                    <img src={loader} alt={t('loading')}/> :
                    <LoginForm formErrors={formErrors} loggingIn={loggingIn} loginMethod={login} setModalForgotPass={setModalForgotPass} responseFacebook={responseFacebook} />
                }
            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}
export default Login;
