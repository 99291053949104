import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';

const ErrorModal = ({ closeModal, message }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <h1>{t('payment_failed')}</h1>
            <p className='u-m-base-2'>
                {message}
            </p>
            <Button onClick={() => closeModal()}>{t('button.ok')}</Button>
        </Fragment>
    )
}

export default ErrorModal;