import React from 'react';
import { useLocation } from '@reach/router';

import LoggedOutHeader from '../../../components/LoggedOutHeader';

const LoggedOutRouter = ({ children }) => {
    const location = useLocation();
    return (
        <div className='logged_out__layout'>
            {!location.pathname.includes('store') && !location.pathname.includes('error') && <LoggedOutHeader />}
            {children}
        </div>
    )
}

export default LoggedOutRouter;
