import React, { Fragment, useState, useContext } from 'react';
import { Link } from '@reach/router';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import cx from 'classnames'
import { useTranslation } from 'react-i18next';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { AuthContext } from '../../services/auth/AuthProvider';
import ProfileImage from '../../components/ProfileImage';
import SaveConfirm from '../../components/SaveConfirm';
import { EDIT_TEACHER_AVATAR_MUTATION, EDIT_PARENT_MUTATION} from './services/graphql';
import defaultImg from './images/default.png'
/*import { UK_COUNTIES } from '../../services/constants';*/
import styles from './ParentProfile.module.scss';
import tabStyles from '../AccountSettings/AccountSettings.module.scss';


const ParentProfile = () => {
    const { t } = useTranslation();
    const [formErrors, setFormErrors] = useState([]);
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);
    const [showCropModal, setShowCropModal] = useState(false);

    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [editParent, { loading: savingProfile }] = useMutation(EDIT_PARENT_MUTATION, {
        update: (_, { data }) => {
            if (data.editParent.errors && data.editParent.errors.length !== 0) {
                setFormErrors(data.editParent.errors);
                return;
            }

            if (data.editParent.parent.id) {
                setCurrentUser(data.editParent.parent);
                setShowSaveConfirm(true);
            }
        },
    });

    const schema = Yup.object().shape({
        firstName: Yup.string().required(t('req_enter_firstname')),
        lastName: Yup.string().required(t('req_enter_lastname')),
        street: Yup.string().required(t('req_enter_street')),
        city: Yup.string().required(t('req_enter_city')),
        postcode: Yup.string().required(t('req_enter_postcode'))
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        const { firstName, lastName, street, city, postcode } = values;
        editParent({
            variables: {
                parentId: currentUser.id, 
                firstName,
                lastName,
                displayName: `${firstName} ${lastName}`, 
                street,
                city, 
                county: currentUser.county || '', 
                postcode, 
            }
        });
    }

    const [saveNewAvatar, { loading: savingAvatar }] = useMutation(EDIT_TEACHER_AVATAR_MUTATION, {
        update: (_, { data }) => {
            if (data.addTeacherAvatar.errors && data.addTeacherAvatar.errors.length !== 0) {
                alert(data.editTeacher.errors[0]);
                return;
            }

            if (data.addTeacherAvatar.teacher.id) {
                setCurrentUser({
                    ...currentUser,
                    avatarUrl: data.addTeacherAvatar.teacher.avatarUrl
                });
                setShowCropModal(false);
                setShowSaveConfirm(true);
            }
        }
    });

    return (
        <Fragment>
            {/*<PageHeader title='Your Profile' icon={User} />*/}
            <div className={tabStyles.profileTabs}>
                <Link to={`/profile`} className={cx(tabStyles.profileTab, tabStyles.profileTabActive)}>
                    {t('your_profile')}
                </Link>
                <Link to={`/account`} className={tabStyles.profileTab}>
                    {t('account_settings')}
                </Link>
            </div>
            <div className='card card-mobile'>
                <div className={styles.contentHolder}>
                    <div className={styles.contentHolderLeft}>
                        <ProfileImage
                            defaultImg={currentUser.avatarUrl || defaultImg}
                            heading={t('profile_photo')}
                            imgName={`teachers/profile${currentUser.id}`}
                            onSave={saveNewAvatar}
                            savingImage={savingAvatar}
                            setShowCropModal={setShowCropModal}
                            showCropModal={showCropModal}
                            width={140}
                        />
                        <form className={styles.form} onSubmit={handleSubmit(onSubmit)}>
                            <div className='basic-form__group'>
                                <label htmlFor='firstName' className='basic-form__text-label'>{t('first_name')}</label>
                                <input
                                    name='firstName'
                                    id='firstName'
                                    placeholder={t('ph_first_name')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    defaultValue={currentUser.firstName}
                                    type='text'
                                />
                            </div>
                            {errors.firstName && <p className='basic-form__hint'>{errors.firstName.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='lastName' className='basic-form__text-label'>{t('last_name')}</label>
                                <input
                                    name='lastName'
                                    id='lastName'
                                    placeholder={t('ph_last_name')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    defaultValue={currentUser.lastName}
                                    type='text'
                                />
                            </div>
                            {errors.lastName && <p className='basic-form__hint'>{errors.lastName.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='street' className='basic-form__text-label'>{t('street_address')}</label>
                                <input
                                    name='street'
                                    id='street'
                                    placeholder={t('ph_street')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    defaultValue={currentUser.street || null}
                                    type='text'
                                />
                            </div>
                            {errors.street && <p className='basic-form__hint'>{errors.street.message}</p>}
                            <div className='basic-form__group'>
                                <label htmlFor='city' className='basic-form__text-label'>{t('city')}</label>
                                <input
                                    name='city'
                                    id='city'
                                    placeholder={t('ph_city')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    defaultValue={currentUser.city}
                                    type='text'
                                />
                            </div>
                            {errors.city && <p className='basic-form__hint'>{errors.city.message}</p>}
                            {/* <div className='basic-form__group'>
                                <label htmlFor='county' className='basic-form__text-label'>County</label>
                                <select name='county' defaultValue={currentUser.county || ''} ref={register} className='basic-form__text-select'>
                                    <option value=''>County</option>
                                    {
                                        UK_COUNTIES.map((c, i) => {
                                            return <option key={i} value={c}>{c}</option>
                                        })
                                    }
                                </select>
                            </div>
                            {errors.county && <p className='basic-form__hint'>{errors.county.message}</p>} */}
                            <div className='basic-form__group'>
                                <label htmlFor='postcode' className='basic-form__text-label'>{t('postcode')}</label>
                                <input
                                    name='postcode'
                                    id='postcode'
                                    placeholder={t('ph_postcode')}
                                    className='basic-form__text-box'
                                    ref={register}
                                    defaultValue={currentUser.postcode}
                                    type='text'
                                />
                            </div>
                            {errors.postcode && <p className='basic-form__hint'>{errors.postcode.message}</p>}
                            
                            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                            <div className='u-display-flex u-align-center'>
                                <Button type='submit' primary className='u-m-right-1'>{savingProfile ? t('saving') : t('button.save')}</Button>
                                <Button 
                                    outline 
                                    onClick={() => {
                                        reset({
                                            firstName: currentUser.firstName, 
                                            lastName: currentUser.lastName, 
                                            displayName: currentUser.displayName, 
                                            email: currentUser.email
                                        })
                                    }} 
                                    type='button'
                                >
                                    {t('button.cancel')}
                                </Button>
                            </div>
                            {showSaveConfirm && <SaveConfirm hideConfirm={setShowSaveConfirm} />}
                        </form>
                    </div>
                </div>
            </div>
            </Fragment>
    )
}

export default ParentProfile;
