import React from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery} from '@apollo/react-hooks';

import { BOOK_TITLE_SEARCH_QUERY } from '../services/graphql';
import TitleSearchResults from './TitleSearchResults';

const TitleSearchQueries = ({code, studentId, close}) => {
    const { t } = useTranslation();
    const { data, error, loading } = useQuery(BOOK_TITLE_SEARCH_QUERY, {
        variables: {
            title: code,
            searchApi: false,
        },
        fetchPolicy: 'network-only',
    });


    if (error) {
    return <p>{error && error.message && error.message}</p>
    }

    if (loading) {
        return <p>{t('loading')}</p>
    }

   if (data) {
        return <TitleSearchResults multipleBooks={data} code={code} studentId={studentId} close={close} />
    }
    return null
}

export default TitleSearchQueries;