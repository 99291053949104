import React from 'react';
import styles from './bookTypeLabel.module.scss';
import cx from 'classnames';
import { BOOK_TYPES } from '../../services/constants';

const TypeLabel = ({type}) => {
    const bookType = BOOK_TYPES.find(t => t.type === type);

    const labelClass = type => {
        switch(type) {
            case 1:
                return styles.typeLabel
            case 2:
            case 3:
                return styles.typeLabelAlt
            case 4:
                return styles.typeLabelGroup
            default:
              return styles.typeLabelAlt
          }
    }

    return (
        <span className={labelClass(type)}>{bookType ? bookType.label : "Book"}</span>
    )
}

export default TypeLabel;
