import React, { useState, Fragment } from 'react';
import ImageFadeIn from 'react-image-fade-in';
import cx from 'classnames';
import { Facebook, Mail } from 'react-feather';
import { useTranslation } from 'react-i18next';
import { useLazyQuery } from '@apollo/react-hooks';

import mailImg from '../../images/confirm-email.png'
import styles from './Pending.module.scss';
import { RESEND_WELCOME_EMAIL } from './services/graphql';
import SaveConfirm from '../../components/SaveConfirm';

const Pending = ({ location }) => {
    const { t } = useTranslation();
    const [showSaveConfirm, setShowSaveConfirm] = useState(false);

    const [resendEmail, { loading }] = useLazyQuery(RESEND_WELCOME_EMAIL, {
        onCompleted: data => {
            setShowSaveConfirm(true)
        }
    });

    return (
        <div className={cx('auth-page__container', styles.pending)}>
            <div className='auth-page__panel'>
                <p className={styles.pendingTitle}>{t('thanks_for_signing_up')}</p>
                <ImageFadeIn src={mailImg} className={styles.pendingMailImg} />
                <p className='u-m-base-2'>{t('to_get_started')}</p>

                {location && location.state && location.state.confirm_email && <p className='u-m-base-2 heavy'>{location.state.confirm_email }</p>}
                <p>{t('simply_click_link')}</p>
                
                {
                    location && location.state && location.state.confirm_email  && (
                        <Fragment>
                            <button
                                onClick={() => resendEmail({
                                    variables: {email: location.state.confirm_email}
                                })}
                                className='btn-reset u-m-top-2 link-underline'
                            >
                                {loading? t('resending') : t('havent_got_email')}
                            </button>
                            {showSaveConfirm && <SaveConfirm message={t('email_sent')} hideConfirm={setShowSaveConfirm} />}
                        </Fragment>
                    )
                }
                <p className={styles.pendingSubTitle}>{t('need_help')}</p>
                <p className='u-m-base-1'>{t('having_trouble')}</p>
                <a className={styles.pendingContactLink} target='_blank' rel='noopener noreferrer' href={t('help_facebook_page')}><Facebook /><span>{t('message_us_fb')}</span></a>
                <a className={styles.pendingContactLink} href={`mailto:${t('help_email_address')}`}><Mail /><span>{t('help_email_address')}</span></a>
            </div>
            <div className='auth-page__extra'>
                <p>{t('back_to_websites')}</p>
                <a href='https://boomwriter.co.uk/' rel='noopener noreferrer'>BoomWriter</a>
                <a href='https://boomreader.co.uk/' rel='noopener noreferrer'>BoomReader</a>
            </div>
        </div>
    )
}

export default Pending;