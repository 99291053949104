import React from 'react';
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

const RecentWords = ({ books, styles }) => {
    const { t } = useTranslation();
    let words = [];
    const currDate = moment(new Date());
    const MAX_DAYS_AGO = 14; 
    _.map(books, book => {
        const recentLogs = book.logs.filter(log => currDate.diff(moment(log.date), 'days') <= MAX_DAYS_AGO);
        _.map(recentLogs, log => {
            words = words.concat(log.problemWords);
        })
    });
    if (words.length > 0)
        return _.map(_.takeRight(_.uniqBy(words, 'word'), 5), word => {
            return <li key={`problemWord${word.id}`} className={styles.recentWord}>{word.word}</li>
        });
    return <p className={styles.noResults}>{t('no_problem_words')}</p>
}

export default RecentWords;
