import React, { useState } from 'react';
import _ from 'lodash';
import cx from 'classnames';
import * as Icon from 'react-feather';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';

import { BOOK_TYPES } from '../../../../../services/constants';
import { ADD_READING_BOOK, ASSIGN_READING_BOOK} from '../services/graphql';
import { CURRENT_PARENT_QUERY  } from '../../../../Home/services/graphql';
import BookTile from '../../../../../components/BookTile';

import styles from './addBook.module.scss';


const TitleSearchResults = ( {multipleBooks, studentId, close} ) => {
    const { t } = useTranslation();
    const [selectedBook, setSelectedBook] = useState({open: false, book: null})

    const [readingBook, setReadingBook] = useState({
        bookType: 3,
        hideComments: true,
        classBook: false,
    });


    const [addReadingBook, { loading }] = useMutation(ADD_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close()},
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            }
        ]
    });

    const [assignReadingBook, { loading: assignLoading }] = useMutation(ASSIGN_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close()},
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            }
        ]
    });

    const AddBookForm = ({book}) => {
        return (
            <>
                <div className={cx(styles.addBookHeader, 'u-m-base-20')}>
                    <div>
                        <h1 className={styles.bookHeading}>{book.title}</h1>
                        <h2 className={styles.bookAuthor}>{book.author}</h2>
                        <div className={styles.resultBookCode}>ISBN: {book.isbn}</div>
                        <div className={cx(styles.resultBookCode, 'u-m-base-5')}>ISBN13: {book.isbn13}</div>
                    </div>
                </div>
                <div className={styles.addBookField}>
                    <label className="u-m-right-5">{t('book_type_colon')}</label>
                    <select value={readingBook.bookType} className={styles.styledSelect} onChange={(e) => setReadingBook({...readingBook, bookType: parseInt(e.currentTarget.value)})}>
                        {_.map(BOOK_TYPES, type => {
                            if (type.type !== 2 && type.type !== 4) {
                                return (
                                    <option key={`type${type.type}`} value={type.type}>{type.label}</option>
                                )
                            }
                            
                        })}
                    </select>
                </div>
                {!book.existsInDb ?
                    <Button className='u-m-top-20' onClick={() => {addReadingBook({
                        variables: {
                            studentId: studentId,
                            author: book.author,
                            title: book.title,
                            isbn: book.isbn,
                            isbn13: book.isbn13,
                            coverUrl: book.coverUrl,
                            description: "test description",
                            bookType: readingBook.bookType,
                            hideComments: !readingBook.hideComments,
                        }
                    })}} disabled={loading && true}>{loading ? t('adding') : t('add_book')}</Button> :
                    <Button className='u-m-top-20' onClick={() => {assignReadingBook({
                        variables: {
                            studentId: studentId,
                            bookId: book.id,
                            bookType: readingBook.bookType,
                            hideComments: !readingBook.hideComments,
                        }
                    })}} disabled={assignLoading && true}>
                        {assignLoading ? t('adding') : t('add_book')}</Button>
                }
            </>
        )
    } 

    const bookListing = (book) => {
        return (
            <BookTile key={`titleBook${book.isbn}`} book={book} event={() => setSelectedBook({open: true, book: book})} />
        )
    }
    return (
        <div>
            <div className={cx(styles.bookResults, "u-m-top-20")}>
                {_.map(multipleBooks.booksByTitle.items, book => {
                    return bookListing(book);
                })}
                {(multipleBooks.booksByTitle.items.length === 0) && <p clasName="u-m-top-20">{t('no_matching_books_found')}</p>}
            </div>
            <div className={cx(styles.bookModal,selectedBook.open && styles.bookModalOpen)}>
                {selectedBook.book && <AddBookForm book={selectedBook.book} />}
            </div>
            {selectedBook.open && <Icon.XCircle onClick={() => {setSelectedBook({...selectedBook, open: false})}} className={cx(styles.closeBook, styles.closeBookAlt)}/> }
        </div>
    )
}

export default TitleSearchResults;