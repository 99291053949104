import React from 'react';
import { navigate } from '@reach/router';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';

import { Button } from '@axeedge/go-teacher-components';

const Activation = () => {
    const schema = Yup.object().shape({
        code: Yup.number()
            .required('Please enter your activation code')
    });

    const { register, handleSubmit, errors } = useForm({
        validationSchema: schema,
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { code } = values;
        navigate(`/setPassword/${code}`);
    }

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <h1 className='auth-page__title'>We've emailed you an activation code.</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                    <div className='basic-form__group'>
                        <input
                            name='code'
                            placeholder='Code e.g. 12345'
                            className='basic-form__text-box'
                            ref={register}
                            type='text'
                        />
                    </div>
                    {errors.code && <p className='basic-form__hint'>{errors.code.message}</p>}
                    <div className='u-display-flex'>
                        <Button type='submit' primary>Create Account</Button>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Activation;