import React, { useState, useEffect } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { navigate, Link } from '@reach/router';
import ImageFadeIn from 'react-image-fade-in';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { Loader } from 'react-feather';
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import cx from 'classnames';
import { Trans, useTranslation } from 'react-i18next';
import { ArrowLeft } from 'react-feather';
import { Button, Modal } from '@axeedge/go-teacher-components';

import { BOOK_BY_CODE_QUERY, CREATE_ORDER_MUTATION, CREATE_WTR_ORDER_MUTATION, CREATE_STRIPE_INTENT_MUTATION } from '../services/graphql';
import noCover from '../../../images/no-cover.png';
import { BOOK_POSTAGE, INDIA_BOOK_POSTAGE } from '../../../services/constants';
import Totals from '../components/Totals';
import TermsModal from '../components/TermsModal';
import visaLogo from '../images/1.png';
import mcLogo from '../images/2.png';
import aeLogo from '../images/22.png';
import stripeLogo from '../images/powered_by_stripe.svg';
import ErrorModal from '../components/ErrorModal';
import brianImg from '../../../images/wtr/brian-cutout.png';
import wtrLogo from '../../../images/wtr/wtr-logo-wide.png';
import sharedStyles from '../SharedStore.module.scss';

const paymentErrorMessage = "We're sorry, there was an error processing your payment. Please check the card details you have provided, or try again with a different payment method.";

const OrderBook = ({ book: bookData, code, defaultAmounts, indiaAmounts, styles, user }) => {
    const { t } = useTranslation();
    const [showErrorModal, setShowErrorModal] = useState(false);
    const [showTermsModal, setShowTermsModal] = useState(false);
    const [deliverToUser, setDeliverToUser] = useState(user ? true : false);
    const [processing, setProcessing] = useState(false);
    const [termsAccepted, setTermsAccepted] = useState(false);
    const [billingDetails, setBillingDetails] = useState(null);
    const [card, setCard] = useState(null);
    const [book, setBook] = useState(bookData);
    const [contactPhone, setContactPhone] = useState(null);

    const [price, setPrice] = useState();
    const [totals, setTotals] = useState(null);

    const getIndiaShipping = copies => INDIA_BOOK_POSTAGE * Math.ceil(copies / 3);

    const getTotals = (book, copies) => {
        if (book.classBook.activeWriteToRaisePromotion) {
            return defaultAmounts.amount
        }
        if (book.classBook.bookTemplate.isBeeTemplate) {
            return (indiaAmounts.amount * copies) + getIndiaShipping(copies)
        }
        return defaultAmounts.amount * copies + BOOK_POSTAGE
    }

    useEffect(() => {
        if (book) {
            setPrice(book.classBook.bookTemplate.isBeeTemplate ? indiaAmounts.amount : book.classBook.bookTemplate.isPromo ? defaultAmounts.promoAmount : defaultAmounts.amount);
            setTotals({
                copies: book.classBook.bookTemplate.isBeeTemplate ? 2 : 1,
                shipping: book.classBook.bookTemplate.isBeeTemplate ? INDIA_BOOK_POSTAGE : BOOK_POSTAGE,
                total: getTotals(book, book.classBook.bookTemplate.isBeeTemplate ? 2 : 1)
            });
        }
    }, [book]);



    const stripe = useStripe();
    const elements = useElements();

    const [getBook, { loading }] = useLazyQuery(BOOK_BY_CODE_QUERY, {
        fetchPolicy: 'network-only',
        variables: {
            code
        },
        onCompleted: ({ bookByCode }) => {
            if (!bookByCode || !bookByCode.classBook) {
                navigate('/store/code');
            } else {
                setBook(bookByCode);
                setPrice(bookByCode.classBook.bookTemplate.isBeeTemplate ? indiaAmounts.amount : bookByCode.classBook.bookTemplate.isPromo ? defaultAmounts.promoAmount : defaultAmounts.amount);
                setTotals({
                    copies: bookByCode.classBook.bookTemplate.isBeeTemplate ? 2 : 1,
                    shipping: bookByCode.classBook.bookTemplate.isBeeTemplate ? INDIA_BOOK_POSTAGE : BOOK_POSTAGE,
                    total: getTotals(bookByCode, bookByCode.classBook.bookTemplate?.isBeeTemplate ? 2 : 1)
                });

            }
        }
    });

    useEffect(() => {
        if (!book && !loading) {
            getBook()
        }
    }, [book, getBook, loading]);

    const schema = Yup.object().shape({
        buyerName: Yup.string()
            .required(t('req_buyer_name')),
        buyerEmail: Yup.string().email()
            .required(t('req_buyer_email')),
        buyerAddress1: Yup.string()
            .required(t('req_buyer_address')),
        buyerCity: Yup.string()
            .required(t('req_buyer_city')),
        buyerPostcode: Yup.string()
            .required(book?.classBook?.bookTemplate?.isBeeTemplate ? 'Please enter PIN' : t('req_buyer_postcode')),
        buyerPhone: Yup.string().required('Please enter contact phone'),
    });

    const schemaWtr = Yup.object().shape({
        buyerName: Yup.string()
            .required(t('req_buyer_name')),
        buyerEmail: Yup.string().email()
            .required(t('req_buyer_email')),
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const { register: registerWtr, handleSubmit: handleSubmitWtr, errors: errorsWtr, reset: resetWtr } = useForm({
        resolver: yupResolver(schemaWtr),
        mode: 'onSubmit'
    });

    const [createIndividualBookOrder] = useMutation(CREATE_ORDER_MUTATION, {
        onCompleted: ({ createIndividualBookOrder }) => {
            setProcessing(false);
            if (createIndividualBookOrder.errors && createIndividualBookOrder.errors.length !== 0) {
                alert(createIndividualBookOrder.errors[0]);
            } else {
                navigate(`/store/order/${createIndividualBookOrder.printBookOrder.orderNumber}`, { state: { isBeeTemplate: book?.classBook?.bookTemplate.isBeeTemplate } });
            }
        }
    })

    const [createWriteToRaiseIndividualPrintBookOrder] = useMutation(CREATE_WTR_ORDER_MUTATION, {
        onCompleted: ({ createWriteToRaiseIndividualPrintBookOrder }) => {
            setProcessing(false);
            if (createWriteToRaiseIndividualPrintBookOrder.errors && createWriteToRaiseIndividualPrintBookOrder.errors.length !== 0) {
                alert(createWriteToRaiseIndividualPrintBookOrder.errors[0]);
            } else {
                navigate(`/store/order/${createWriteToRaiseIndividualPrintBookOrder.printBookOrder.orderNumber}`, { state: { isWriteToRaise: true, bookCode: code } });
            }
        }
    })


    const onPaymentIntent = async (clientSecret) => {
        // Use your card Element with other Stripe.js APIs
        const payload = await stripe.confirmCardPayment(clientSecret, {
            payment_method: {
                type: 'card',
                card,
                billing_details: billingDetails
            }
        })

        if (payload.error) {
            setProcessing(false);
            setShowErrorModal(paymentErrorMessage);
        } else {
            if (payload.paymentIntent.status === 'succeeded') {
                //WriteToRaise order mutation
                if (book.classBook.activeWriteToRaisePromotion) {
                    const { name, email } = billingDetails;
                    createWriteToRaiseIndividualPrintBookOrder({
                        variables: {
                            classBookId: book.classBook.id,
                            orderDetails: JSON.stringify({
                                buyerName: name,
                                studentName: `${book.student.firstName} ${book.student.lastName}`,
                                studentCode: code.split('-')[1],
                                copies: totals.copies,
                                totalCharge: totals.total,
                            }),
                            name: name,
                            email: email,
                            paymentIntentId: payload.paymentIntent.id
                        }
                    });
                } else {
                    const { name, email, address: { city, line1, line2, postal_code } } = billingDetails;
                    createIndividualBookOrder({
                        variables: {
                            classBookId: book.classBook.id,
                            orderDetails: JSON.stringify({
                                buyerName: name,
                                studentName: `${book.student.firstName} ${book.student.lastName}`,
                                studentCode: code.split('-')[1],
                                copies: totals.copies,
                                totalCharge: totals.total,
                                totalShipping: totals.shipping,
                            }),
                            name: name,
                            email: email,
                            shippingToStreet: line1,
                            shippingToCity: city,
                            shippingToPostcode: postal_code,
                            shippingToAddress2: line2,
                            paymentIntentId: payload.paymentIntent.id,
                            contactPhone: contactPhone
                        }
                    })
                }
            }
            if (payload.paymentIntent.status === 'requires_payment_method') {
                setProcessing(false);
                setShowErrorModal(paymentErrorMessage);
            }
        }
    }

    const [createStripePaymentIntent] = useMutation(CREATE_STRIPE_INTENT_MUTATION, {
        onCompleted: ({ createStripePaymentIntent }) => {
            if (createStripePaymentIntent.errors && createStripePaymentIntent.errors.length !== 0) {
                alert(createStripePaymentIntent.errors[0]);
            } else {
                onPaymentIntent(createStripePaymentIntent.response);
            }
        },
        variables: {
            amount: totals && totals.total,
            numberOfCopies: totals && totals.copies,
            bookCode: code
        }
    })

    const onSubmit = async (values) => {
        const { buyerName, buyerEmail, buyerAddress1, buyerAddress2, buyerCity, buyerPostcode, buyerPhone } = values;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }

        setProcessing(true);
        setContactPhone(buyerPhone);

        setBillingDetails({
            name: buyerName,
            email: buyerEmail,
            address: {
                city: buyerCity,
                line1: buyerAddress1,
                line2: buyerAddress2,
                postal_code: buyerPostcode
            }
        });
        setCard(elements.getElement(CardElement))

        createStripePaymentIntent();
    }

    const onSubmitWtr = async (values) => {
        const { buyerName, buyerEmail } = values;
        if (!stripe || !elements) {
            // Stripe.js has not loaded yet. Make sure to disable
            // form submission until Stripe.js has loaded.
            return;
        }
        setProcessing(true);
        setBillingDetails({
            name: buyerName,
            email: buyerEmail,
        });
        setCard(elements.getElement(CardElement))
        createStripePaymentIntent();
    }

    const changeDeliverToUser = changeTo => {
        reset({
            buyerName: changeTo ? `${user.firstName} ${user.lastName}` : '',
            buyerAddress1: changeTo ? user.street : '',
            buyerCity: changeTo ? user.city : '',
            buyerPostcode: changeTo ? user.postcode : ''
        });
        setDeliverToUser(changeTo);
    }

    const onChangeCopies = e => {
        let copies = Number(e.target.value);
        if (book.classBook.bookTemplate.isBeeTemplate) {
            if (copies < 2) {
                copies = 2
            } else if (copies % 2 !== 0) {
                copies = parseInt(Math.round(copies / 2) * 2);
            }
        }
        setTotals({
            copies,
            shipping: book.classBook.bookTemplate.isBeeTemplate ? getIndiaShipping(copies) : BOOK_POSTAGE,
            total: getTotals(book, copies)
        })
    }

    const changeTermsAccepted = showModal => {
        if (showModal) {
            setShowTermsModal(true);
        } else {
            setTermsAccepted(!termsAccepted);
        }

    }

    if (loading) {
        return <p>Loading...</p>
    }

    if (!book) {
        return null;
    }

    return (
        <>
            {book.classBook.activeWriteToRaisePromotion && (
                <div className={sharedStyles.wtrBanner}>
                    <div className={sharedStyles.wtrBannerImg}>
                        <ImageFadeIn src={brianImg} alt='Brian Blessed' />
                    </div>
                    <div className={sharedStyles.wtrBannerContent}>
                        <ImageFadeIn className={sharedStyles.wtrBannerLogo} src={wtrLogo} alt='Brian Blessed' />
                        <h1 className={sharedStyles.wtrBannerTitle}>
                            Your school receives £4.99 for every copy purchased!
                        </h1>
                        <h2 className={sharedStyles.wtrBannerSubtitle}>
                            Orders must be placed before March 31st
                        </h2>
                    </div>
                </div>
            )}
            <Link to='/store/code' className={styles.backToStore}><ArrowLeft /> This is not my book</Link>
            <div className={sharedStyles.bookInfo}>
                <ImageFadeIn src={book.classBook.bookTemplate.coverUrl || noCover} className={sharedStyles.bookInfoCover} />
                <div className={sharedStyles.bookInfoDetails}>
                    <h1 className={sharedStyles.bookInfoDetailsTitle}>{book.classBook.title || book.classBook.bookTemplate.name}</h1>
                    <p className={sharedStyles.bookInfoDetailsFeaturing}>{t('featuring_colon')} <b>{book.student?.firstName} {book.student?.lastName}</b></p>

                    <div className={cx(sharedStyles.bookInfoDetailsCopies, { [sharedStyles.wtrCopies]: book.classBook.activeWriteToRaisePromotion })}>
                        {!book.classBook.bookTemplate.isBeeTemplate &&
                            <div className={sharedStyles.bookInfoDetailsCopiesMain}>
                                <b className='u-m-right-2'>{t('copies')}</b>
                                {totals && (
                                    <input
                                        name='copies'
                                        className='basic-form__text-number u-m-base-0'
                                        value={totals.copies}
                                        onChange={onChangeCopies}
                                        min={book.classBook.bookTemplate.isBeeTemplate ? 2 : 1}
                                        step={book.classBook.bookTemplate.isBeeTemplate ? 2 : 1}
                                        type='number'
                                    />
                                )}
                                {price && <span className={sharedStyles.bookInfoDetailsPrice}>{book.classBook.bookTemplate.isBeeTemplate ? '₹' : t('currency_icon')}{(price / 100).toFixed(2)} {t('each')}</span>}
                            </div>
                        }
                        {book.classBook.activeWriteToRaisePromotion && (
                            <div className={sharedStyles.wtrCopiesRaise}>
                                <div>You'll raise</div>
                                <span>{(totals?.copies * 4.99).toFixed(2)}</span>
                            </div>
                        )}
                    </div>
                </div>
            </div>

            {book.classBook.bookTemplate.isBeeTemplate ?
                <div className={sharedStyles.alertInfo}>Sorry, this book is not available for purchase at this time</div>

                :
                <div className={cx(styles.orderBook, { 'card-mobile': user })}>
                    <div className={styles.orderBookLeft}>
                        <div className={styles.desktopTotals}>
                            <p className='lead heavy u-m-base-2'>Order summary</p>
                            {totals && <Totals book={book} price={price} styles={styles} totals={totals} />}

                            {book.classBook.activeWriteToRaisePromotion && (
                                <>
                                    <p className='heavy u-m-base-2'>About</p>
                                    <p className='small'>This class has published a book with Brian Blessed OBE as part of BoomWriter’s Write To Raise promotion.</p>
                                    <p className='small'>For every copy of this bool sold before 31st March 2023, the school will receive £4.99. All orders will be collated, printed and shipped to the school starting 1st April 2023 for collection.</p>
                                </>
                            )}
                        </div>
                    </div>
                    {totals && (
                        <div className={styles.orderBookRight}>
                            {book.classBook.activeWriteToRaisePromotion ? (
                                <form onSubmit={handleSubmitWtr(onSubmitWtr)}>
                                    <p className='lead heavy u-m-base-2'>Your details</p>
                                    <div className='u-m-base-3'>
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerName' className='basic-form__text-label'>Your name</label>
                                            <input
                                                name='buyerName'
                                                defaultValue={user && `${user.firstName} ${user.lastName}`}
                                                placeholder={t('ph_buyer_name')}
                                                className='basic-form__text-box'
                                                ref={registerWtr}
                                                type='text'
                                            />
                                        </div>
                                        {errorsWtr.buyerName && <p className='basic-form__hint'>{errorsWtr.buyerName.message}</p>}
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerEmail' className='basic-form__text-label'>{t('email')}</label>
                                            <input
                                                name='buyerEmail'
                                                defaultValue={user && user.email}
                                                placeholder={t('ph_buyer_email')}
                                                className='basic-form__text-box'
                                                ref={registerWtr}
                                                type='email'
                                            />
                                        </div>
                                        {errorsWtr.buyerEmail && <p className='basic-form__hint'>{errorsWtr.buyerEmail.message}</p>}
                                    </div>
                                    <div className={sharedStyles.bookWtrSent}>
                                        <p className='heavy lead'>Your books are sent to your school.</p>
                                        <p className='u-m-base-2'>Your order will be included in the pack sent the school. Books will be delevered to the school at the end of the Write to Raise project</p>
                                        <p className='heavy lead u-m-base-0'>{book?.student?.school?.name}</p>
                                    </div>
                                    <div className={styles.mobileTotals}>
                                        {totals && <Totals book={book} price={price} styles={styles} totals={totals} />}
                                    </div>
                                    <div className='u-m-base-3'>
                                        <p className='lead heavy'>{t('payment_details')}</p>
                                        <CardElement
                                            options={{
                                                hidePostalCode: true,
                                                style: {
                                                    base: {
                                                        color: '#4A4A4A',
                                                        fontSize: '16px'
                                                    }
                                                }
                                            }}
                                        />
                                        <div className={styles.cardImages}>
                                            <ImageFadeIn className={styles.cardImagesImg} src={visaLogo} alt='' />
                                            <ImageFadeIn className={styles.cardImagesImg} src={mcLogo} alt='' />
                                            <ImageFadeIn className={styles.cardImagesImg} src={aeLogo} alt='' />
                                            <ImageFadeIn className={cx(styles.cardImagesImg, styles.cardImagesImgStripe)} src={stripeLogo} alt='' />
                                        </div>
                                    </div>
                                    <div className='basic-form__group basic-form__group--check basic-form__group--check--with-link'>
                                        <input
                                            id='terms'
                                            name='terms'
                                            className='basic-form__check-box'
                                            type='checkbox'
                                            value={termsAccepted}
                                            onChange={() => changeTermsAccepted(false)}
                                        />
                                        <Trans i18nKey='accept_terms_conditions_check'>
                                            <label htmlFor='terms' className='basic-form__check-label'>Accept</label> <span onClick={() => { changeTermsAccepted(true) }}>Terms & Delivery Conditions</span>
                                        </Trans>
                                    </div>
                                    <Button disabled={!(termsAccepted && stripe && elements)} type='submit'>{t('purchase')}</Button>
                                </form>
                            ) :
                                <form onSubmit={handleSubmit(onSubmit)} className='u-m-base-3'>
                                    <p className='lead heavy u-m-base-2'>Your details</p>
                                    <div className='u-m-base-3'>
                                        {user && (
                                            <div className='basic-form__group basic-form__group--check'>
                                                <input
                                                    id='deliverToUser'
                                                    name='deliverToUser'
                                                    className='basic-form__check-box'
                                                    type='checkbox'
                                                    defaultChecked={deliverToUser}
                                                    value={deliverToUser}
                                                    onChange={() => changeDeliverToUser(!deliverToUser)}
                                                />
                                                <label htmlFor='deliverToUser' className='basic-form__check-label'>{t('deliver_to_me')}</label>
                                            </div>
                                        )}
                                        <div className={cx({ 'basic-form__group': !deliverToUser })}>
                                            {!deliverToUser && <label htmlFor='buyerName' className='basic-form__text-label'>{t('deliver_to')}</label>}
                                            <input
                                                name='buyerName'
                                                defaultValue={user && `${user.firstName} ${user.lastName}`}
                                                placeholder={t('ph_buyer_name')}
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                                hidden={deliverToUser}
                                            />
                                        </div>
                                        {errors.buyerName && <p className='basic-form__hint'>{errors.buyerName.message}</p>}
                                        <div className={cx({ 'basic-form__group': !user })}>
                                            {!user && <label htmlFor='buyerEmail' className='basic-form__text-label'>{t('email')}</label>}
                                            <input
                                                name='buyerEmail'
                                                defaultValue={user && user.email}
                                                placeholder={t('ph_buyer_email')}
                                                className='basic-form__text-box'
                                                hidden={user}
                                                ref={register}
                                                type='email'
                                            />
                                        </div>
                                        {errors.buyerEmail && <p className='basic-form__hint'>{errors.buyerEmail.message}</p>}

                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerPhone' className='basic-form__text-label'>Telephone/Mobile</label>
                                            <input
                                                name='buyerPhone'
                                                placeholder='6754379899'
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                            />
                                        </div>
                                        {errors.buyerPhone && <p className='basic-form__hint'>{errors.buyerPhone.message}</p>}
                                    </div>
                                    <p className='lead heavy u-m-base-2'>Delivery Address</p>
                                    <div className='u-m-base-3'>
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerAddress1' className='basic-form__text-label'>{t('address_1')}</label>
                                            <input
                                                name='buyerAddress1'
                                                defaultValue={user && user.street}
                                                placeholder={t('ph_buyer_address_1')}
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                            />
                                        </div>
                                        {errors.buyerAddress1 && <p className='basic-form__hint'>{errors.buyerAddress1.message}</p>}
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerAddress2' className='basic-form__text-label'>{t('address_2')}</label>
                                            <input
                                                name='buyerAddress2'
                                                placeholder={t('ph_buyer_address_2')}
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                            />
                                        </div>
                                        {errors.buyerAddress2 && <p className='basic-form__hint'>{errors.buyerAddress2.message}</p>}
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerCity' className='basic-form__text-label'>{t('city')}</label>
                                            <input
                                                name='buyerCity'
                                                defaultValue={user && user.city}
                                                placeholder={t('ph_buyer_city')}
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                            />
                                        </div>
                                        {errors.buyerCity && <p className='basic-form__hint'>{errors.buyerCity.message}</p>}
                                        <div className='basic-form__group'>
                                            <label htmlFor='buyerPostcode' className='basic-form__text-label'>{book.classBook.bookTemplate.isBeeTemplate ? 'PIN' : t('postcode')}</label>
                                            <input
                                                name='buyerPostcode'
                                                defaultValue={user && user.postcode}
                                                placeholder={book.classBook.bookTemplate.isBeeTemplate ? 'PIN' : t('ph_buyer_postcode')}
                                                className='basic-form__text-box'
                                                ref={register}
                                                type='text'
                                            />
                                        </div>
                                        {errors.buyerPostcode && <p className='basic-form__hint'>{errors.buyerPostcode.message}</p>}
                                    </div>
                                    <div className={styles.mobileTotals}>
                                        {totals && <Totals book={book} price={price} styles={styles} totals={totals} />}
                                    </div>
                                    <div className='u-m-base-3'>
                                        <h4>{t('payment_details')}</h4>
                                        <CardElement
                                            options={{
                                                hidePostalCode: true,
                                                style: {
                                                    base: {
                                                        color: '#4A4A4A',
                                                        fontSize: '16px'
                                                    }
                                                }
                                            }}
                                        />
                                        <div className={styles.cardImages}>
                                            <ImageFadeIn className={styles.cardImagesImg} src={visaLogo} alt='' />
                                            <ImageFadeIn className={styles.cardImagesImg} src={mcLogo} alt='' />
                                            <ImageFadeIn className={styles.cardImagesImg} src={aeLogo} alt='' />
                                            <ImageFadeIn className={cx(styles.cardImagesImg, styles.cardImagesImgStripe)} src={stripeLogo} alt='' />
                                        </div>
                                    </div>
                                    <div className='basic-form__group basic-form__group--check basic-form__group--check--with-link'>
                                        <input
                                            id='terms'
                                            name='terms'
                                            className='basic-form__check-box'
                                            type='checkbox'
                                            value={termsAccepted}
                                            onChange={() => changeTermsAccepted(false)}
                                        />
                                        <Trans i18nKey='accept_terms_conditions_check'>
                                            <label htmlFor='terms' className='basic-form__check-label'>Accept</label> <span onClick={() => { changeTermsAccepted(true) }}>Terms & Delivery Conditions</span>
                                        </Trans>
                                    </div>
                                    <Button disabled={!(termsAccepted && stripe && elements)} type='submit'>{t('purchase')}</Button>
                                </form>
                            }
                        </div>
                    )}


                    {
                        processing && (
                            <div className={styles.processing}>
                                <Loader className={styles.processingLoader} />
                                <p className={styles.processingText}>{t('processing_payment')}</p>
                            </div>
                        )
                    }
                    {
                        showTermsModal && (
                            <Modal closeModal={() => setShowTermsModal(false)}>
                                <TermsModal closeModal={() => setShowTermsModal(false)} />
                            </Modal>
                        )
                    }
                    {
                        showErrorModal && (
                            <Modal closeModal={() => setShowErrorModal(false)}>
                                <ErrorModal closeModal={() => setShowErrorModal(false)} message={showErrorModal} />
                            </Modal>
                        )
                    }
                </div>
            }
        </>
    )
}

export default OrderBook;
