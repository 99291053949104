import React, {useState, useEffect} from 'react';
import BarcodeScannerComponent from "react-webcam-barcode-scanner";
import cx from 'classnames';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';

import beep from "../../../../../sounds/beep.mp3"
import BookQuery from './AddBookQuery';
import styles from './addBook.module.scss';

const AddBookIsbn = ({close, studentId, hide}) => {

    let audio = new Audio(beep)

    const [bookCode, setBookCode] = useState("");
    const [scannedCode, setScannedCode] = useState("");
    const [showSearch, setShowSearch] = useState({
        show: false,
        code: null
    })
    const handleSearch = (e) => {
        e.preventDefault();
        setShowSearch({
            show: true,
            code: bookCode
        });
    }
    const { t } = useTranslation();

    useEffect(() => {
        if (scannedCode !== "") {
            //audio.play();
            const playedPromise = audio.play();
            if (playedPromise) {
                playedPromise.then(_ => {
                    // Autoplay started!
                }).catch(error => {
                    console.log(error);
                });
            }
        }
    }, [audio, scannedCode])

    return (
        <>
        <h1 onClick={() => hide()} className={styles.newBookTitle}><Icon.ArrowLeft size="40" className={styles.newBookTitleIcon} />{t('scan_isbn')}</h1>
        <div className={cx(styles.bookSearchPanel, styles.bookSearchPanelISBN)}>
            <Icon.XCircle className={cx(styles.closeSearch, styles.closeSearchISBN)} onClick={() => hide()}/>
            {!showSearch.show ?
                <> 
                <form className={styles.searchForm} onSubmit={(e) => {handleSearch(e)}} >
                    <input type="text" placeholder={t('enter_isbn')} value={bookCode} onChange={(e) => setBookCode(e.currentTarget.value)} className={cx(styles.searchInput)} />
                    <button type="submit" className={cx(styles.searchBtn, styles.searchBtnISBN, bookCode !== "" && styles.searchBtnActive)} disabled={bookCode === "" && true}>{t('button.search')}</button>
                </form>
                <div className={styles.scannerHolder}>
                    <BarcodeScannerComponent
                        width={350}
                        height={'auto'}
                        onUpdate={(err, result) => {
                            if (result) {
                                if (result !== bookCode) {
                                    setBookCode(result.text);
                                    setScannedCode(result.text);
                                }
                            }
                        }}
                    />
                </div>
                <ul className={cx(styles.help, "u-m-top-20")}>
                    <li>{t('use_device_camera')}<span className={styles.helpSmall}>{t('try_hold_flat')}</span></li>
                    <li className="u-m-top-10">{t('once_scanned')}</li>
                </ul>
                </>
                :
                    <div className={styles.bookFind}>
                        <BookQuery code={showSearch.code} studentId={studentId} close={close} />
                        <p className={styles.searchAgain} onClick={() => {setShowSearch({show: false, code: null});setBookCode("");}}>{t('try_again')}</p>
                    </div>
            }
        </div>
        </>
    )
}

export default AddBookIsbn;