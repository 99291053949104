import React, { useEffect, Fragment, useState, useContext } from 'react';
import { PlusCircle } from 'react-feather';
import _ from 'lodash';
import moment from 'moment';
import { Link } from '@reach/router';
import cx from 'classnames';
import * as Icon from 'react-feather';
import { useMutation } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { READING_BOOK_STATUS } from '@axeedge/go-shared-utils';
import { DELETE_READING_BOOK_MUTATION } from '../../../../services/readingBook/graphql';
import { CURRENT_PARENT_QUERY } from '../../../../scenes/Home/services/graphql';
import { ASSIGN_READING_BOOK } from './services/graphql';
import PageHeader from '../../../../components/PageHeader';
import { Dropdown, FormErrors } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../../../services/auth/AuthProvider';
import styles from '../../Child.module.scss';
import logStyles from './components/addLog.module.scss';
import Button from '../../../../components/ButtonLink/button';
import AddBook from './components/AddBook';
import styles2 from './components/addBook.module.scss';
import RecentWords from './components/RecentWords';
import LogNew from './components/LogNew';
import TypeLabel from '../../../../components/BookTypeLabel';
import BookTile from '../../../../components/BookTile';
import starRating from '../../../../images/star-filled.svg';
import { READING_BOOK_TYPE } from '../../../../services/constants'
import GroupBookContent from './components/GroupReadingBook'

const ReadingBooksContent = ({ id, students }) => {

    const { t } = useTranslation();
    const [child, setChild] = useState(students.find(c => c.id === id));
    const { currentUser } = useContext(AuthContext);
    useEffect(() => {
        if (child !== students.find(c => c.id === id)) {
            setChild(students.find(c => c.id === id));
        }
    }, [students, child, id]);


    const currentBooks = _.filter(_.sortBy([...child.student.readingBooks, ...child.student.groupReadingBooks], 'book.title', 'asc'), book => {
        return book.status !== READING_BOOK_STATUS.complete
    });
    const previousBooks = _.filter(_.orderBy([...child.student.readingBooks, ...child.student.groupReadingBooks], 'completedAt', 'desc'), book => {
        return book.status === READING_BOOK_STATUS.complete
    });

    const sClass = child.student.classes[0];
    const teacher = sClass && sClass.teachers[0];
    const [showAddBook, setShowAddBook] = useState(false);
    const [selectedBook, setSelectedBook] = useState(null)
    const [selectedGroupBook, setSelectedGroupBook] = useState(null)
    const [showSuccess, setShowSuccess] = useState(false);
    const [showComplete, setShowComplete] = useState(false);
    const [filter, setFilter] = useState("ACTIVE");

    useEffect(() => {
        if (selectedBook || selectedGroupBook) {
            document.body.classList.add('preventScroll');
        } else {
            document.body.classList.remove('preventScroll');
        }
    }, [selectedBook, selectedGroupBook])

    /*const displayLogAuthor = author => {
        switch(author.role) {
            case 'Parent':
                return author.displayName
            case 'Teacher':
                return 'You'
            case 'Student':
                return author.displayName //TODO: avatar
            default:
              return '-'
          } 
    }*/

    const handleOpen = (book) => {
        if (book.students) {
            setSelectedGroupBook(book)
        } else {
            setSelectedBook(book.id)
        }
        // setSelectedBook(book.id)
    }

    const Log = ({ log }) => {
        var date = moment(log.readingDate);
        return (
            <li className={styles.logListItem}>
                <div className={styles.logListItemHeader}>
                    <div className={styles.logListItemHeaderMain}>
                        <span className={styles.logListItemHeaderDate}>{date.format("DD MMM YY")}</span>
                        <span className={styles.logListItemHeaderPages}><Icon.FileText size="20" className='u-text-muted u-mr-2' /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}</span>
                    </div>
                    <div>{/*displayLogAuthor(log.author)*/}{log.author.displayName}</div>
                </div>
                {log.entry !== "" &&
                    <div className={styles.logListSection}>
                        <p>{log.entry}</p>
                    </div>
                }
                {log.problemWords.length > 0 && (
                    <div className={styles.logListSection}>
                        {log.problemWords.map((w, i) => <span key={`word-${i}`} className={cx('u-m-right-5', styles.wordLabel)}>{w.word}</span>)}
                    </div>
                )}

            </li>
        )
    }

    const BookContent = () => {
        const [deleteFormErrors, setDeleteFormErrors] = useState([]);

        const [bookState] = useState(child.student.readingBooks.find(c => c.id === selectedBook));
        const [actionFilter, setActionFilter] = useState(bookState.status === READING_BOOK_STATUS.complete ? "HISTORY" : "LOG");
        const [recentLogs] = useState(_.orderBy(bookState.logs, ['readingDate', 'date'], ['desc', 'desc']));
        const [assignBookErrors, setAssignBookErrors] = useState(false);

        const review = _.filter(bookState.book.reviews, review => {
            return review.author && review.author.id === child.student.id
        })[0];

        let reviewStars;
        if (review) {
            reviewStars = Array.from({ length: review.reviewRate }, (_, i) => i)
        }

        let startDate;
        let endDate;
        if (recentLogs.length > 0) {
            startDate = moment(recentLogs[recentLogs.length - 1].readingDate);
            endDate = moment(recentLogs[0].readingDate);
        }
        const [deleteBook] = useMutation(DELETE_READING_BOOK_MUTATION, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: CURRENT_PARENT_QUERY }],
            variables: {
                readingBookId: bookState.book.id,
                studentId: child.student.id
            },
            onCompleted: (data) => {
                if (data.deleteReadingBookFromStudent.errors && data.deleteReadingBookFromStudent.errors.length > 0) {
                    setDeleteFormErrors(data.deleteReadingBookFromStudent.errors);
                    return
                }
                if (data.deleteReadingBookFromStudent && data.deleteReadingBookFromStudent.deleted) {
                    setSelectedBook(null);
                }
            },
        })

        const onDeleteBook = () => {
            if (window.confirm(t('are_you_sure_delete_book'))) {
                deleteBook();
            }
        }


        const [assignReadingBook, {loading: assigningBook}] = useMutation(ASSIGN_READING_BOOK, {
            onCompleted: (data) => {
                if (data.assignReadingBookToStudent.errors && data.assignReadingBookToStudent.errors.length > 0) {
                    setAssignBookErrors(true);
                    return
                }
                if (data.assignReadingBookToStudent && data.assignReadingBookToStudent.studentReadingBook && data.assignReadingBookToStudent.studentReadingBook.id) {
                    setSelectedBook(data.assignReadingBookToStudent.studentReadingBook.id);
                    setAssignBookErrors(false);
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [{ query: CURRENT_PARENT_QUERY }],
        });

        return (
            <>
                <h1 className={logStyles.bookHeading}>{bookState.book.title}</h1>
                <TypeLabel type={bookState.bookType} />
                <div className={cx(logStyles.bookFilters, logStyles.bookFiltersLog, "u-m-top-20 u-m-base-20")}>
                    {bookState.status !== READING_BOOK_STATUS.complete && <button className={logStyles.filter} onClick={() => setActionFilter('LOG')}><span className={cx(logStyles.filterInner, actionFilter === 'LOG' ? logStyles.filterInnerActive : null)}>{t('log_reading')}</span></button>}
                    <button className={logStyles.filter} onClick={() => setActionFilter('HISTORY')}><span className={cx(logStyles.filterInner, actionFilter === 'HISTORY' ? logStyles.filterInnerActive : null)}>{t('history')}</span></button>
                    <button className={logStyles.filter} onClick={() => setActionFilter('BOOKINFO')}><span className={cx(logStyles.filterInner, actionFilter === 'BOOKINFO' ? logStyles.filterInnerActive : null)}>{t('book_info')}</span></button>
                    {( recentLogs.filter(log => log.author && log.author.role === "Teacher").length === 0 &&  bookState.addedBy && bookState.addedBy.id === currentUser.id) && (
                        <Dropdown classNames={logStyles.bookFiltersLogAction}>
                            <li><button onClick={() => onDeleteBook()}>{t('button.delete')}</button></li>
                        </Dropdown>
                    )}
                </div>
                {deleteFormErrors.length !== 0 && <FormErrors errors={deleteFormErrors} />}

                {actionFilter === 'LOG' &&
                    <>
                        {showSuccess ?
                            <>
                                <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                <p className="u-m-base-20">{t('your_log_for_has_been_added', { studentName: child.student.firstName })}</p>
                                <Button event={() => setShowSuccess(false)}>{t('log_more')}</Button>
                            </> :
                            <LogNew book={bookState} student={child.student} recentLog={recentLogs[0] && recentLogs[0].pageEnd} close={setSelectedBook} success={setShowSuccess} complete={setShowComplete} />
                        }
                    </>
                }
                {actionFilter === 'HISTORY' &&
                    <>
                        {showComplete &&
                            <p className="u-bold u-m-base-20">{t('book_will_stay_seven_days')}</p>
                        }
                        {bookState.status === READING_BOOK_STATUS.complete && startDate && endDate && <>
                            <p className="u-m-base-5">{t('started_colon')} {startDate.format("DD MMM YY")}</p>
                            <p className="u-m-base-2">
                                {t('completed_colon')} {endDate.format("DD MMM YY")}

                                {bookState.bookType !== READING_BOOK_TYPE.group && (
                                    <>
                                        <button className={cx(styles.btnReread, "u-m-left-2 btn-reset")} onClick={() => assignReadingBook({
                                            variables: {
                                                bookId: bookState.book.id,
                                                studentId: child.student.id,
                                                bookType: bookState.bookType,
                                                isReread: true,
                                                hideComments: bookState.hideComments !== null ? bookState.hideComments : false
                                            }
                                        })}>{assigningBook ? t('saving') : <><Icon.PlusCircle size="20" /> {t('read_again')}</>}</button> 
                                    </>
                                )}
                             {assignBookErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong')}</p>}
                            </p>
                        </>
                        }
                        {review &&
                            <>
                                <p className={styles.reviewHeader}>{t('review_by_name', { studentName: child.student.firstName })}</p>
                                <div className={styles.reviewBlock}>
                                    <ul className={styles.reviewImg}>
                                        {reviewStars.map(star => <li className='u-m-right-1' key={`img-${star}`}><img src={starRating} alt='' /></li>)}
                                    </ul>
                                    <div className={styles.reviewBody}>
                                        <p>{review.reviewText}</p>
                                    </div>
                                </div>
                            </>
                        }
                        <ul>
                            {_.map(recentLogs, log => {
                                return <Log key={`activeBook${log.id}`} log={log} />
                            })}
                        </ul>
                    </>
                }
                {actionFilter === 'BOOKINFO' &&
                    <>
                        <h2 className={cx(styles.bookHeading, "u-m-base-5")}>{bookState.book.title}</h2>
                        {bookState.book.author && <p>{bookState.book.author}</p>}
                        {bookState.book.isbn && <p className="u-m-top-20">ISBN: {bookState.book.isbn}</p>}
                        {bookState.book.isbn13 && <p>ISBN13: {bookState.book.isbn13}</p>}
                        {bookState.book.publisher && <p className="u-m-top-20">{bookState.book.publisher.name}</p>}
                    </>
                }
            </>
        )
    }

    return (
        <Fragment>
            <PageHeader
                title={`${child.student.firstName} ${child.student.lastName}`}
                subTitle={sClass && teacher && `${sClass.name}: ${teacher.displayName}`}
                icon={``}
                breadCrumb={false}
                avatar={child.student.boomerAvatarUrl}
                updatedAt={child.student.updatedAt}
            />
            {showAddBook ?
                <AddBook close={() => setShowAddBook(false)} studentId={child.student.id} /> :
                <>
                    <Link to={`/child/${id}`} className={styles.headerNavLink}>
                        <h1 className={styles2.newBookTitle}><Icon.ArrowLeft size="40" className={styles2.newBookTitleIcon} />{t('reading_books')}</h1>
                    </Link>
                    <div className={styles.childContainer}>
                        <div className={cx(styles.childContainerLeft, styles.childContainerLeftExt)}>
                            <div className={cx(logStyles.bookFilters, logStyles.bookFiltersLog, "u-m-base-20")}>
                                <button className={logStyles.filter} onClick={() => setFilter('ACTIVE')}><span className={cx(logStyles.filterInner, filter === 'ACTIVE' ? logStyles.filterInnerActive : null)}>{t('current_books')}</span></button>
                                <button className={logStyles.filter} onClick={() => setFilter('COMPLETE')}><span className={cx(logStyles.filterInner, filter === 'COMPLETE' ? logStyles.filterInnerActive : null)}>{t('completed_books')}</span></button>
                            </div>
                            {filter === 'ACTIVE' ?
                                <>
                                    {
                                        currentBooks.length === 0 ? <p className={styles.noResults}>{t('no_reading_books')}</p> :
                                            <>
                                                {currentBooks.filter(c => c.bookType === 1).map(book => {
                                                    return (
                                                        <BookTile key={book.id} studentId={child.student.id} readingBook={book} book={book.book} event={() => handleOpen(book)} />
                                                    )
                                                })}
                                                {currentBooks.filter(c => c.bookType !== 1).map(book => {
                                                    return (
                                                        <BookTile key={book.id} studentId={child.student.id} readingBook={book} book={book.book} event={() => handleOpen(book)} />
                                                    )
                                                })}
                                            </>
                                    }
                                </>
                                :
                                <>
                                    {
                                        previousBooks.length === 0 ? <p className={styles.noResults}>{t('no_completed_reading_books')}</p> :
                                            <>
                                                {previousBooks.map(book => {
                                                    return (
                                                        <BookTile key={book.id} studentId={child.student.id} readingBook={book} book={book.book} event={() => handleOpen(book)} />
                                                    )
                                                })}
                                            </>
                                    }
                                </>
                            }
                        </div>
                        <div className={styles.childContainerRight}>
                            <Button event={() => setShowAddBook(true)}><PlusCircle /> {t('new_book')}</Button>
                            <p className='u-m-top-2'>{t('latest_problem_words')}</p>
                            <ul className={styles.recentWordList}>
                                <RecentWords books={child.student.readingBooks} styles={styles} />
                            </ul>
                            {child.student.school.guideUrl &&
                                <a href={child.student.school.guideUrl} className={styles.readingGuideBtn} target="_blank" rel="noopener noreferrer">
                                    <Icon.Download color="#000000" className="u-m-right-5" />
                                    {t('view_reading_guide')}
                                </a>
                            }
                        </div>
                    </div>
                </>
            }
            <div className={cx(styles.bookBg, (selectedBook || selectedGroupBook) && styles.bookBgShow)} onClick={() => {setSelectedBook(null);setSelectedGroupBook(null)}}></div>
            <div className={cx(styles.bookModal, (selectedBook|| selectedGroupBook) && styles.bookModalOpen)}>
                {selectedBook && <BookContent />}
                {selectedGroupBook && <GroupBookContent book={selectedGroupBook} child={child} close={setSelectedGroupBook} />}
            </div>
            {(selectedBook || selectedGroupBook) && <Icon.XCircle onClick={() => { setSelectedBook(null); setSelectedGroupBook(null); setShowComplete(false); setShowSuccess(false) }} className={styles.closeBook} />}
        </Fragment>
    )
}

export default ReadingBooksContent;