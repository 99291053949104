import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import SearchResults from './AddBookResults';
import { BOOK_ISBN_SEARCH_QUERY } from '../services/graphql';

const BookQuery = ({code, studentId, close}) => {
    const { t } = useTranslation();
    const { data, error, loading } = useQuery(BOOK_ISBN_SEARCH_QUERY, {
        variables: {
            isbn: code.replace(/-/g, "")
        },
        fetchPolicy: 'network-only'
    });

    if (error) {
        return <p>{error && error.message && error.message}</p>
    }

    if (loading) {
        return <p>{t('loading')}...</p>
    }

    if (data) {
        return <SearchResults singleBook={data} code={code} studentId={studentId} close={close} />
    }
    
    return null
}

export default BookQuery;