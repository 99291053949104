import React, { useState, useContext } from 'react';
import { useLazyQuery, useMutation } from '@apollo/react-hooks';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';

import { ADD_CHILD_MUTATION, FIND_CHILD_QUERY } from './services/graphql';
import { AuthContext } from '../../services/auth/AuthProvider';

import styles from './AddChildForm.module.scss';

const AddChildForm = ({ title }) => {
    const [confirmModal, setConfirmModal] = useState(null);
    const [confirmMsg, setConfirmMsg] = useState(null);
    const [formErrors, setFormErrors] = useState([]);
    const { setCurrentUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const schema = Yup.object().shape({
        schoolCode: Yup.string()
            .required(t('req_enter_school_code')),
        studentCode: Yup.string()
            .required(t('req_enter_child_code'))
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const [findChild, {loading: searching}] = useLazyQuery(FIND_CHILD_QUERY, {
        fetchPolicy: 'network-only',
        onCompleted: ({ studentByCode }) => {
            if (studentByCode && studentByCode.id) {
                setConfirmModal(studentByCode);
            } else {
                setFormErrors([t('err_no_child')])
            }

        }
    })

    const [addChildToParent, { loading }] = useMutation(ADD_CHILD_MUTATION, {
        onCompleted: ({ addChildToParent }) => {
            if (addChildToParent.errors && addChildToParent.errors.length !== 0) {
                setFormErrors(addChildToParent.errors);
                return;
            } 
            if (addChildToParent.parent && addChildToParent.parent.id) {
                setCurrentUser(addChildToParent.parent);
                setConfirmMsg(`${confirmModal.firstName} ${confirmModal.lastName} ${t('added')}!`);
                setConfirmModal(null);
                reset({
                    schoolCode: '',
                    studentCode: ''
                })
                navigate('/');
            }
        }
    })

    const onSubmit = values => {
        const { schoolCode, studentCode } = values;
        setConfirmMsg(null);
        findChild({
            variables: {
                schoolCode,
                studentCode
            }
        });
    }

    const closeModal = () => {
        setConfirmModal(null);
        reset({
            schoolCode: '',
            studentCode: ''
        })
    }

    return (
        <div className={styles.panel}>
            {title && <h1 className={styles.panelTitle}>{title}</h1>}
            <p className={styles.panelSubTitle}>{t('connect_to_your_child')}:</p>
            <form onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='schoolCode'
                        placeholder={t('ph_school_code')}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.schoolCode && <p className='basic-form__hint'>{errors.schoolCode.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='studentCode'
                        placeholder={t('ph_child_code')}
                        className='basic-form__text-box'
                        ref={register}
                        type='text'
                    />
                </div>
                {errors.studentCode && <p className='basic-form__hint'>{errors.studentCode.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-justify-center'>
                    <Button type='submit' primary>{searching ? `${t('finding')}...` : t('connect')}</Button>
                </div>
            </form>
            {
                confirmMsg && <p className='basic-form__success'>{confirmMsg}</p>
            }
            {
                confirmModal && (
                    <Modal closeModal={() => closeModal()}>
                        <h1>{t('q_your_child')}</h1>
                        <p><strong>{confirmModal.firstName} {confirmModal.lastName}</strong></p>
                        <p>{confirmModal.school.name}</p>
                        <Button 
                            className='u-m-right-1 u-m-top-3'
                            onClick={() => {
                                addChildToParent({
                                    variables: {
                                        schoolCode: confirmModal.school.schoolCode,
                                        studentCode: confirmModal.loginCode
                                    }
                                })
                            }}
                        >{loading ? `${t('saving')}...` : t('button.yes')}</Button>
                        <Button outline onClick={() => closeModal()}>{t('button.no')}</Button>
                    </Modal>
                )
            }
        </div>
        
    )
}

export default AddChildForm;