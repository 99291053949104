// Queries and mutations for the Login module
import { gql } from 'apollo-boost';
import { PARENT_QUERY } from '../../../services/constants';

export const LOGIN = gql`
mutation parentLogIn($email: String!, $password: String!) {
    parentLogIn(email: $email, password: $password) {
        parent {
            id
        }
        token
        errors
    }
}
`;

export const LOGIN_FB = gql`
mutation parentLogInWithFb($facebookId: String!, $email: String!) {
    parentLogInWithFb(facebookId: $facebookId, email: $email) {
        parent {
            id
        }
        emailLinked
        token
        errors
    }
}
`; 

export const CONFIRM_NEW_EMAIL_MUTATION= gql`
mutation confirmNewEmail($confirmEmailToken: String!) {
    confirmNewEmail(confirmEmailToken: $confirmEmailToken) {
        parent {
            ${PARENT_QUERY}
        }
        errors
        token
    }
}
`;

export const UPDATE_PASSWORD_MUTATION = gql`
mutation updatePassword($token: String!, $password: String!, $passwordConfirmation: String!) {
    updatePassword(token: $token, password: $password, passwordConfirmation: $passwordConfirmation) {
        user {
            id
        }
        errors
        token
    }
}
`;

export const RESET_PASSWORD_MUTATION = gql`
mutation resetPassword($email: String!) {
    resetPassword(email: $email) {
        reset
        errors
    }
}
`;