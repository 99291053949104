import React, { Fragment, useContext } from 'react';
import { Link } from '@reach/router';
import * as Icon from 'react-feather'
import { useTranslation } from 'react-i18next';


import { AuthContext } from '../../services/auth/AuthProvider';
import AddChildForm from '../../components/AddChildForm';
import ButtonLink from '../../components/ButtonLink';
import BoomWriter from '../../images/boomwriter.png'
import Boomer from '../../images/loader-boomer.png'
import FBWelcomeModal from '../../components/FBWelcomeModal';
import styles from './Dashboard.module.scss';
import cx from 'classnames';

const Dashboard = () => {
    const { t } = useTranslation();
    const { currentUser, emailLinked, setEmailLinked } = useContext(AuthContext);
    const closeModal = () => {
        setEmailLinked(false);
    }
    return (
        <>
            {!currentUser.hasSetPassword &&
                <Link to='/account' className={styles.callout}>{t('welcome_goapps_recovery')}<Icon.ArrowRight color="#417505" className={styles.calloutIcon} /></Link>
            }
            {emailLinked &&
                <FBWelcomeModal close={() => closeModal()} type={emailLinked} />}
            <div className={styles.dashContainer}>
            {(currentUser.children && currentUser.children.length !== 0) ?
                <>
                <div>
                    {
                        currentUser.children.filter(child => child.student.school).map(child => {
                            return (
                                <Link to={`child/${child.id}`} key={child.id} className={styles.child}>
                                <img src={child.student.boomerAvatarUrl ? `${child.student.boomerAvatarUrl}?v=${child.student.updatedAt.replace(/\s/g, '' )}` : Boomer} className={styles.childAvatar} />
                                    <p className={styles.childName}>{child.student.firstName} {child.student.lastName}</p>
                                </Link>
                            )
                        })
                    }
                    <ButtonLink outline to='/addChild'>{t('add_another_child')}</ButtonLink>
                    {
                        currentUser.children.filter(child => !child.student.school).map(child => {
                            return (
                                <div key={child.id} className={cx(styles.child, styles.childDisabled)}>
                                <img src={child.student.boomerAvatarUrl ? `${child.student.boomerAvatarUrl}?v=${child.student.updatedAt.replace(/\s/g, '' )}` : Boomer} className={styles.childAvatar} />
                                    <div>
                                        <p className={styles.childName}>{child.student.firstName} {child.student.lastName}</p>
                                        <p>{child.student.inactiveSchools && child.student.inactiveSchools.length > 0 && child.student.inactiveSchools.map(s => s.name).join(', ')}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                </div>
                </>
                :
                <AddChildForm title={t('welcome')} />
            }
            </div>
        </>
    )
}

export default Dashboard;
