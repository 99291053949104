import React from 'react';
import { Link } from '@reach/router';
import { Home } from 'react-feather';
import { useTranslation } from 'react-i18next';


import Dropdown from '../Dropdown';
import styles from './PageHeader.module.scss';
import Boomer from '../../images/loader-boomer.png'

const PageHeader = ({ title, icon: Icon, dropdownOpts, subTitle, breadCrumb = true, avatar = null, updatedAt=null }) => {
    const { t } = useTranslation();

    return(
        <div className={styles.pageHeader}>
            <div className={styles.pageHeaderContent}>
                {breadCrumb &&
                    <ul className={styles.pageHeaderContentBreadcrumbs}>
                        <li><Link to='/'><Home /> {t('back_home')}</Link></li>
                    </ul>
                }
                <div className={styles.pageHeaderContentMain}>
                        <img src={avatar ? `${avatar}?v=${updatedAt.replace(/\s/g, '' )}` : Boomer} className={styles.avatar} />
                    {Icon !== '' &&
                        <Icon className={styles.pageHeaderContentIcon} />
                    }
                    <div className={styles.pageHeaderContentRight}>
                        <h1 className={styles.pageHeaderContentTitle}> {title}</h1>
                        <p className={styles.pageHeaderContentSubTitle}>{subTitle}</p>
                    </div>
                    
                </div>
            </div>
            {
                dropdownOpts && (
                    <div>
                        <Dropdown dropdownOpts={dropdownOpts}></Dropdown>
                    </div>
                )
            }
         
        </div>
    )
}

export default PageHeader;
