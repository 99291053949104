import { APPS } from '@axeedge/go-shared-utils';

export const APP_NAME = APPS.go_parents_desktop;

export const BOOK_PRICE = 600;
export const BOOK_POSTAGE = 295;
export const INDIA_BOOK_POSTAGE = 10000;

export const PARENT_QUERY = `
id
avatarUrl
displayName
email
firstName
lastName
street
hasSetPassword
facebookId
pendingEmail
city
county
postcode
lastTermsVersionApproved
children {
    id
    student {
        id
        availableGems
        avatarJson
        boomerAvatarUrl
        updatedAt
        firstName
        lastName
        storeCode
        loginCode
        inactiveSchools {
            id
            name
        }
        school {
            id
            guideUrl
            name
            schoolCode
            allowParentsGroupLogs
        }
        classes {
            id
            name
            teachers {
                id
                displayName
            }
            books {
                id
                bookCode
                status
                title
                isSingleChapter
                chapters {
                    id
                    status
                }
                currentChapter
                bookTemplate {
                    id
                    name
                    coverUrl
                    isPromo
                }
                studentsClass {
                    id
                    school {
                        id
                        name
                    }
                }
                classPack {
                    id
                    isWritingActivity
                }
            }
            
        }
        readingBooks {
            addedBy {
                displayName
                id
            }
            book {
                id
                author
                coverUrl
                title
                isbn
                isbn13
                publisher {
                    id
                    name
                }
                reviews {
                    id
                    author {
                        id
                    }
                    reviewText
                    reviewRate
                    date
                }
            }
            bookType
            completedAt
            hideComments
            isReread
            id
            logs {
                id
                author {
                    id
                    firstName
                    lastName
                    displayName
                    role
                }
                problemWords {
                    id
                    word
                }
                isCompletion
                date
                entry
                pageEnd
                pageStart
                readingDate
            }
            noReview
            status
        }
        groupReadingBooks {
            id
            status
            createdAt
            addedBy {
                displayName
                id
            }
            book {
                id
                title
            }
            mostRecentLog {
                id
                date
            }
            completedAt
            hideComments
            students {
                id
            }
        }
    }
    
}
`;

export const UK_COUNTIES = [
    'Bedfordshire',
    'Buckinghamshire',
    'Cambridgeshire',
    'Cheshire',
    'Cleveland',
    'Cornwall',
    'Cumbria',
    'Derbyshire',
    'Devon',
    'Dorset',
    'Durham',
    'East Sussex',
    'Essex',
    'Gloucestershire',
    'Greater London',
    'Greater Manchester',
    'Hampshire',
    'Hertfordshire',
    'Kent',
    'Lancashire',
    'Leicestershire',
    'Lincolnshire',
    'Merseyside',
    'Norfolk',
    'North Yorkshire',
    'Northamptonshire',
    'Northumberland',
    'Nottinghamshire',
    'Oxfordshire',
    'Shropshire',
    'Somerset',
    'South Yorkshire',
    'Staffordshire',
    'Suffolk',
    'Surrey',
    'Tyne and Wear',
    'Warwickshire',
    'West Berkshire',
    'West Midlands',
    'West Sussex',
    'West Yorkshire',
    'Wiltshire',
    'Worcestershire',
    'Flintshire',
    'Glamorgan',
    'Merionethshire',
    'Monmouthshire',
    'Montgomeryshire',
    'Pembrokeshire',
    'Radnorshire',
    'Anglesey',
    'Breconshire',
    'Caernarvonshire',
    'Cardiganshire',
    'Carmarthenshire',
    'Denbighshire',
    'Aberdeen City',
    'Aberdeenshire',
    'Angus',
    'Argyll and Bute',
    'City of Edinburgh',
    'Clackmannanshire',
    'Dumfries and Galloway',
    'Dundee City',
    'East Ayrshire',
    'East Dunbartonshire',
    'East Lothian',
    'East Renfrewshire',
    'Eilean Siar',
    'Falkirk',
    'Fife',
    'Glasgow City',
    'Highland',
    'Inverclyde',
    'Midlothian',
    'Moray',
    'North Ayrshire',
    'North Lanarkshire',
    'Orkney Islands',
    'Perth and Kinross',
    'Renfrewshire',
    'Scottish Borders',
    'Shetland Islands',
    'South Ayrshire',
    'South Lanarkshire',
    'Stirling',
    'West Dunbartonshire',
    'West Lothian',
    'Antrim',
    'Armagh',
    'Down',
    'Fermanagh',
    'Derry and Londonderry',
    'Tyrone',
]

export const READING_BOOK_TYPE = {
    reading: 1,
    library: 2,
    home: 3,
    group: 4
  };

export const BOOK_TYPES = [
    {type: 1, label: 'Reading Book', extended_label: 'Class Reading Book'},
    {type: 2, label: 'Library Book', extended_label: 'Library Reading Book'},
    {type: 3, label: 'Home Book', extended_label: 'Home Reading Book'},
    {type: 4, label: 'Group Book', extended_label: 'Group Reading Book'},

]

export const FbAppId = () => {
    const hostname = window && window.location && window.location.hostname;
  
        if (hostname !== "localhost" && !hostname.includes("staging")) {
          return "350583132881265";
        }
        if (hostname === "localhost") {
          return "2734367840140180";
        }
        return "853592078717819";
     
  };