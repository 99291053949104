import React, { useState, useRef, useEffect } from 'react';
import { useMutation } from '@apollo/react-hooks';
import * as Icon from 'react-feather';
import cx from 'classnames';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import { Button } from '@axeedge/go-teacher-components';
import styles from './addLog.module.scss';
import { ADD_GROUP_READING_LOG, GET_GROUP_BOOK_QUERY } from '../services/graphql';
import { CURRENT_PARENT_QUERY } from '../../../../Home/services/graphql';
const GroupLogNew = ({book, child, setShowSuccess, close}) => {

    Date.prototype.toDateInputValue = (function() {
        var local = new Date(this);
        local.setMinutes(this.getMinutes() - this.getTimezoneOffset());
        return local.toJSON().slice(0,10);
    });

    const [record, setRecord] = useState({
        date: new Date().toDateInputValue(),
        time: new Date().toTimeString().slice(0,5),
        pageStart: book?.mostRecentLog?.pageEnd || 1,
        pageEnd: '',
        comment: "",
        problemWords: [],
        completed: false,
        showError: false,
    })

    const [justNow, setJustNow] = useState(true);

    useEffect(() => {
        if (justNow) {
            setRecord({
                ...record,
                date: new Date().toDateInputValue(),
                time: new Date().toTimeString().slice(0,5),
            })
        }
    }, [justNow])


    const { t } = useTranslation();

    const tagInput = useRef(null);

    const addWordTags = e => {
        const val = e.target.value;
        if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
            e.preventDefault();
            if (record.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                return;
            }
            setRecord({
                ...record,
                problemWords: [...record.problemWords, val]
            });
            tagInput.current.value = null;
        }
    };

    const removeWordTag = i => {
        const newWords = [ ...record.problemWords ];
        newWords.splice(i, 1);
        setRecord({
            ...record,
            problemWords: newWords
        });
    };

    const [addLog, {loading}] = useMutation(ADD_GROUP_READING_LOG, {
        awaitRefetchQueries: true,
        onCompleted: () => {
            setShowSuccess(true)
        },
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            },
            {
                query: GET_GROUP_BOOK_QUERY,
                variables: {
                    id: book.id
                }
            }
        ]
    });

    const handleAdd = () => {
        if (record.pageEnd === '' || record.pageStart === '' || record.pageEnd < 0 || record.pageStart < 0|| isNaN(record.pageStart) || isNaN(record.pageEnd) || record.pageStart % 1 !== 0 || record.pageEnd % 1 !== 0 || record.pageEnd > 100000 || record.pageStart > 100000) {
            setRecord({...record, showError: true})
        } else {
            addLog({
                variables: {
                    groupReadingBookId: book.id, 
                    pageStart: parseInt(record.pageStart),
                    pageEnd: parseInt(record.pageEnd),
                    entry: record.comment,
                    problemWords: record.problemWords,
                    finished: false,
                    readingDate: `${record.date} ${record.time}:00`,
                    studentIds: [child.student.id]
                }
            })
        }
    }

    return (
        <>
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20')}>Reading Date &amp; Time</h2>
            <div className="u-display-flex">
                {justNow ? 
                    <p onClick={() => setJustNow(false)} className={styles.logReadingField}>Just Now <Icon.Edit className={cx(styles.justNow, "u-m-left-1")}/></p> :
                    <>
                        <div className={cx(styles.logReadingField, 'u-m-right-2')}>
                            <Icon.Calendar className="u-m-right-10" />
                            <input placeholder="5/10/25" type="date" onChange={(e) => setRecord({...record, date: e.currentTarget.value})} value={record.date} className={styles.styledInput} />
                        </div>
                        <div className={cx(styles.logReadingField, 'u-m-right-40')}>
                            <Icon.Clock className="u-m-right-10" />
                            <input placeholder="5/10/25" type="time" onChange={(e) => setRecord({...record, time: e.currentTarget.value})} value={record.time} className={styles.styledInput} />
                        </div>
                        <Icon.XCircle className={cx(styles.justNow, "u-m-left-2")} onClick={() => setJustNow(true)} />
                    </>
                }
            </div>
            <div className="u-display-flex u-m-top-20">
                <div className={styles.logReadingField}>
                    <Icon.FileText className="u-m-right-10" />
                    <input type="number" className={cx(styles.pageNum,'u-m-right-10', styles.styledInput)} placeholder="1" onChange={(e) => setRecord({...record, pageStart: e.currentTarget.value, showError: false})} value={record.pageStart} />
                    <span className="u-m-right-10">to</span>
                    {!record.completed ?
                        <input type="number" inputMode="numeric" className={cx(styles.pageNum, styles.styledInput)} onChange={(e) => setRecord({...record, pageEnd: e.currentTarget.value, showError: false})} value={record.pageEnd} step="1" min="1" max="100000"/>
                        :
                        <p>The End</p>
                    }
                </div>
            </div>
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-20')}>{t('comments')}</h2>
            <textarea className={cx(styles.logReadingComment, styles.styledInput, 'u-m-top-5')} rows="3" placeholder={t('write_your_comment')} onChange={(e) => setRecord({...record, comment: e.currentTarget.value})}></textarea>
            <h2 className={cx(styles.logReadingSubHeader, 'u-m-top-42')}>{t('any_problem_words')}</h2>
            <p>{t('type_problem_words')}</p>
            <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                <input
                    type="text"
                    placeholder={t('separate_problem_words')}
                    className='basic-form__text-box'
                    onKeyPress={(e) => addWordTags(e)}
                    ref={tagInput}
                />
            </div>
            <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                {_.map(record.problemWords, (word, i) => {
                    return (
                        <span className={styles.logReadingProbWord} key={`probWord${i}`}>
                            {word}
                            <button className={styles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                        </span>
                    )
                })}
            </div>
            <div className="u-m-top-20">
                {record.showError && <p className="u-m-base-5 error">{t('ensure_page_numbers')}</p>}
                <Button className="u-m-right-10" onClick={() => handleAdd()}  disabledStyle={(record.pageEnd === '' && record.completed) ? false : record.pageEnd === '' ? true : false}>{loading ? t('adding') : t('add_record')}</Button>
                <Button outline onClick={() => close()}>{t('button.cancel')}</Button>
            </div>
        </>
    )
}

export default GroupLogNew