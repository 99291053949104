import { gql } from 'apollo-boost';

export const BOOK_BY_CODE_QUERY = gql`
query bookByCode($code: String!) {
    bookByCode(code: $code) {
        classBook {
            id
            bookCode
            title
            isSingleChapter
            activeWriteToRaisePromotion
            bookTemplate {
                id
                coverUrl
                name
                isPromo
                isBeeTemplate
            }
        }
        student {
            id
            firstName
            lastName
            school {
                id
                name
            }
        }
    }
}
`;

export const STRIPE_DETAILS_QUERY = gql`
query stripeDetails {
    stripeDetails {
        default {
            id
            amount
            promoAmount
            currency
        }
        india {
            id
            amount
            promoAmount
            currency
        }        
        publicKey
    }
}
`;

export const CREATE_STRIPE_INTENT_MUTATION = gql`
mutation createStripePaymentIntent($amount: Int!, $bookCode: String, $numberOfCopies: Int) {
    createStripePaymentIntent(amount: $amount, bookCode: $bookCode, numberOfCopies: $numberOfCopies) {
        response
        errors
    }
}
`;

export const CREATE_ORDER_MUTATION = gql`
mutation createIndividualBookOrder($classBookId: ID!, $orderDetails: String, $name: String!, $email: String!, $shippingToStreet: String!, $shippingToCity: String!, $shippingToCounty: String, $shippingToPostcode: String!, $shippingToAddress2: String, $paymentIntentId: String, $contactPhone: String) {
    createIndividualBookOrder(classBookId: $classBookId, orderDetails: $orderDetails, name: $name, email: $email, shippingToStreet: $shippingToStreet, shippingToCity: $shippingToCity, shippingToCounty: $shippingToCounty, shippingToPostcode: $shippingToPostcode, shippingToAddress2: $shippingToAddress2, paymentIntentId: $paymentIntentId, contactPhone: $contactPhone) {
        printBookOrder {
            id
            orderNumber
            orderType
        }
        errors
    }
}
`;

export const CREATE_WTR_ORDER_MUTATION = gql`
mutation createWriteToRaiseIndividualPrintBookOrder($classBookId: ID!, $orderDetails: String, $name: String!, $email: String!, $paymentIntentId: String) {
    createWriteToRaiseIndividualPrintBookOrder(classBookId: $classBookId, orderDetails: $orderDetails, name: $name, email: $email, paymentIntentId: $paymentIntentId) {
        printBookOrder {
            id
            orderNumber
            orderType
        }
        errors
    }
}
`;