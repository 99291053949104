import React from 'react';
import styles from './bookTitle.module.scss';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';

import { READING_BOOK_STATUS } from '@axeedge/go-shared-utils';

import BookTypeLabel  from '../BookTypeLabel';

const BookTile = ({readingBook, book, event}) => {
    const { t } = useTranslation();
    return (
        <div className={styles.bookTile}>
            <button onClick={event} className={styles.bookTileInner}>
                <div>
                    <span className={styles.bookTitle}>{book.title}</span>
                    {book.author && <span className="u-m-top-5 u-bold">{book.author}</span>}
                    {book.publisher && <span className={styles.bookPublisher}>{book.publisher.name}</span>}
                    {readingBook && <div className={styles.meta}>
                            <BookTypeLabel type={readingBook.students ? 4 : readingBook.bookType}/>
                            {readingBook.status === READING_BOOK_STATUS.complete &&
                                <span className={styles.completeLabel}>
                                    <Icon.CheckCircle color="#6B9A11" className="u-m-right-5"/> {t('completed')}
                                </span>
                            }
                        </div>}
                </div>
                <div className={styles.bookState}>
                    {readingBook ? <>
                        {readingBook.status !== READING_BOOK_STATUS.complete &&
                            <>
                                <Icon.PlusCircle size={50} color={"#000"} />
                                <span className="u-m-top-5">{t('log_reading')}</span>
                            </>
                        }
                    </>
                    :
                    <>
                            <Icon.PlusCircle size={50} color={"#000"} />
                            <span className="u-m-top-5">{t('add_book')}</span>             
                    </>
                    } 
                </div>
                
            </button>
        </div>
    )
}

export default BookTile;