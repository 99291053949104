import React, {useState} from 'react';
import _ from 'lodash';
import { useMutation} from '@apollo/react-hooks';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';

import cx from 'classnames';

import { Button } from '@axeedge/go-teacher-components';

import { BOOK_TYPES } from '../../../../../services/constants';
import { ADD_READING_BOOK } from '../services/graphql';
import { CURRENT_PARENT_QUERY  } from '../../../../Home/services/graphql';

import styles from './addBook.module.scss';

const AddNewBook = ({close, hideSearch, studentId, searchTerm}) => {
    const [form, setForm] = useState({
        title: searchTerm ? searchTerm : '',
        author: '',
        bookType: 3,
        hideComments: true,
        classBook: false,
        error: null,
    })

    const { t } = useTranslation();

    const handleSubmit = (e) => {
        e.preventDefault();
        if (form.title !== '') {
            addReadingBook({
                variables: {
                    studentId: studentId,
                    author: form.author,
                    title: form.title,
                    coverUrl: 'noImage',
                    description: "test description",
                    bookType: form.bookType,
                    hideComments: !form.hideComments,
                }
            })
        } else {
            setForm({...form, error: true})
        }
    }

    const [addReadingBook, { loading }] = useMutation(ADD_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close()},
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            }
        ]
    });

    return (
        <div className={styles.newBookPanel}>
            <h1 onClick={hideSearch} className={styles.newBookTitle}><Icon.ArrowLeft size="40" className={styles.newBookTitleIcon} />{t('create_new_book')}</h1>
            <p>{t('cant_find_book_no_problem')}</p>
            <form onSubmit={(e) => {handleSubmit(e)}}>
                <div className={cx(styles.addBookField, "u-m-top-20")}>
                    <label className="u-m-right-5" htmlFor="Title">{t('book_title')}</label>
                    <input className={styles.styledInput} id="Title" type="text" placeholder={t('book_title')} value={form.title} onChange={(e) => {setForm({...form, title: e.currentTarget.value, error: false})}} />
                </div>
                <div className={cx(styles.addBookField, "u-m-top-20")}>
                    <label className="u-m-right-5" htmlFor="Author">{t('author_s')}</label>
                    <input className={styles.styledInput} id="Author" type="text" placeholder={t('author_s')} value={form.author} onChange={(e) => {setForm({...form, author: e.currentTarget.value})}} />
                </div>
                <div className={styles.addBookField}>
                    <label className="u-m-right-5">{t('book_type_colon')}</label>
                    <select value={form.bookType} className={styles.styledSelect} onChange={(e) => setForm({...form, bookType: parseInt(e.currentTarget.value)})}>
                        {_.map(BOOK_TYPES, type => {
                            if (type.type !== 2 && type.type !== 4) {
                                return (
                                    <option key={`type${type.type}`} value={type.type}>{type.label}</option>
                                )
                            }
                            
                        })}
                    </select>
                </div>
                {form.error && <p className="error u-m-base-1">{t('title_cannot_be_empty')}</p>}
                <Button className="u-m-right-5" type="submit"  disabled={(loading) && true}>{loading ? t('adding') : t('add_new_book')}</Button>
                <Button outline onClick={hideSearch}>{t('button.cancel')}</Button>
            </form>
        </div>
    )

}

export default AddNewBook;