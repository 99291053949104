import React, { useState, useContext } from 'react';
import { AuthContext } from '../../services/auth/AuthProvider';
import { useMutation } from '@apollo/react-hooks';
import { UPDATE_TERMS} from './services/graphql';
import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { TERMS_VERSION } from '@axeedge/go-shared-utils';
/*import styles from '../Faq.module.scss';*/
import cx from 'classnames';
import styles from './Terms.module.scss';
const TermsUpdate = () => {
    const { currentUser: user, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState(null)
    const [updateTerms, { loading: updatingTerms }] = useMutation(UPDATE_TERMS, {
        update: (_, { data }) => {
            if (data.approveTerms.errors && data.approveTerms.errors.length !== 0) {
                setFormErrors(data.approveTerms.errors);
                return;
            }
            if (data.approveTerms.user.id) {
                const cu = {...user,lastTermsVersionApproved: data.approveTerms.user.lastTermsVersionApproved}
                setCurrentUser(cu);
            }
        },
    });
    return (
        <div>
            <h1 className="u-m-base-2">We’ve updated our Terms and Privacy Policy</h1>
            <p className="u-m-base-2">We’ve made some changes to our Terms and Conditions and our Privacy Policy. To continue using BoomHub please review and accept the updated versions. To help we’ve outlined the key changes that apply to you as a teacher or parent below:</p>
            <h2>Terms Summary</h2>
            <ul  className={cx("u-m-base-2", styles.privacyList)}>
                <li>We’ve consolidated the separate terms for GoRead and GoWrite into a single document.</li>
                <li>We’ve simplified the wording of the Terms document to make it more concise and easier to read and understand.</li>
            </ul>
            <h2>Privacy Policy</h2>
            <ul className={cx("u-m-base-2", styles.privacyList)}>
                <li>We’ve consolidated the Privacy Policies from BoomHub and Squirrel Learning into a single policy document.</li>
            </ul>
            <ul className="u-m-base-2">
                <li><a href="https://boomreader.co.uk/terms" target="_blank">Terms &amp; Conditions</a></li>
                <li><a href="https://boomreader.co.uk/privacy" target="_blank">Privacy Policy</a></li>
            </ul>
            <Button onClick={() => updateTerms({variables: {
                version: (TERMS_VERSION).toString()
            }})}>{updatingTerms ? 'Saving' : 'Accept'}</Button>
        </div>
    )
}

export default TermsUpdate