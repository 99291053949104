import React, { useContext } from 'react';
import { useQuery } from '@apollo/react-hooks';
import { Loader } from '@axeedge/go-teacher-components';
import ls from 'local-storage';

import Header from '../../../components/Header';
import Nav from '../../../components/Nav';
import { CURRENT_PARENT_QUERY } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';

const LoggedInRouter = ({ children }) => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    
    const { error, loading } = useQuery(CURRENT_PARENT_QUERY, {
        onCompleted: ({ currentParent }) => {
            setCurrentUser(currentParent);
        },
        pollInterval: 60000
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <Loader />
    }

    if (currentUser.id && currentUser.displayName) {
        return (
            <div className='app__layout'>
                {ls('go_impersonating') && <p className='impersonating'>Impersonating Active</p>}
                <Header />
                <Nav user={currentUser} />
                <div className='app__content'>
                    {children}
                </div>
            </div>
        );
    }

    return null;
    
}

export default LoggedInRouter;
