import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';

const TermsModal = ({ closeModal }) => {
    const { t } = useTranslation();
    return (
        <Fragment>
            <h1>{t('terms_delivery_conditions')}</h1>
            <h4>{t('returns_refunds_policy')}</h4>
            <p className='u-m-base-1'>{t('returns_refunds_policy_1')}</p>
            <p className='u-m-base-1'>{t('returns_refunds_policy_2')}</p>
            <p className='u-m-base-4'>
                <Trans i18nKey='returns_refunds_policy_3'>
                    If your book is lost, damaged or otherwise faulty please contact our return department to arrange a replacement or refund: <a href={`mailto:${t('help_email_address')}`}>parents@boomhub.app</a>
                </Trans>
            </p>
            
            <h4>{t('delivery')}</h4>
            <p className='u-m-base-1'>{t('delivery_1')}.</p>
            <p className='u-m-base-1'>{t('delivery_2')}</p>
            <p className='u-m-base-4'>{t('delivery_3')}</p>

            <h4>{t('payments')}</h4>
            <p className='u-m-base-1'>{t('payments_1')}</p>
            <p className='u-m-base-1'>{t('payments_2')}</p>
            <p className='u-m-base-4'>{t('payments_3')}</p>

            <Button onClick={() => closeModal()}>{t('button.ok')}</Button>
        </Fragment>
    )
}

export default TermsModal;