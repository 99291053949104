import React from 'react'
import { useTranslation } from 'react-i18next';

import { Button, Modal } from '@axeedge/go-teacher-components';

import linkImg from '../../images/fbLink.png';
import styles from './fbmodal.module.scss';

const FBModalWelcome = ({ close, type }) => {
    const { t } = useTranslation();
    return (
        <Modal closeModal={close}>
            <div className={styles.modalContent}>
                <img src={linkImg} alt="Boom <-> Facebook" className={styles.fbImg} />
                <h1>{t('hey')}</h1>
                {type === 'LINKEXISTING' ?
                <p className="u-m-base-20">{t('fb_link_success_1')}</p>:
                <p className="u-m-base-20">{t('fb_link_success_2')}</p>
                }
                <p className="u-m-base-20">{t('fb_link_success_3')}</p>
                <Button onClick={close}>{t('continue')}</Button>
            </div>
        </Modal>
    )
}

export default FBModalWelcome;