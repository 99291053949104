import React, {useState} from 'react'
import * as Icon from 'react-feather';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import AddBookIsbn from './AddBookIsbn';
import AddBookTitle from './AddBookTitle';
import AddNewBook from './AddNewBook';
import styles from './addBook.module.scss';

const AddBook = ({studentId, close}) => {
    const [showSearch, setShowSearch] = useState(false)
    const [showAdd, setShowAdd] = useState(false)
    const [showHelp, setShowHelp] = useState(false)
    const [searchTerm, setSearchTerm] = useState(null);
    const hideSearch = () => {
        setShowSearch(false)
    }
    const { t } = useTranslation();

    return (
        <div className={styles.searchMain}>
            {(!showSearch && !showAdd && !showHelp) && <>
                <h2 className={styles.addTitle}>{t('add_book')}</h2>
                <Icon.XCircle className={cx(styles.closeSearch, styles.closeSearchAlt, styles.addTitle)} onClick={() => close(false)}/>
                <AddBookTitle studentId={studentId} close={close} hide={hideSearch} showHelp={() => setShowHelp(true)} showIsbn={() => setShowSearch(true)} searchTerm={setSearchTerm} />
                <button className={styles.newBook} onClick={() => setShowAdd(true)}>
                    <Icon.PlusCircle size="40" className="u-m-right-10"/>
                    <div>
                        <p><span className="u-bold u-base-tight">{t('cant_find_book')}</span><br />{t('no_problem_you_can_add')}</p>
                    </div>
                </button>
            </>
            }
            {showHelp && 
                <div className={styles.helpText}>
                    <span className={styles.helpBack} onClick={() => {setShowHelp(false)}}><Icon.ArrowLeft /> Back</span>
                    <p className="u-bold u-m-top-20">{t('searchHelp_1')}</p>
                    <h2 className="u-m-top-20">{t('searchHelp_2')}</h2>
                    <p className="u-m-top-20">{t('searchHelp_3')}</p>
                    <p className="u-bold u-m-top-20">{t('searchHelp_4')}</p>
                    <h2 className="u-m-top-20">{t('searchHelp_5')}</h2>
                    <p className="u-m-top-20">{t('searchHelp_6')}</p>
                    <p className="u-m-top-20 u-bold">{t('searchHelp_7')}</p>
                </div>
            }
            {showSearch &&
                <AddBookIsbn studentId={studentId} close={close} hide={hideSearch} />
            }
            {showAdd && 
                <AddNewBook studentId={studentId} close={close} hideSearch={() => setShowAdd(false)} searchTerm={searchTerm}  />
            }
        </div>
    )
}

export default AddBook;