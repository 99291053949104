import React from 'react';
import { CheckCircle } from 'react-feather';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import ImageFadeIn from 'react-image-fade-in';
import brianImg from '../../../images/brian_blessed.png';
import { Button } from '@axeedge/go-teacher-components';
import { useLocation } from 'react-use';
import sharedStyles from '../SharedStore.module.scss'
import ButtonLink from '../../../components/ButtonLink';

const OrderConfirmed = ({ orderId, styles, user }) => {
    const location = useLocation();

    const { t } = useTranslation();
    return (
        <div className={cx(styles.orderConfirmPage, { 'card-mobile': user })}>
            {location && location.state && location.state.isWriteToRaise ? (
                <div className={sharedStyles.wtrOrderConfirmed}>
                    <ImageFadeIn className={sharedStyles.wtrOrderConfirmedImg} src={brianImg} alt='Brian Blessed' />
                    <h1 className={sharedStyles.wtrOrderConfirmedTitle}>Thank you for your order</h1>
                    <h2 className={sharedStyles.wtrOrderConfirmedSubtitle}>{t('order_ref')} <strong>{orderId}</strong></h2>
                    <p className='u-m-base-2'>Please keep a record of your confirmation number. and refernce it in any contact with us.We’ve sent you a confirmation email.</p>
                    <h2 className={sharedStyles.wtrOrderConfirmedSubtitle}>Help raise more...</h2>
                    <p>This book is an amazing keepsake and perfect for your extended family. Share the unique book code so that others can purchase copies of this book</p>
                    <h3 className={cx(sharedStyles.wtrOrderConfirmedSubtitle, 'u-m-base-3 u-m-top-3')}>{location?.state?.bookCode}</h3>
                    <ButtonLink to='/store/code'>Purchase another book</ButtonLink>

                </div>
            ) : (
                <>
                    <CheckCircle className={styles.orderConfirmPageIcon} />
                    <h1 className='u-m-base-3'>{t('thanks_for_your_order')}</h1>
                    <p>{t('order_ref')} <strong>{orderId}</strong></p>
                    <p className='u-m-base-3'>{t('weve_sent_confirmation_email')}</p>
                    <div className='u-m-base-2'>
                        <ButtonLink to='/store/code'>{t('purchase_another')}</ButtonLink>
                    </div>

                    {!user && (
                        <a href={location?.state?.isBeeTemplate ? "https://boomwriter.co.uk/iwb/" : "https://boomwriter.co.uk/buy"}>
                            <Button>{t('back_to_gowrite')}</Button>
                        </a>
                    )}
                </>
            )}


        </div>
    )
}

export default OrderConfirmed;