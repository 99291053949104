import React, { useState, useContext } from 'react';
import { Router } from '@reach/router';
import ImageFadeIn from 'react-image-fade-in';
import { X } from 'react-feather';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import CodeInput from './scenes/CodeInput';
import logo from '../../images/boomwriter-logo.svg';
import loggedOutStyles from './LoggedOutStore.module.scss';
import styles from './Store.module.scss';
import OrderBook from './scenes/OrderBook';
import { STRIPE_DETAILS_QUERY } from './services/graphql';
import OrderConfirmed from './scenes/OrderConfirmed';
import { AuthContext } from '../../services/auth/AuthProvider';


const Store = () => {
    const { t } = useTranslation();
    const [book, setBook] = useState(null);
    const [stripePromise, setStripePromise] = useState(null);
    const { currentUser } = useContext(AuthContext);
    const { data, error, loading } = useQuery(STRIPE_DETAILS_QUERY, {
        onCompleted: ({ stripeDetails }) => {
            setStripePromise(loadStripe(stripeDetails.publicKey));
        }
    });

    if (error) {
        return <p>{error.message}</p>
    }

    if (loading) {
        return <p>{t('loading')}</p>
    }

    if (data && stripePromise) {
        return (
            <Elements stripe={stripePromise} options={{ locale: 'en-GB' }}>
                {
                    !currentUser ? (
                        <div className={loggedOutStyles.storePage}>
                            <header className={loggedOutStyles.storePageHeader}>
                                <div className={loggedOutStyles.storePageHeaderLeft}>
                                    <ImageFadeIn src={logo} className={loggedOutStyles.storePageLogo} />
                                    <p className={loggedOutStyles.storePageHeaderTitle}>{t('store')}</p>
                                </div>
                                <a className={loggedOutStyles.storePageExit} href='https://boomwriter.co.uk/buy'><X size='36' /></a>
                            </header>

                            <div className={loggedOutStyles.storePageContent}>
                                <Router>
                                    <CodeInput user={currentUser} styles={loggedOutStyles} path='code' setBook={setBook} default />
                                    <OrderBook user={currentUser} styles={loggedOutStyles} path='orderBook/:code' book={book} defaultAmounts={data.stripeDetails?.default} indiaAmounts={data.stripeDetails?.india}/>
                                    <OrderConfirmed user={currentUser} styles={loggedOutStyles} path='order/:orderId' />
                                </Router>
                            </div>
                        </div>
                    ) : (
                        <Router>
                            <CodeInput user={currentUser} styles={styles} path='code' setBook={setBook} default />
                            <OrderBook user={currentUser} styles={styles} path='orderBook/:code' book={book} defaultAmounts={data.stripeDetails?.default} indiaAmounts={data.stripeDetails?.india} />
                            <OrderConfirmed user={currentUser} styles={styles} path='order/:orderId' />
                        </Router>
                    )
                }

            </Elements>
        )
    }

    return null;
}

export default Store;
