import React from 'react'
import ImageFadeIn from "react-image-fade-in";
import { LogOut } from 'react-feather';
import { Link } from '@reach/router';

import LogoutButton from '../LogoutButton';

import logo from '../../images/boomer.png'
import styles from './Header.module.scss';


const Header = () => {
    return (
        <header className={styles.header}>
            <Link to='/'>
                <ImageFadeIn className={styles.headerLogo} src={logo} />
            </Link>
            <LogoutButton classes={styles.headerLogout}><LogOut className={styles.headerLogoutIcon}/></LogoutButton>
        </header>
    )
}

export default Header;
