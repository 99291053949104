import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { CURRENT_PARENT_QUERY } from '../../../Home/services/graphql';
import ReadingBooksContent from './readingBooksContent';

const ReadingBooks = ({ id }) => {
    const { t } = useTranslation();
    const { data, error, loading } = useQuery(CURRENT_PARENT_QUERY);

    return (
        <>
        {loading && <p>{t('loading')}</p>}
        {error && <p>{t('something_happened')}</p>}
        {data && <ReadingBooksContent id={id} students={data.currentParent.children} />}
        </>
    )
}

export default ReadingBooks;
