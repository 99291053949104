import React from 'react';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const Totals = ({ price, styles, totals, book }) => {
    const { t } = useTranslation();

    return (
        <>
        <div className={styles.orderTotals}>
            <div className={styles.orderTotalsItem}>
                <p>{totals.copies}{t('x_book')}</p>
                <p>{book.classBook.bookTemplate.isBeeTemplate ? '₹' : t('currency_icon')}{((totals.copies * price) / 100).toFixed(2)}</p>
            </div>
            {book && book.classBook && !book.classBook.activeWriteToRaisePromotion && (
                <div className={styles.orderTotalsItem}>
                    <p>{t('shipping')}</p>
                    <p>{book.classBook.bookTemplate.isBeeTemplate ? '₹' : t('currency_icon')}{(totals.shipping / 100).toFixed(2)}</p>
                </div>
            )}

            {!book.classBook.bookTemplate.isBeeTemplate &&
                <div className={styles.orderTotalsItem}>
                    <p>{t('vat')}</p>
                    <p>{t('currency_icon')}{(0).toFixed(2)}</p>
                </div>
            }
            <div className={cx(styles.orderTotalsItem, styles.orderTotalsItemLast)}>
                <p>{t('total')}</p>
                <p>{book.classBook.bookTemplate.isBeeTemplate ? '₹' : t('currency_icon')}{(totals.total / 100).toFixed(2)}</p>
            </div>
        </div>
            {book.classBook.bookTemplate.isBeeTemplate && (
                <div className={styles.contactPanel}>
                    <h3 className='heavy'><b>Need help?</b></h3>
                    <p className='u-m-base-1'>In case of difficulty ordering your book, please contact us using the details below:</p>
                    <p>email: contact@overleaf.co.in</p>
                    <p>Telephone: 9821705974</p>
                </div>
            )}
        </>
    )
}

export default Totals;