import React, { useState, useEffect, useContext, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import cx from 'classnames';
import { navigate } from '@reach/router';
import * as Icon from 'react-feather';
import ImageFadeIn from 'react-image-fade-in';
import { useTranslation } from 'react-i18next';

import { useMutation } from '@apollo/react-hooks';
import { READING_BOOK_STATUS, CHAPTER_STATUS } from '@axeedge/go-shared-utils';
import { DELETE_READING_BOOK_MUTATION } from '../../services/readingBook/graphql';
import { EDIT_LOG, DELETE_LOG } from './scenes/ReadingBooks/services/graphql';
import { CURRENT_PARENT_QUERY } from '../../scenes/Home/services/graphql';
import ButtonLink from '../../components/ButtonLink';
import { Dropdown } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../services/auth/AuthProvider';
import bookIcon from '../../images/book-icon.svg';
import gem from './images/gem.svg';
import styles from './Child.module.scss';
import logStyles from './scenes/ReadingBooks/components/addLog.module.scss';
import AddBook from './scenes/ReadingBooks/components/AddBook';
import { Button, Modal, FormErrors } from '@axeedge/go-teacher-components';
import LogNew from './scenes/ReadingBooks/components/LogNew';
import TypeLabel from '../../components/BookTypeLabel';
import BookTile from '../../components/BookTile';

import goWriteLogo from '../../images/boomwriter-logo.svg';
import goReadLogo from '../../images/boomreader-logo.svg';
import Boomer from '../../images/loader-boomer.png'
import GroupBookContent from './scenes/ReadingBooks/components/GroupReadingBook'
const getWriteBooks = classes => {
    const books = [];
    classes.forEach(c => {
        c.books.forEach(book => {
            if (book.classPack.isWritingActivity || (book.isSingleChapter && book.chapters[0].status < CHAPTER_STATUS.writing) || (!book.isSingleChapter && book.currentChapter === 0) || (!book.isSingleChapter && book.currentChapter === 1 && book.chapters[1].status < CHAPTER_STATUS.writing)) {
                return null
            }
            books.push(book)
        })
    })
    return books;
}

const StudentContent = ({ id, students }) => {
    const { currentUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const [child, setChild] = useState(students.find(c => c.id === id))
    useEffect(() => {
        if (child !== students.find(c => c.id === id)) {
            setChild(students.find(c => c.id === id));
        }
    }, [students, child, id])
    const currentBooks = _.filter(_.sortBy([...child.student.readingBooks, ...child.student.groupReadingBooks], 'book.title', 'asc'), book => {
        return book.status !== READING_BOOK_STATUS.complete
    });
    const readingBooks = currentBooks.filter(c => c.bookType === 1);
    const otherBooks = currentBooks.filter(c => c.bookType !== 1);
    const writeBooks = getWriteBooks(child.student.classes);
    const [selectedBook, setSelectedBook] = useState(null);
    const [selectedGroupBook, setSelectedGroupBook] = useState(null)
    const [showAddBook, setShowAddBook] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [showLogSuccess, setShowLogSuccess] = useState(false);
    const [showLogDeleteSuccess, setShowLogDeleteSuccess] = useState(false);
    const [showComplete, setShowComplete] = useState(false);

    const closeBook = () => {
        setShowSuccess(false);
        setShowLogSuccess(false);
        setShowLogDeleteSuccess(false);
        setShowComplete(false);
        setSelectedBook(null);
        setSelectedGroupBook(null);
    }

    useEffect(() => {
        if (selectedBook || selectedGroupBook) {
            document.body.classList.add('preventScroll');
        } else {
            document.body.classList.remove('preventScroll');
        }
    }, [selectedBook, selectedGroupBook])


    const handleOpen = (book) => {
        if (book.students) {
            setSelectedGroupBook(book)
        } else {
            setSelectedBook(book.id)
        }
        // setSelectedBook(book.id)
    }

    const displayLogAuthor = author => {
        if (author.id === currentUser.id) {
            return t('you');
        }
        switch (author.role) {
            case 'Parent':
                return author.displayName
            case 'Teacher':
                return author.displayName
            case 'Student':
                return author.displayName //TODO: avatar
            default:
                return '-'
        }
    }

    const Log = ({ log, action }) => {
        var date = moment(log.readingDate);
        return (
            <li className={cx(styles.logListItem, log.author.id !== currentUser.id && styles.logListItemCantEdit)} onClick={() => { log.author.id === currentUser.id && action(log) }}>
                <div className={styles.logListItemHeader}>
                    <div className={styles.logListItemHeaderMain}>
                        <span className={styles.logListItemHeaderDate}>{date.format("DD MMM YY")}</span>
                        <span className={styles.logListItemHeaderPages}><Icon.FileText size="20" className='u-text-muted u-mr-2' /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}</span>
                    </div>
                    <div>{displayLogAuthor(log.author)}</div>
                </div>
                {log.entry !== "" &&
                    <div className={styles.logListSection}>
                        <p>{log.entry}</p>
                    </div>
                }
                {log.problemWords.length > 0 && (
                    <div className={styles.logListSection}>
                        {log.problemWords.map((w, i) => <span key={`word-${i}`} className={cx('u-m-right-5', styles.wordLabel)}>{w.word}</span>)}
                    </div>
                )}
                {log.author.id === currentUser.id &&
                    <Icon.Settings className={styles.logListItemCog} />
                }
            </li>
        )
    }

    const words = (problemWords) => {
        let words = [];
        _.map(problemWords, word => {
            return words.push(word.word);
        })
        return words;
    }

    const EditLog = ({ log, close }) => {
        const [formErrors, setFormErrors] = useState(false);
        const [confirmLogDelete, setConfirmLogDelete] = useState(null);


        const [form, setForm] = useState({
            comment: log.entry ? log.entry : '',
            problemWords: log.problemWords ? words(log.problemWords) : [],
        })

        const [editLog, { loading }] = useMutation(EDIT_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.editReadingBookLog.errors && data.editReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.editReadingBookLog) {
                    setShowLogSuccess(true);
                }
            },
            refetchQueries: [{ query: CURRENT_PARENT_QUERY }],
            variables: {
                studentReadingBookLogId: log.id,
                entry: form.comment,
                problemWords: form.problemWords
            }
        });

        const [deleteLog, { loading: deletingLog }] = useMutation(DELETE_LOG, {
            awaitRefetchQueries: true,
            update: (_, { data }) => {
                if (data.deleteReadingBookLog.errors && data.deleteReadingBookLog.errors.length !== 0) {
                    setFormErrors(true);
                    return;
                }
                if (data.deleteReadingBookLog && data.deleteReadingBookLog.deleted) {
                    setShowLogDeleteSuccess(true);
                    setConfirmLogDelete(false);
                }
            },
            refetchQueries: [{ query: CURRENT_PARENT_QUERY }],
            variables: {
                studentReadingBookLogId: log.id,
                studentId: child.student.id
            }
        });

        const handleEdit = (e) => {
            e.preventDefault();
            editLog();
        }

        let date = moment(log.readingDate);

        const tagInput = useRef(null);

        const addWordTags = e => {
            const val = e.target.value;
            if ((e.key === 'Enter' || e.key === ',') && val && val.replace(/\s/g, '').length) {
                e.preventDefault();
                if (form.problemWords.find(word => word.replace(/\s/g, '').toLowerCase() === val.replace(/\s/g, '').toLowerCase())) {
                    return;
                }
                setForm({
                    ...form,
                    problemWords: [...form.problemWords, val]
                });
                tagInput.current.value = null;
            }
        };

        const removeWordTag = i => {
            const newWords = [...form.problemWords];
            newWords.splice(i, 1);
            setForm({
                ...form,
                problemWords: newWords
            });
        };

        return (
            <>
                <h1 className={cx(styles.bookSubHeading, "u-m-top-20")} onClick={close}><Icon.ArrowLeft className={styles.bookSubHeadingIcon} /> History</h1>
                <div className={cx("u-m-top-20 u-m-base-20", styles.editHeader)}>
                    <div className={cx(styles.editMeta, "u-m-right-20")}>
                        <Icon.User className="u-m-right-5" /> {displayLogAuthor(log.author)}
                    </div>
                    <div className={styles.editMeta}>
                        <Icon.FileText className="u-m-right-5" /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}
                    </div>
                    <div className={cx(styles.editMeta, styles.editDate)}>
                        {date.format("DD MMM YY")}
                    </div>
                </div>
                <div className={styles.editPanel}>
                    <form onSubmit={(e) => handleEdit(e)}>
                        <label>{t('comment_colon')}</label>
                        <textarea className={cx(styles.logReadingComment, 'u-m-top-10 basic-form__text-box')} rows="3" placeholder={t('ph_write_comment')} value={form.comment} onChange={(e) => setForm({ ...form, comment: e.currentTarget.value })}></textarea>
                        <div className={cx(styles.logReadingField, 'u-m-top-10')}>
                            <input
                                type="text"
                                placeholder={t('ph_separate_words')}
                                className='basic-form__text-box'
                                onKeyPress={(e) => addWordTags(e)}
                                ref={tagInput}
                            />
                        </div>
                        <div className={cx(logStyles.logReadingField, 'u-m-top-10 u-m-base-20')}>
                            {_.map(form.problemWords, (word, i) => {
                                return (
                                    <span className={logStyles.logReadingProbWord} key={`probWord${i}`}>
                                        {word}
                                        <button className={logStyles.logReadingProbWordDelete} type="button" onClick={() => removeWordTag(i)}><Icon.X size="12" /></button>
                                    </span>
                                )
                            })}
                        </div>
                        {formErrors && <p className="error u-smaller u-m-base-10">{t('oops_something_went_wrong')}</p>}
                        <Button className="u-m-right-10" disabled={loading}>{loading ? t('updating') : t('button.update')}</Button>
                        <Button type='button' outline onClick={close}>{t('button.cancel')}</Button>
                    </form>
                </div>
                <Button type='button' className='btn-danger u-m-top-3' outline onClick={() => setConfirmLogDelete(true)}>{deletingLog ? t('saving') : t('button.delete')}</Button>

                {confirmLogDelete && 
                    <Modal closeModal={() => setConfirmLogDelete(false)}>
                        <div className='u-text-center'>
                            <h3>{t('are_you_sure_you_want_delete_log')}</h3>
                            <Button
                                className='u-m-right-1 u-m-top-3'
                                onClick={() => deleteLog() }>{loading ? `${t('saving')}...` : t('button.yes')}</Button>
                            <Button className='btn-danger'  onClick={() => setConfirmLogDelete(false)}>{t('button.no')}</Button>
                        </div>
                    </Modal>
                }
            </>
        )
    }

    const BookContent = () => {
        const [deleteFormErrors, setDeleteFormErrors] = useState([]);

        const [bookState] = useState(child.student.readingBooks.find(c => c.id === selectedBook));
        const [actionFilter, setActionFilter] = useState((bookState.status === READING_BOOK_STATUS.complete || showLogSuccess || showLogDeleteSuccess) ? "HISTORY" : "LOG");
        const [recentLogs] = useState(_.orderBy(bookState.logs, ['readingDate', 'date'], ['desc', 'desc']));
        const review = _.filter(bookState.book.reviews, review => {
            return review.author && review.author.id === child.student.id
        })[0];

        let reviewImg;
        if (review) {
            reviewImg = `../../../images/rating/${review.reviewRate}.svg`;
        }
        const [selectedLog, setLog] = useState(null)
        let startDate;
        let endDate;
        if (recentLogs.length > 0) {
            startDate = moment(recentLogs[recentLogs.length - 1].readingDate);
            let completedLog = _.filter(recentLogs, ['isCompletion', true])
            if (completedLog.length > 0) {
                endDate = moment(completedLog[0].readingDate);
            }
        }
        const [deleteBook] = useMutation(DELETE_READING_BOOK_MUTATION, {
            awaitRefetchQueries: true,
            refetchQueries: [{ query: CURRENT_PARENT_QUERY }],
            variables: {
                readingBookId: bookState.book.id,
                studentId: child.student.id
            },
            onCompleted: (data) => {
                if (data.deleteReadingBookFromStudent.errors && data.deleteReadingBookFromStudent.errors.length > 0) {
                    setDeleteFormErrors(data.deleteReadingBookFromStudent.errors);
                    return
                }
                if (data.deleteReadingBookFromStudent && data.deleteReadingBookFromStudent.deleted) {
                    setSelectedBook(null);
                }
            },
        })

        const onClickEdit = (log) => {
            setActionFilter("EDIT");
            setLog(log);
        }

        const onDeleteBook = () => {
            if (window.confirm(t('are_you_sure_delete_book'))) {
                deleteBook();
            }
        }

        return (
            <>
                <h1 className={logStyles.bookHeading}>{bookState.book.title}</h1>
                <TypeLabel type={bookState.bookType} />
                {actionFilter !== 'EDIT' &&
                    <>
                        <div className={cx(logStyles.bookFilters, logStyles.bookFiltersLog, "u-m-top-20 u-m-base-20")}>
                            {bookState.status !== READING_BOOK_STATUS.complete && <button className={logStyles.filter} onClick={() => setActionFilter('LOG')}><span className={cx(logStyles.filterInner, actionFilter === 'LOG' ? logStyles.filterInnerActive : null)}>{t('log_reading')}</span></button>}
                            <button className={logStyles.filter} onClick={() => setActionFilter('HISTORY')}><span className={cx(logStyles.filterInner, actionFilter === 'HISTORY' ? logStyles.filterInnerActive : null)}>{t('history')}</span></button>
                            <button className={logStyles.filter} onClick={() => setActionFilter('BOOKINFO')}><span className={cx(logStyles.filterInner, actionFilter === 'BOOKINFO' ? logStyles.filterInnerActive : null)}>{t('book_info')}</span></button>
                            {(recentLogs.filter(log => log.author && log.author.role === "Teacher").length === 0 && bookState.addedBy && bookState.addedBy.id === currentUser.id) && (
                                <Dropdown classNames={logStyles.bookFiltersLogAction}>
                                    <li><button onClick={() => onDeleteBook()}>{t('button.delete')}</button></li>
                                </Dropdown>
                            )}
                        </div>
                        {deleteFormErrors.length !== 0 && <FormErrors errors={deleteFormErrors} />}
                    </>
                }
                {actionFilter === 'LOG' &&
                    <>
                        {showSuccess ?
                            <>
                                <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                <p className="u-m-base-20">{t('your_log_for_has_been_added', { studentName: child.student.firstName })}</p>
                                <Button onClick={() => setShowSuccess(false)}>{t('log_more')}</Button>
                            </> :
                            <LogNew book={bookState} student={child.student} recentLog={recentLogs[0] && recentLogs[0].pageEnd} close={setSelectedBook} success={setShowSuccess} complete={setShowComplete} />
                        }
                    </>
                }
                {actionFilter === 'HISTORY' &&
                    <>
                        {showLogSuccess &&
                            <p className={cx(styles.editMeta, "u-bold u-m-base-20")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_update_success')}</p>
                        }
                        {showLogDeleteSuccess &&
                            <p className={cx(styles.editMeta, "u-bold u-m-base-20")}><Icon.CheckCircle className="u-m-right-10" color="#6B9A11" />{t('log_delete_success')}</p>
                        }
                        {showComplete &&
                            <p className="u-bold u-m-base-20">{t('book_will_stay_seven_days')}</p>
                        }
                        {bookState.status === READING_BOOK_STATUS.complete && <>
                            <p className="u-m-base-5">{t('started_colon')} {startDate.format("DD MMM YY")}</p>
                            <p className="u-m-base-10">{t('completed_colon')} {endDate.format("DD MMM YY")}</p>
                        </>
                        }
                        {review &&
                            <>
                                <p className={styles.reviewHeader}>{t('review_by_name', { studentName: child.student.firstName })}</p>
                                <div className={styles.reviewBlock}>
                                    <div>
                                        <img src={reviewImg} alt={"Review"} />
                                    </div>
                                    <div className={styles.reviewBody}>
                                        <p>{review.reviewText}</p>
                                    </div>
                                </div>
                            </>
                        }
                        <ul>
                            {_.map(recentLogs, log => {
                                return <Log key={`activeBook${log.id}`} log={log} action={onClickEdit} />
                            })}
                        </ul>
                    </>
                }
                {actionFilter === 'BOOKINFO' &&
                    <>
                        <h2 className={cx(styles.bookHeading, "u-m-base-5")}>{bookState.book.title}</h2>
                        {bookState.book.author && <p>{bookState.book.author}</p>}
                        {bookState.book.isbn && <p className="u-m-top-20">ISBN: {bookState.book.isbn}</p>}
                        {bookState.book.isbn13 && <p>ISBN13: {bookState.book.isbn13}</p>}
                        {bookState.book.publisher && <p className="u-m-top-20">{bookState.book.publisher.name}</p>}
                    </>
                }
                {actionFilter === 'EDIT' &&
                    <>
                        <EditLog log={selectedLog} close={() => { setActionFilter("HISTORY"); setLog(null); }} />
                    </>
                }
            </>
        )
    }

    return (
        <>
            {showAddBook ?
                <AddBook close={() => setShowAddBook(false)} studentId={child.student.id} /> :
                <div className={styles.childContainer}>
                    <div className={styles.childContainerLeft}>
                        <div className={styles.childInfo}>
                            <div className={styles.profileImgHolder}>
                            <img src={child.student.boomerAvatarUrl ? `${child.student.boomerAvatarUrl}?v=${child.student.updatedAt.replace(/\s/g, '' )}` : Boomer} className={styles.profileImg} />
                            </div>
                            <div className={styles.childInfoLower}>
                                <p className={styles.childName}>{child.student.firstName} {child.student.lastName}</p>
                                <div className={styles.gemPanel}>
                                    <img src={gem} className={styles.gem} alt="Gem" />
                                    <p>{child.student.availableGems} {child.student.availableGems.length === 1 ? t('gem') : t('gems')}</p>
                                </div>
                            </div>
                            <div className={styles.childInfoLogin}>
                                <p className={styles.childInfoLoginTitle}>Login details:</p>
                                <p>School code: {child.student.school.schoolCode}</p>
                                <p>Login code: {child.student.loginCode}</p>
                            </div>
                        </div>
                        <div className={styles.sections}>
                            <div className={styles.section}>
                                <div className={cx(styles.sectionTitle, styles.sectionTitleRead)}>
                                    <ImageFadeIn src={goReadLogo} className={styles.sectionTitleImg} />
                                    <h4 className={styles.sectionTitleText}>Reading {currentBooks.length !== 1 ? t('books_lc') : t('book_lc')}</h4>
                                    <button className={styles.addBookBtn} onClick={() => { setShowAddBook(true) }}>
                                        <Icon.PlusCircle size="40" color="#000" />
                                    </button>
                                </div>
                                {
                                    currentBooks.length === 0 ? <>
                                        <p className={cx('u-m-top-2 u-m-base-1', styles.noResults)}>{t('no_reading_books')}</p>
                                        <Button onClick={() => setShowAddBook(true)} className="u-m-right-10">{t('add_book')}</Button>
                                    </> :
                                        <>  <div>
                                            {_.sortBy(readingBooks, 'book.title', 'asc').map(book => {
                                                return (
                                                    <BookTile key={book.id} studentId={child.student.id} readingBook={book} book={book.book} event={() => handleOpen(book)} />
                                                )
                                            })
                                            }
                                            {_.sortBy(otherBooks, 'book.title', 'asc').map(book => {
                                                return (
                                                    <BookTile key={book.id} studentId={child.student.id} readingBook={book} book={book.book} event={() => handleOpen(book)} />
                                                )
                                            })
                                            }
                                        </div>
                                        </>
                                }
                                {child.student.readingBooks.length > 0 &&
                                    <ButtonLink to='readingBooks' outline fullWidth>{t('view_all_reading_books')}</ButtonLink>
                                }
                            </div>
                            {
                                writeBooks.length !== 0 && (
                                    <div className={styles.section}>
                                        <div className={styles.sectionTitle}>
                                            <ImageFadeIn src={goWriteLogo} className={styles.sectionTitleImg} />
                                            <h4 className={styles.sectionTitleText}>{t('name_has_written', { studentName: child.student.firstName })}</h4>
                                        </div>

                                        {
                                            writeBooks.map(book => {
                                                return (
                                                    <button
                                                        key={book.id}
                                                        className={styles.book}
                                                        onClick={() => navigate(`/store/orderBook/${book.bookCode}-${child.student.storeCode}`)}
                                                    >
                                                        <div className={styles.bookLeft}>
                                                            <p className={cx(styles.bookTitle, styles.bookTitleWriting)}>{book.title || book.bookTemplate.name}</p>
                                                            <p className={styles.bookAuthor}>{book.studentsClass.school.name}</p>
                                                            <p className={cx('u-m-top-1', styles.bookAuthor)}>{t('code_colon')} {book.bookCode}-{child.student.storeCode}</p>
                                                        </div>
                                                        <div className={styles.bookCoverHolder}>
                                                            <img alt='' onError={(e) => { e.currentTarget.src = bookIcon; e.currentTarget.className += ' ' + styles.resultBookImageError }} src={book.bookTemplate.coverUrl ? book.bookTemplate.coverUrl : bookIcon} className={styles.bookCover} />
                                                        </div>
                                                    </button>
                                                )
                                            })
                                        }
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            }
            <div className={cx(styles.bookBg, (selectedBook || selectedGroupBook) && styles.bookBgShow)} onClick={() => closeBook()}></div>
            <div className={cx(styles.bookModal, (selectedBook || selectedGroupBook) && styles.bookModalOpen)}>
                {selectedBook && <BookContent />}
                {selectedGroupBook && <GroupBookContent book={selectedGroupBook} child={child} close={setSelectedGroupBook} />}
            </div>
            {(selectedBook || selectedGroupBook) && <Icon.XCircle onClick={() => closeBook()} className={styles.closeBook} />}
        </>
    )
}

export default StudentContent;
