import React, {useState} from 'react'
import styles from '../../../Child.module.scss';
import logStyles from './addLog.module.scss';
import TypeLabel from '../../../../../components/BookTypeLabel';
import { READING_BOOK_STATUS } from '@axeedge/go-shared-utils';
import { useTranslation } from 'react-i18next';
import cx from 'classnames'
import Button from '../../../../../components/ButtonLink/button';
import {useQuery, useLazyQuery, useMutation} from '@apollo/react-hooks'
import { GET_GROUP_BOOK_QUERY } from '../services/graphql';
import GroupLogNew from './GroupLogNew';
import _ from 'lodash'
import moment from 'moment'
import starRating from '../../../../../images/star-filled.svg';
import * as Icon from 'react-feather';
const GroupReadingBook = ({book, child, close}) => {

    const { data, error, loading } = useQuery(GET_GROUP_BOOK_QUERY, {
        variables: {
            id: book.id
        },
        fetchPolicy: 'network-only',
    });
    
    const { t } = useTranslation();
    const [actionFilter, setActionFilter] = useState(book.status === READING_BOOK_STATUS.complete ? "HISTORY" : "LOG")
    const [showSuccess, setShowSuccess] = useState(false)


    const History = ({b}) => {
        const review = _.filter(b.book.reviews, review => {
            return review.author && review.author.id === child.student.id
        })[0];

        let reviewStars;
        if (review) {
            reviewStars = Array.from({ length: review.reviewRate }, (_, i) => i)
        }

        let startDate;
        let endDate;
        if (b.logs.length > 0) {
            startDate = moment(b.logs[b.logs.length - 1].readingDate);
            endDate = moment(b.logs[0].readingDate);
        }
        return (
            <>
            {book.status === READING_BOOK_STATUS.complete && startDate && endDate && <>
                <p className="u-m-base-5">{t('started_colon')} {startDate.format("DD MMM YY")}</p>
                <p className="u-m-base-2">
                    {t('completed_colon')} {endDate.format("DD MMM YY")}
                </p>
            </>
            }
            {review &&
                <>
                    <p className={styles.reviewHeader}>{t('review_by_name', { studentName: child.student.firstName })}</p>
                    <div className={styles.reviewBlock}>
                        <ul className={styles.reviewImg}>
                            {reviewStars.map(star => <li className='u-m-right-1' key={`img-${star}`}><img src={starRating} alt='' /></li>)}
                        </ul>
                        <div className={styles.reviewBody}>
                            <p>{review.reviewText}</p>
                        </div>
                    </div>
                </>
            }
            <ul>
                {_.map(_.orderBy(b.logs, ['readingDate', 'date'], ['desc', 'desc']), log => {
                    return <Log key={`activeBookLog${log.id}`} log={log} />
                })}
            </ul>
        </>
        )
    }

    const Log = ({ log }) => {
        var date = moment(log.readingDate);
        var includesChild = log.studentIds?.find((i) => i === child.student.id)
        if (includesChild) {
        return (
            <li className={styles.logListItem}>
                <div className={styles.logListItemHeader}>
                    <div className={styles.logListItemHeaderMain}>
                        <span className={styles.logListItemHeaderDate}>{date.format("DD MMM YY")}</span>
                        <span className={styles.logListItemHeaderPages}><Icon.FileText size="20" className='u-text-muted u-mr-2' /> {log.pageStart} - {log.isCompletion ? 'The End' : log.pageEnd}</span>
                    </div>
                    <div>{/*displayLogAuthor(log.author)*/}{log.author.displayName}</div>
                </div>
                {log.entry !== "" &&
                    <div className={styles.logListSection}>
                        <p>{log.entry}</p>
                    </div>
                }
                {log.problemWords.length > 0 && (
                    <div className={styles.logListSection}>
                        {log.problemWords.map((w, i) => <span key={`word-${i}`} className={cx('u-m-right-5', styles.wordLabel)}>{w.word}</span>)}
                    </div>
                )}

            </li>
        )
        }
        return null
    }
    
    if (loading) {
        return <p>Fetching book data...</p>
    }

    if (error) {
        return <p>Something went wrong</p>
    }
    if (data) {
        return (
            <>
                <h1 className={logStyles.bookHeading}>{book.book.title}</h1>
                <TypeLabel type={4} />
                <div className={cx(logStyles.bookFilters, logStyles.bookFiltersLog, "u-m-top-20 u-m-base-20")}>
                    {book.status !== READING_BOOK_STATUS.complete && <button className={logStyles.filter} onClick={() => setActionFilter('LOG')}><span className={cx(logStyles.filterInner, actionFilter === 'LOG' ? logStyles.filterInnerActive : null)}>{t('log_reading')}</span></button>}
                    <button className={logStyles.filter} onClick={() => setActionFilter('HISTORY')}><span className={cx(logStyles.filterInner, actionFilter === 'HISTORY' ? logStyles.filterInnerActive : null)}>{t('history')}</span></button>
                    <button className={logStyles.filter} onClick={() => setActionFilter('BOOKINFO')}><span className={cx(logStyles.filterInner, actionFilter === 'BOOKINFO' ? logStyles.filterInnerActive : null)}>{t('book_info')}</span></button>
                </div>
                {actionFilter === 'LOG' &&
                        <>
                            {!child.student.school.allowParentsGroupLogs ?
                            <p>Only teachers at this school can add logs to group books.</p>
                            :
                            showSuccess ?
                                <>
                                    <h1 className={styles.bookHeading}>{t('thanks')}</h1>
                                    <p className="u-m-base-20">{t('your_log_for_has_been_added', { studentName: child.student.firstName })}</p>
                                    <Button event={() => setShowSuccess(false)}>{t('log_more')}</Button>
                                </> :
                                <GroupLogNew book={data.groupReadingBook} child={child} setShowSuccess={setShowSuccess} close={close}/>
                            }
                        </>
                }
                {actionFilter === 'HISTORY' &&
                    <History b={data.groupReadingBook}/>
                }
                {actionFilter === 'BOOKINFO' &&
                    <>
                        <h2 className={cx(styles.bookHeading, "u-m-base-5")}>{book.book.title}</h2>
                        {book.book.author && <p>{book.book.author}</p>}
                        {book.book.isbn && <p className="u-m-top-20">ISBN: {book.book.isbn}</p>}
                        {book.book.isbn13 && <p>ISBN13: {book.book.isbn13}</p>}
                        {book.book.publisher && <p className="u-m-top-20">{book.book.publisher.name}</p>}
                    </>
                }
            </>
        )
    }

    return null
}

export default GroupReadingBook