import React from 'react';
import { Link } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';

import mailImg from '../../../images/confirm-email.png'

const ResetSent = () => {
    const { t } = useTranslation();
    return(
        <div className='auth-page__container'>
            <div className='auth-page__panel u-text-center'>
                <h3 className='heavy u-m-top-3 u-m-base-3 u-text-center'>{t('we_sent_link')}</h3>
                <div className='auth-page__confirm-email'><img src={mailImg} alt='' /></div>
                <p className='heavy'>{t('didnt_get_link')}</p>
                <p className='u-m-base-2'>{t('check_spam')}</p>
                <Link to='/'><Button>{t('login')}</Button></Link>
            </div>
        </div>
    )
}

export default ResetSent;