import React, {useState} from 'react';
import _ from 'lodash';
import cx from 'classnames';
import { useMutation} from '@apollo/react-hooks';
import { useTranslation } from 'react-i18next';

import { Button } from '@axeedge/go-teacher-components';

import { BOOK_TYPES } from '../../../../../services/constants';
import { ADD_READING_BOOK, ASSIGN_READING_BOOK} from '../services/graphql';
import { CURRENT_PARENT_QUERY  } from '../../../../Home/services/graphql';
import styles from './addBook.module.scss';
import bookIcon from '../../../../../images/book-icon.svg';

const SearchResults = ( {singleBook, studentId, close, code} ) => {

    const [selectedBook] = useState({open: true, book: singleBook.bookByIsbn})
    const { t } = useTranslation();
    const [newBook, setNewBook] = useState({
        title: '',
        author: '',
    })

    const [readingBook, setReadingBook] = useState({
        bookType: 3,
        hideComments: true,
        classBook: false,
    });


    const [addReadingBook, { loading }] = useMutation(ADD_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close()},
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            }
        ]
    });

    const [assignReadingBook, { loading: assignLoading }] = useMutation(ASSIGN_READING_BOOK, {
        awaitRefetchQueries: true,
        onCompleted: () => {close()},
        refetchQueries: () => [
            {
                query: CURRENT_PARENT_QUERY,
            }
        ]
    });

    const AddBookForm = ({book}) => {
        return (
            <>
                <div className={cx(styles.addBookHeader, 'u-m-base-20')}>
                    {/* <img alt='Book Cover' src={book.coverUrl} onError={(e) => {e.currentTarget.src = bookIcon; e.currentTarget.className += ' ' + styles.resultBookImageError}} className={styles.addBookCover} /> */}
                    <div>
                        <h1 className={styles.bookHeading}>{book.title}</h1>
                        <h2 className={styles.bookAuthor}>{book.author}</h2>
                        <div className={styles.resultBookCode}>ISBN: {book.isbn}</div>
                        <div className={cx(styles.resultBookCode, 'u-m-base-5')}>ISBN13: {book.isbn13}</div>
                    </div>
                </div>
                <div className={styles.addBookField}>
                    <label className="u-m-right-5">{t('book_type_colon')}</label>
                    <select value={readingBook.bookType} className={styles.styledSelect} onChange={(e) => setReadingBook({...readingBook, bookType: parseInt(e.currentTarget.value)})}>
                        {_.map(BOOK_TYPES, type => {
                            if (type.type !== 2 && type.type !== 4) {
                                return (
                                    <option key={`type${type.type}`} value={type.type}>{type.label}</option>
                                )
                            }
                            
                        })}
                    </select>
                </div>
                {!book.existsInDb ?
                    <Button className='u-m-top-20' onClick={() => {addReadingBook({
                        variables: {
                            studentId: studentId,
                            author: book.author,
                            title: book.title,
                            isbn: book.isbn,
                            isbn13: book.isbn13,
                            coverUrl: book.coverUrl,
                            description: "test description",
                            bookType: readingBook.bookType,
                            hideComments: !readingBook.hideComments,
                        }
                    })}} disabled={loading && true}>{loading ? t('adding') : t('add_book')}</Button> :
                    <Button className='u-m-top-20' onClick={() => {assignReadingBook({
                        variables: {
                            studentId: studentId,
                            bookId: book.id,
                            bookType: readingBook.bookType,
                            hideComments: !readingBook.hideComments,
                        }
                    })}}disabled={assignLoading && true}>
                    {assignLoading ? t('adding') : t('add_book')}</Button>
                }
            </>
        )
    }
    
    const AssignBook = () => {
        return (
        <>
        <div className={cx(styles.addBookField, "u-m-top-20")}>
            <label className="u-m-right-5">Book Title:</label>
            <input type="text" className={styles.styledInput} onChange={(e) => setNewBook({...newBook, title: e.currentTarget.value})} />
        </div>
        <div className={cx(styles.addBookField, "u-m-top-10")}>
            <label className="u-m-right-5">Author:</label>
            <input type="text" className={styles.styledInput} onChange={(e) => setNewBook({...newBook, author: e.currentTarget.value})} />
        </div>
        <div className={styles.addBookField}>
            <label className="u-m-right-5">{t('book_type_colon')}</label>
            <select value={readingBook.bookType} className={styles.styledSelect} onChange={(e) => setReadingBook({...readingBook, bookType: parseInt(e.currentTarget.value)})}>
                {_.map(BOOK_TYPES, type => {
                    if (type.type !== 2 && type.type !== 4) {
                        return (
                            <option key={`type${type.type}`} value={type.type}>{type.label}</option>
                        )
                    }
                    
                })}
            </select>
        </div>
            <Button className='u-m-top-20' onClick={() => {addReadingBook({
                variables: {
                    studentId: studentId,
                    author: newBook.author,
                    title: newBook.title,
                    isbn: code,
                    isbn13: code,
                    coverUrl: 'noImage',
                    description: "test description",
                    bookType: readingBook.bookType,
                    hideComments: !readingBook.hideComments,
                }
            })}} disabled={(newBook.title === '' || newBook.author === '' || loading) && true}>{loading ? 'Adding...' : 'Add Book'}</Button>
    </>           
        )
    }
    return (
        <>
                {selectedBook.book ? <AddBookForm book={selectedBook.book} /> : 
                    <>
                        <p>This book doesn't exist in our database would you like to add it?</p>
                        {AssignBook()}
                    </>
                }
        </>
    )
}

export default SearchResults;