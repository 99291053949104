import React, {useContext, useState, useEffect} from 'react'
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { useTranslation } from 'react-i18next';

import { getAppTokenName } from '@axeedge/go-shared-utils';

import { CONFIRM_NEW_EMAIL_MUTATION } from '../services/graphql';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { APP_NAME } from '../../../services/constants';
import loader from '../../../images/loader.svg';

const ConfirmNewEmail = ({token}) => {
    const { t } = useTranslation();
    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();
    const [errors, setErrors] = useState(null)
    const [confirmNewEmail] = useMutation(CONFIRM_NEW_EMAIL_MUTATION, {
        onCompleted: data => {
            if (data.confirmNewEmail.errors && data.confirmNewEmail.errors.length !== 0) {
                setErrors(data.confirmNewEmail.errors);
                return
            }
            if (data.confirmNewEmail.token) {
                ls(getAppTokenName(APP_NAME), data.confirmNewEmail.token);
                ls('go_parent_user', JSON.stringify(data.confirmNewEmail.parent));
                setCurrentUser({
                    ...data.confirmNewEmail.parent,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
        }
    });
    useEffect(() => {
        confirmNewEmail({
            variables: {
                confirmEmailToken: token
            }
        }) 
    }, [])
    return (

        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <h1 className='auth-page__title-light'>
                    {t('confirming_email')}
                </h1>
                <div style={{
                    width: '200px',
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '40px auto'
                }}>
                    <img src={loader} alt="Loading" />
                </div>
               
                {errors && <p className='u-center'>{t('oops_something_went_wrong')}</p>}
            </div>
        </div>
    )
} 

export default ConfirmNewEmail;