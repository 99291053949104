import React from 'react';
import cx from 'classnames';
import styles from './ButtonLink.module.scss';

const Button = ({ children, disabled, fullWidth, large, outline, to, event }) => {
    return (
        <button 
            disabled={disabled} 
            to={to} 
            className={cx(styles.button, {[styles.buttonFullWidth]: fullWidth}, {[styles.buttonLarge]: large}, {[styles.buttonOutline]: outline})}
            onClick={event}
        >
            {children}
        </button>
    )
}

export default Button;
