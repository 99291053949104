import { gql } from 'apollo-boost';

import { PARENT_QUERY } from '../../../services/constants';


export const PARENT_LINK_FB = gql`
mutation parentLinkFb($facebookId: String!) {
    parentLinkFb(facebookId: $facebookId) {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`
export const PARENT_UNLINK_FB = gql`
mutation parentUnlinkFb {
    parentUnlinkFb {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`


export const UPDATE_EMAIL = gql`
mutation editParentEmail($parentId: ID!, $email: String!) {
    editParentEmail(parentId: $parentId, email: $email) {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`

export const CHANGE_PASSWORD_MUTATION = gql`
mutation changePassword($existingPassword: String!, $newPassword: String!, $passwordConfirmation: String!) {
    changePassword(existingPassword: $existingPassword, newPassword: $newPassword, passwordConfirmation: $passwordConfirmation) {
        user {
            id
        }
        errors
    }
}
`;

export const SET_PASSWORD_MUTATION = gql`
mutation parentSetPassword($password: String!, $passwordConfirmation: String!) {
    parentSetPassword(password: $password, passwordConfirmation: $passwordConfirmation) {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`;

export const LINK_EXISTING_ACCOUNT_MUTATION = gql`
mutation parentLinkExistingAccount($email: String!, $password: String!, $facebookId: String!) {
    parentLinkExistingAccount(email: $email, password: $password, facebookId: $facebookId) {
        parent {
            ${PARENT_QUERY}
        }
        token
        errors
    }
}
`;