import React, { Fragment, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';

import { Button, FormErrors } from '@axeedge/go-teacher-components';

import { UPDATE_EMAIL } from '../services/graphql';
import { Check } from 'react-feather';
import { AuthContext } from '../../../services/auth/AuthProvider';

const UpdateEmail = ({ closeModal }) => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [success, setSuccess] = useState(false);
    const { t } = useTranslation();

    const [updateEmail, { loading }] = useMutation(UPDATE_EMAIL, {
        onCompleted: data => {
            if (data.editParentEmail.errors && data.editParentEmail.errors.length !== 0) {
                setFormErrors(data.editParentEmail.errors);
                return;
            }

            if (data.editParentEmail.parent.id) {
                setSuccess(true);
                reset({
                    email: '',
                })
                setCurrentUser(data.editParentEmail.parent);
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().email().required(t('req_enter_email_example'))
    });

    const { register, handleSubmit, errors, reset } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        setFormErrors([]);
        const { email} = values;
        updateEmail({
            variables: {
                parentId: currentUser.id,
                email,
            }
        })
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className='light u-m-base-3'>{t('update_email')}</h1>
                {!success &&
                <>
                <div className='basic-form__group'>
                    <label htmlFor='email' className='basic-form__text-label'>{t('email')}</label>
                    <input
                        name='email'
                        id='email'
                        className='basic-form__text-box'
                        type='email'
                        placeholder={t('ph_email')}
                        defaultValue={currentUser.email}
                        ref={register}
                    />
                </div>
                {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                <div className='u-m-top-3'>
                    <Button className='u-m-right-1' type='submit'>{loading ? t('saving') : t('button.save')}</Button>
                    <Button type='button' outline onClick={() => closeModal()}>{t('button.cancel')}</Button>
                </div>
                </>
                }
            </form>
            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
            {
                success && (
                    <p className='basic-form__success'><Check className='basic-form__success__icon'/>{t('we_have_sent_confirmation')}</p>
                )
            }
        </Fragment>
    )
}

export default UpdateEmail;
