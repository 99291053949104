import { gql } from 'apollo-boost';

import { PARENT_QUERY } from '../../../services/constants';

export const GET_S3_UPLOAD_URL_QUERY = gql`
query s3UploadUrl($name: String!) {
    s3UploadUrl(name: $name)
}
`;

export const EDIT_PARENT_MUTATION = gql`
mutation parentSetPassword($parentId: ID!, $firstName: String!, $lastName: String!, $displayName: String, $street: String, $city: String, $county: String, $postcode: String) {
    editParent(parentId: $parentId, firstName: $firstName, lastName: $lastName, displayName: $displayName, street: $street, city: $city, county: $county, postcode: $postcode) {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`;

export const EDIT_TEACHER_AVATAR_MUTATION = gql`
mutation addTeacherAvatar($avatarUrl: String!) {
    addTeacherAvatar(avatarUrl: $avatarUrl) {
        teacher {
            id
            avatarUrl
        }
        errors
    }
}
`