import { gql } from 'apollo-boost';

export const ADD_PARENT_MUTATION = gql`
mutation addParent($firstName: String!, $lastName: String!, $displayName: String!, $email: String, $facebookId: String, $termsVersionApproved: String!) {
    addParent(firstName: $firstName, lastName: $lastName, displayName: $displayName, email: $email, facebookId: $facebookId, termsVersionApproved: $termsVersionApproved) {
        parent {
            id
            token
            email
            facebookId
        }
        errors
    }
}
`;