import { gql } from 'apollo-boost';

import { PARENT_QUERY } from '../../../services/constants';

export const ADD_CHILD_MUTATION = gql`
mutation addChildToParent($studentCode: String!, $schoolCode: String!) {
    addChildToParent(studentCode: $studentCode, schoolCode: $schoolCode) {
        parent {
            ${PARENT_QUERY}
        }
        errors
    }
}
`;

export const FIND_CHILD_QUERY = gql`
query studentByCode($schoolCode: String!, $studentCode: String!) {
    studentByCode(schoolCode: $schoolCode, studentCode: $studentCode) {
        id
        firstName
        lastName
        loginCode
        school {
            id
            name
            schoolCode
        }
    }
}
`;