import React, { Fragment, useState, useContext } from 'react';
import { Link } from '@reach/router';
import { useMutation } from '@apollo/react-hooks';

import { Button, Modal } from '@axeedge/go-teacher-components';
import { AuthContext } from '../../services/auth/AuthProvider';
import { PARENT_LINK_FB, PARENT_UNLINK_FB } from './services/graphql';
import ChangePassword from './components/ChangePassword';
import AddPassword from './components/AddPassword';
import LinkAccount from './components/LinkAccount';
import UpdateEmail from './components/UpdateEmail';
import FacebookLoginWithButton from 'react-facebook-login';
import styles from './AccountSettings.module.scss';
import cx from 'classnames'
import fbImg from '../../images/fbLink.png';
import * as Icon from 'react-feather'
import { parse } from "query-string";
import { useTranslation } from 'react-i18next';

const AccountSettings = ({location}) => {
    const { currentUser, setCurrentUser } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [showLinkModal, setShowLinkModal] = useState(false);
    const [showPasswordModal, setShowPasswordModal] = useState((parse(location.search).setPassword && !currentUser.hasSetPassowrd) ? true : false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showNewPassword, setNewPasswordModal] = useState(false);
    const { t } = useTranslation();

    const [linkFB] = useMutation(PARENT_LINK_FB, {
        update: (_, { data }) => {
            if (data.parentLinkFb.errors && data.parentLinkFb.errors.length !== 0) {
                setFormErrors(data.parentLinkFb.errors);
                return;
            }

            if (data.parentLinkFb.parent.id) {
                setCurrentUser(data.parentLinkFb.parent);
            } 
        }
    })

    const [unlinkFB] = useMutation(PARENT_UNLINK_FB, {
        update: (_, { data }) => {
            if (data.parentUnlinkFb.errors && data.parentUnlinkFb.errors.length !== 0) {
                setFormErrors(data.parentUnlinkFb.errors);
                return;
            }

            if (data.parentUnlinkFb.parent.id) {
                setCurrentUser(data.parentUnlinkFb.parent);
            } 
        }
    })

    const responseFacebook = (response) => {
        linkFB({
            variables: {
                facebookId: response.id
            }
        })
      }

    return (
        <Fragment>
            <div className={styles.profileTabs}>
                <Link to={`/profile`} className={styles.profileTab}>
                    {t('your_profile')}
                </Link>
                <Link to={`/account`} className={cx(styles.profileTab, styles.profileTabActive)}>
                    {t('account_settings')}
                </Link>
            </div>
            <div className='card card-mobile'>
                <div className={styles.contentHolder}>
                    <div className={cx(styles.settingPanel, styles.settingPanelEmail)} onClick={()=> setShowEmailModal(true)}>
                        <Icon.Edit className={styles.settingPanelIcon}/>
                        <h3>{t('your_email')}</h3>
                        {currentUser.pendingEmail && <p>{t('pending_email')} {currentUser.pendingEmail}</p>}
                        <p>{currentUser.email}</p>
                    </div>
                    {currentUser.hasSetPassword ?
                        <div className={styles.settingPanel}>
                            <h3>{t('forgot_your_password')}</h3>
                            <Button onClick={() => setShowPasswordModal(true)} outline>{t('change_password')}</Button>
                        </div>
                    :
                        <div className={styles.settingPanel}>
                            <h3>{t('account_recovery')}</h3>
                            <p>{t('recovery_set_password')}</p>
                            <Button className='u-m-right-2' onClick={() => setNewPasswordModal(true)} outline>{t('set_password')}</Button>
                            <Button onClick={() => setShowLinkModal(true)} outline>{t('link_account')}</Button>
                        </div>
                    }
                    <div className={cx(styles.settingPanel, styles.fbSettingPanel)}>
                        <img src={fbImg} alt="GoApps <--> Facebook" className={styles.fbSettingImg}/>
                        {!currentUser.facebookId ?
                            <>
                                <p className="u-m-base-20">{t('link_your_facebook_account')}</p>
                                <FacebookLoginWithButton
                                    appId="2734367840140180"
                                    autoLoad={false}
                                    fields="name,email,picture"
                                    callback={responseFacebook}
                                    textButton={t('link_facebook_account')}
                                    cssClass="kep-login-facebook small"
                                    icon="fa-facebook"
                                />
                            </>
                                :
                            <>
                                <p className="u-m-base-20">{t('you_can_login_your_fb_account')}</p>
                                {currentUser.hasSetPassword && <Button onClick={() => unlinkFB()}>{t('unlink_account')}</Button>}
                            </>
                        }
                        {formErrors.length > 0 && <p>{t('something_went_wrong')} {t('please_contact_us_at')} <a href={`mailto:${t('help_email_address')}`}>{t('help_email_address')}</a>.</p>}
                    </div>
                </div>
            </div>
            {showLinkModal &&
                <Modal closeModal={() => setShowLinkModal(false)}>
                    <LinkAccount closeModal={() => setShowLinkModal(false)}/>
                </Modal>           
            }
            {showNewPassword &&
                <Modal closeModal={() => setNewPasswordModal(false)}>
                    <AddPassword closeModal={() => setNewPasswordModal(false)}/>
                </Modal>           
            }
            {
                showEmailModal && (
                    <Modal closeModal={() => setShowEmailModal(false)}>
                        <UpdateEmail closeModal={() => setShowEmailModal(false)}/>
                    </Modal>
                )
            }
            {
                showPasswordModal && (
                    <Modal closeModal={() => setShowPasswordModal(false)}>
                        <ChangePassword closeModal={() => setShowPasswordModal(false)} />
                    </Modal>
                )
            }
            </Fragment>
    )
}

export default AccountSettings;
