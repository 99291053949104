import React, {useContext} from 'react';
import ImageFadeIn from "react-image-fade-in";
import { Book, LogOut, Home, User, UserPlus } from 'react-feather';
import { Link } from '@reach/router';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';

import LogoutButton from '../LogoutButton';

import logo from '../../images/boomer.png'
import profileDefault from '../../images/profile-default.svg';
import styles from './Nav.module.scss';

import { AuthContext } from '../../services/auth/AuthProvider';
import { TERMS_VERSION } from '@axeedge/go-shared-utils';

const Nav = ({ user }) => {
    const { currentUser } = useContext(AuthContext);
    const { t } = useTranslation();
    const isActive = ({ isCurrent }) => {
        return isCurrent ? { className: cx(styles.navMenuLink, styles.navMenuLinkActive)} : { className: styles.navMenuLink}
    }

    const isPartiallyActive = ({ isPartiallyCurrent }) => {
        return isPartiallyCurrent ? { className: cx(styles.navMenuLink, styles.navMenuLinkActive)} : { className: styles.navMenuLink}
    }

    return (
        <div className={styles.nav}>
            <div className={styles.navUser}>
                <Link to='/profile'>
                    <ImageFadeIn src={user.avatarUrl || profileDefault} />
                    <p className={styles.navUserName}>{user.firstName} {user.lastName}</p>
                </Link>
            </div>
            {parseInt(user.lastTermsVersionApproved) >= TERMS_VERSION &&
                <ul className={styles.navMenu}>
                    <li><Link to='/' getProps={isActive}><i className={styles.navMenuLinkIcon}><Home size="32" /></i> {t('home')}</Link></li>
                    <li><Link to='/profile' getProps={isActive}><i className={styles.navMenuLinkIcon}><User size="32" /></i> {t('profile')}</Link></li>
                    <li><Link to='/addChild' getProps={isActive}><i className={styles.navMenuLinkIcon}><UserPlus size="32" /></i> {t('add_child')}</Link></li>
                    <li><Link to='/store' getProps={isPartiallyActive}><i className={styles.navMenuLinkIcon}><Book size="32" /></i> {t('store')}</Link></li>
                </ul>
            }
            <div className={styles.navExit}>
                <LogoutButton classes={styles.navLogout}><LogOut size='32' className={styles.headerLogoutIcon}/></LogoutButton>
                <Link to='/' className={styles.navBrand}>
                    <ImageFadeIn src={logo} />
                </Link>
            </div>
        </div>
    );
}

export default Nav;
