import React, { Fragment, useState } from 'react';
import * as Icon from 'react-feather';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

import styles from './addBook.module.scss';
import TitleSearchQueries from './TitleSearchQueries';

const AddBookTitle = ({studentId, close, group, showIsbn, searchTerm, showHelp}) => {
    const { t } = useTranslation();
    const [bookCode, setBookCode] = useState("");
    const [showSearch, setShowSearch] = useState({
        showSearch: false,
        code: null,
    })
    const handleSearch = (e) => {
        e.preventDefault();
        searchTerm(bookCode);
        setShowSearch({
            show: true,
            code: bookCode
        });
    }

    return (
        <Fragment>
            <>
                <div className={styles.searchSplit}>
                    <div className={styles.titleSearchHeader}>
                        <h1 className={styles.searchSubTitle}>{t('search_author_title')}</h1>
                        <form onSubmit={(e) => {handleSearch(e)}}>
                            <input type="text" placeholder="Book Title" onChange={(e) => {setBookCode(e.currentTarget.value)}} value={bookCode} className={cx(styles.searchInput, "u-m-right-10")} />
                            <button type="submit" className={cx(styles.searchBtn, "u-m-right-10", bookCode !== "" && styles.searchBtnActive)} disabled={bookCode === "" && true}>{t('button.search')}</button>
                            <p className="u-m-top-10 u-bold">{t('searchWarning')}</p>
                            <p className="u-underline" style={{cursor: 'pointer'}} onClick={showHelp}>{t('searchLink')}</p>
                        </form>
                    </div>
                    <button className={styles.isbnButton} onClick={showIsbn}>
                        <div className={styles.isbnButtonLeft}>
                            <Icon.Camera size="40"/>
                            <p>BETA</p>
                        </div>
                        <h3 className="u-bold u-base-tight">{t('scan_isbn_code')}</h3>
                        <p>{t('use_device_camera_alt')}</p>
                    </button>
                </div>
                {showSearch.show &&
                <TitleSearchQueries code={showSearch.code} studentId={studentId} group={group && group} close={close}/>
                
                }
            </>
        </Fragment>
    )
}

export default AddBookTitle;
