import React from 'react';
import cx from 'classnames';
import { Link } from '@reach/router';
import styles from './ButtonLink.module.scss';

const ButtonLink = ({ children, disabled, fullWidth, large, outline, to }) => {
    return (
        <Link 
            disabled={disabled} 
            to={to} 
            className={cx(styles.button, {[styles.buttonFullWidth]: fullWidth}, {[styles.buttonLarge]: large}, {[styles.buttonOutline]: outline})}
        >
            {children}
        </Link>
    )
}

export default ButtonLink;
