import React from 'react';
import { ApolloProvider } from '@apollo/react-hooks';
import { getClient } from '@axeedge/go-shared-utils';
import Bugsnag from '@bugsnag/js';
import BugsnagPluginReact from '@bugsnag/plugin-react';
import ls from 'local-storage';

import { ErrorPage } from '@axeedge/go-shared-components';

import { AuthProvider } from '../../services/auth/AuthProvider';
import { APP_NAME } from '../../services/constants';
import RouterAuthQuery from './components/RouterAuthQuery';


const hostname = window && window.location && window.location.hostname;

Bugsnag.start({
    apiKey: '112ef5bd7b0c7c164caf36e3c5198c23',
    plugins: [new BugsnagPluginReact()],
    metadata: {
        app: 'Parents App',
        releaseName: hostname === 'localhost' ? 'development' : !hostname.includes('staging') ? 'production' : 'staging'
    },
    enabledReleaseStages: [ 'production', 'staging' ],
    onError: (event) => {
        const user = JSON.parse(ls('go_parent_user'));
        if (user.id && user.email) {
            event.setUser(user.id, user.email, `${user.firstName} ${user.lastName}`)
        } else {
            event.setUser('unknown', 'unknown', 'unknown')
        }
    }
})

const ErrorBoundary = Bugsnag.getPlugin('react')
    .createErrorBoundary(React)

const Home = () => {
    return (
        <ErrorBoundary FallbackComponent={ErrorPage}>
            <ApolloProvider client={getClient(APP_NAME)} >
                <AuthProvider>
                    <RouterAuthQuery />
                </AuthProvider>
            </ApolloProvider>
        </ErrorBoundary>
    )
}

export default Home;