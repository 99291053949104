import React, { useEffect, useState } from 'react';
import ImageFadeIn from 'react-image-fade-in';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from '@reach/router';
import { Menu, X } from 'react-feather';
import cx from 'classnames';

import { APPS, getUrl } from '@axeedge/go-shared-utils';

import styles from './LoggedOutHeader.module.scss';
import logo from '../../images/boomer.png';

const LoggedOutHeader = () => {
    const { t } = useTranslation();
    const [showMenu, setShowMenu] = useState(false);

    const location = useLocation();

    useEffect(() => {
        setShowMenu(false);
    }, [location, setShowMenu])

    return (
        <div className={cx(styles.loggedOutHeader, {[styles.loggedOutHeaderWithMenu]: showMenu})}>
            <div className={styles.loggedOutHeaderContent}>
                <Link to='/' className={styles.loggedOutHeaderBranding}>
                    <ImageFadeIn src={logo} alt='Go Apps' className={styles.loggedOutHeaderBrandingLogo} />
                    <p className={styles.loggedOutHeaderBrandingTitle} >BoomReader &amp; BoomWriter</p>
                </Link>               
                <div className={styles.loggedOutHeaderActions}>
                    <div className={styles.loggedOutHeaderActionsDesktop}>
                        <Link className={styles.loggedOutHeaderActionsDesktopAction} to='/'>{t('login')}</Link>
                        <Link className={styles.loggedOutHeaderActionsDesktopAction} to='/signup'>{t('create_account')}</Link>
                    </div>
                    <button className={styles.loggedOutHeaderActionsToggleButton} onClick={() => setShowMenu(s => !s)}>{showMenu ? <X /> : <Menu />}</button>
                </div>    
            </div>
            
            { 
                showMenu && (
                    <div 
                        className={styles.loggedOutHeaderMenu}
                    >
                        <Link className={cx(styles.loggedOutHeaderMenuAction, styles.loggedOutHeaderMenuActionBordered)} to='/'>{t('login')}</Link>
                        <Link className={cx(styles.loggedOutHeaderMenuAction, styles.loggedOutHeaderMenuActionBordered)} to='/signup'>{t('create_account')}</Link>
                        <a href={`${getUrl(APPS.go_portal_teacher)}`} className={styles.loggedOutHeaderMenuAction} to='/'>{t('im_teacher')}</a>
                        <a href={`${getUrl(APPS.go_portal_pupil)}`}  className={styles.loggedOutHeaderMenuAction} to='/signup'>{t('im_pupil')}</a>
                        <a href="https://boomreader.co.uk/" rel='noopener noreferrer' className={styles.loggedOutHeaderMenuAction}>BoomReader</a>
                        <a href="https://boomwriter.co.uk/" rel='noopener noreferrer' className={styles.loggedOutHeaderMenuAction}>BoomWriter</a>
                        <a href="https://boomreader.co.uk/privacy" rel='noopener noreferrer' className={styles.loggedOutHeaderMenuAction}>{t('privacy')}</a>
                        <a href="https://boomreader.co.uk/terms" rel='noopener noreferrer' className={styles.loggedOutHeaderMenuAction}>Terms &amp; Conditions</a>
                    </div>
                )
            }
        </div>
    )
}

export default LoggedOutHeader;