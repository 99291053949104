import React, { Fragment, useState, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { useApolloClient, useMutation } from '@apollo/react-hooks';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { APP_NAME } from '../../../services/constants';
import { useTranslation } from 'react-i18next';

import { Button, FormErrors } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { LINK_EXISTING_ACCOUNT_MUTATION } from '../services/graphql';
import { Check } from 'react-feather';
import { AuthContext } from '../../../services/auth/AuthProvider';


const LinkAccount = ({ closeModal }) => {
    const { currentUser, setCurrentUser, setEmailLinked } = useContext(AuthContext);
    const [formErrors, setFormErrors] = useState([]);
    const [success] = useState(false);
    const client = useApolloClient();
    const { t } = useTranslation();

    const [linkAccount, { loading }] = useMutation(LINK_EXISTING_ACCOUNT_MUTATION, {
        onCompleted: data => {
            if (data.parentLinkExistingAccount.errors && data.parentLinkExistingAccount.errors.length !== 0) {
                setFormErrors(data.parentLinkExistingAccount.errors);
                return;
            }

            if (data.parentLinkExistingAccount.parent.id) {
                ls(getAppTokenName(APP_NAME), data.parentLinkExistingAccount.token);
                ls('go_parent_user', JSON.stringify(data.parentLinkExistingAccount.parent));
                setCurrentUser(data.parentLinkExistingAccount.parent);
                setEmailLinked("LINKEXISTING");
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
        }
    });

    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required(t('req_enter_email')),
        password: Yup.string()
            .required(t('req_enter_your_password'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onBlur'
    });

    const onSubmit = values => {
        setFormErrors([]);
        const { password, email } = values;
        linkAccount({
            variables: {
                email,
                password,
                facebookId: currentUser.facebookId,
            }
        })
    };

    return (
        <Fragment>
            <form onSubmit={handleSubmit(onSubmit)}>
                <h1 className='light u-m-base-3'>{t('link_existing_account')}</h1>
                {!success &&
                    <>
                    <p className="u-m-base-2">{t('enter_existing_go_details')}</p>
                    <div className='basic-form__group'>
                    <input
                            name='email'
                            placeholder='Email'
                            className='basic-form__text-box'
                            ref={register}
                            type='email'
                        />
                    </div>
                    {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                    <div className='basic-form__group'>
                        <input
                            name='password'
                            placeholder='Password'
                            className='basic-form__text-box'
                            autoComplete='off'
                            ref={register}
                            type='password'
                        />
                    </div>
                    {errors.password && <p className='basic-form__hint'>{errors.password.message}</p>}
                    <div className='u-m-top-3'>
                        <Button className='u-m-right-2' type='submit'>{loading ? t('linking') : t('link')}</Button>
                        <Button type='button' outline onClick={() => closeModal()}>{t('button.cancel')}</Button>
                    </div>
                    </>
                }
            </form>
            {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
            {
                success && (
                    <p className='basic-form__success'><Check className='basic-form__success__icon'/>{t('new_password_saved')}</p>
                )
            }
        </Fragment>
    )
}

export default LinkAccount;
