import React, {useState} from 'react';
import { useForm } from 'react-hook-form';
import * as Yup from 'yup';
import { yupResolver } from '@hookform/resolvers';
import { useTranslation } from 'react-i18next';
import FacebookLoginWithButton from 'react-facebook-login';

import { Button, FormErrors, Modal } from '@axeedge/go-teacher-components';

import { FbAppId } from '../../../services/constants';
import styles from '../../SignUp/SignUp.module.scss';

const LoginForm = ({ formErrors, loggingIn, loginMethod, setModalForgotPass, responseFacebook }) => {
    const { t } = useTranslation();
    
    const schema = Yup.object().shape({
        email: Yup.string().email()
            .required(t('req_enter_email')),
        password: Yup.string()
            .required(t('req_enter_your_password'))
    });

    const [fbError, setFbError] = useState(false)
    const [showFBmsg, setShowFBmsg] = useState(false)

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: 'onSubmit'
    });

    const onSubmit = values => {
        const { email, password } = values;
        loginMethod({
            variables: {
                email,
                password
            }
        });
    }

    /*useEffect(() => {console.log(responseFacebook)})*/

    return (
        <>
        <div className={styles.loginMethods}>
            <form className={styles.loginMethodsForm} onSubmit={handleSubmit(onSubmit)}>
                <div className='basic-form__group'>
                    <input
                        name='email'
                        placeholder={t('email')}
                        className='basic-form__text-box'
                        ref={register}
                        type='email'
                    />
                </div>
                {errors.email && <p className='basic-form__hint'>{errors.email.message}</p>}
                <div className='basic-form__group'>
                    <input
                        name='password'
                        placeholder={t('password')}
                        className='basic-form__text-box'
                        autoComplete='off'
                        ref={register}
                        type='password'
                    />
                </div>
                {errors.password && <p className='basic-form__hint'>{errors.password.message}</p>}
                {formErrors.length !== 0 && <FormErrors errors={formErrors} />}
                <div className='u-display-flex u-justify-center'>
                    <Button type='submit' primary fullWidth>{loggingIn ? t('logging_in') : t('login')}</Button>
                </div>
                <div className='u-display-flex u-justify-center u-m-top-3'>
                    <p onClick={() => setModalForgotPass(true)} className='auth-page__link'>{t('ive_forgotten_pw')}</p>
                </div>
                <div  className='u-display-flex u-justify-center u-m-top-3'>
                    <p onClick={() => setShowFBmsg(true)} className='auth-page__link'>Looking for Facebook login?</p>
                </div>
            </form>
            {/*}
            <div className={styles.loginMethodsFb}>
                <div className={styles.fbLogin}>
                    <span className={styles.fbLoginSpan}>or</span>
                </div>
                <FacebookLoginWithButton
                    appId={FbAppId()}
                    autoLoad={false}
                    fields="name, email, picture, first_name, last_name"
                    callback={responseFacebook}
                    cssClass="kep-login-facebook small"
                    icon="fa-facebook"
                    textButton="Login with Facebook"
                    isMobile={false}
                    onFailure={() => setFbError(true)}     
                />
                {fbError &&
                    <>
                        <div style={{textAlign: 'center', marginTop: 10, marginBottom: 10}} className={"error"}>We are currently experiencing problems with our Facebook login feature.</div>
                        <div style={{textAlign: 'center', marginBottom: 10}} className={"error"}>To login, please follow our <span onClick={() => setModalForgotPass(true)} style={{fontWeight: 'bold', textDecoration: 'underline'}}>password reset</span> procedure using the email address associated with your Facebook account.</div>
                    </>
                }
            </div>
            */}
        </div>
        {showFBmsg &&
            <Modal closeModal={() => setShowFBmsg(false)}>
                <p>We have disabled the <i>Login With Facebook</i> feature. If you previously used this to gain access to your parent account then please use the <i>I've Forgotten My Password</i> feature using the email associated to your Facebook account to log back in to your account..</p>
            </Modal>
        }
        </>
    )
}

export default LoginForm;