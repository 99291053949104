import React, { useContext, useState, Fragment } from 'react';
import { useForm } from "react-hook-form";
import { useMutation, useApolloClient } from '@apollo/react-hooks';
import { yupResolver } from '@hookform/resolvers';
import * as Yup from 'yup';
import ls from 'local-storage';
import { navigate } from '@reach/router';
import { Trans, useTranslation } from 'react-i18next';

import { Button, Modal } from '@axeedge/go-teacher-components';
import { getAppTokenName } from '@axeedge/go-shared-utils';

import { UPDATE_PASSWORD_MUTATION } from '../services/graphql';
import ForgotPassword from './ForgotPassword';
import { AuthContext } from '../../../services/auth/AuthProvider';
import { APP_NAME } from '../../../services/constants';

const SetPassword = ({ token, '*': reset }) => {
    const [modalForgotPass, setModalForgotPass] = useState(false);
    const { setCurrentUser } = useContext(AuthContext);
    const client = useApolloClient();
    const { t } = useTranslation();

    const [updatePassword, { loading }] = useMutation(UPDATE_PASSWORD_MUTATION, {
        onCompleted: data => {
            if (data.updatePassword.token) {
                ls(getAppTokenName(APP_NAME), data.updatePassword.token);
                ls('go_parent_user', JSON.stringify(data.updatePassword.user));
                setCurrentUser({
                    ...data.updatePassword.user,
                    reset: true
                });
                client.writeData({
                    data: {
                        loggedIn: true,
                        forcedLogout: false
                    }
                });
                navigate('/');
            }
            if (data.updatePassword.errors && data.updatePassword.errors.length !== 0) {
                setModalForgotPass(data.updatePassword.errors);
            }
        }
    });

    const schema = Yup.object().shape({
        password: Yup.string()
            .required(t('req_enter_password')),
        passwordConfirmation: Yup.string()
            .oneOf([Yup.ref('password'), null], t('req_pw_must_match'))
    });

    const { register, handleSubmit, errors } = useForm({
        resolver: yupResolver(schema),
        mode: "onBlur"
    });

    const onSubmit = values => {
        const { password, passwordConfirmation } = values;
        updatePassword({
            variables: {
                token,
                password,
                passwordConfirmation
            }
        })
    };

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <h1 className='auth-page__title-light'>
                    {
                        reset ? (
                            <Fragment>
                                {t('reset_your_password')}
                            </Fragment>
                        ) : (
                            <Fragment>
                                <Trans i18nKey='account_activation_set_pw'>Account activation: <br/> Set your password</Trans>
                            </Fragment>
                        ) 
                    }
                </h1>
                <p className='u-m-base-2 u-text-center'>{reset ? t('to_reset_pw') : t('to_complete_activation')}:</p>
                <form onSubmit={handleSubmit(onSubmit)}>
                    
                    <div className="basic-form__group">
                        <input
                            name="password"
                            placeholder={reset ? t('new_password') : t('password')}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.password && <p className="basic-form__hint">{errors.password.message}</p>}
                    <div className="basic-form__group">
                        <input
                            name="passwordConfirmation"
                            placeholder={reset ? t('confirm_new_password') : t('confirm_password')}
                            className="basic-form__text-box"
                            type="password"
                            ref={register}
                        />
                    </div>
                    {errors.passwordConfirmation && <p className="basic-form__hint">{errors.passwordConfirmation.message}</p>}
                    <div className='u-display-flex u-justify-center'>
                        <Button type="submit">{loading ? t('saving') : t('save_pw_login')}</Button>
                    </div>
                </form>
            </div>
            {
                modalForgotPass && (
                    <Modal closeModal={() => setModalForgotPass(false)}>
                        <ForgotPassword invalid_token={modalForgotPass} closeModal={() => setModalForgotPass(false)} />
                    </Modal>
                )
            }
        </div>
    )
}

export default SetPassword;
