import React, { useContext } from 'react';
import { useMutation } from '@apollo/react-hooks';
import { useEffectOnce } from 'react-use';
import { navigate } from '@reach/router';
import ImageFadeIn from "react-image-fade-in";
import { useApolloClient } from '@apollo/react-hooks';
import ls from 'local-storage';
import { useTranslation } from 'react-i18next';

import { getAppTokenName } from '@axeedge/go-shared-utils';

import { AuthContext } from '../../services/auth/AuthProvider';
import { IMPERSONATE_LOGOUT_MUTATION, LOGOUT_MUTATION } from './services/graphql';
import { APP_NAME } from '../../services/constants';

import logo from '../../images/boomer.png';


const Logout = ({ '*': targetUrl }) => {
    const client = useApolloClient();
    const { setCurrentUser } = useContext(AuthContext);
    const { t } = useTranslation();

    const processLogout = () => {
        ls.clear();
        setCurrentUser(null);
        client.clearStore();
        navigate(targetUrl || '/');
    }

    const [logOut] = useMutation(LOGOUT_MUTATION, {
        onCompleted: ({ logOut }) => {
            if (logOut.errors && logOut.errors.length !== 0) {
                alert(logOut.errors[0]);
            }
            if (logOut.loggedOut) {
                processLogout();
            }
        }
    });

    const [impersonateLogOut] = useMutation(IMPERSONATE_LOGOUT_MUTATION, {
        onCompleted: ({ logOut }) => {
            if (logOut.errors && logOut.errors.length !== 0) {
                alert(logOut.errors[0]);
            }
            if (logOut.loggedOut) {
                processLogout();
            }
        }
    })

    useEffectOnce(() => {
        if (ls('go_impersonating')) {
            impersonateLogOut({
                variables: {
                    token: ls(getAppTokenName(APP_NAME))
                }
            });
        } else {
            logOut({
                variables: {
                    token: ls(getAppTokenName(APP_NAME))
                }
            });
        }
        
    })

    return (
        <div className='auth-page__container'>
            <div className='auth-page__panel'>
                <ImageFadeIn className='auth-page__logo' src={logo} />
                <h1 className='auth-page__title'>{t('logging_out')}</h1>
            </div>
        </div>
    );
}

export default Logout;
